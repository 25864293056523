import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { HttpService } from 'servicios/http.service';
import { Cama } from 'modelos/tecnico/Cama';
import { Trampa } from 'modelos/tecnico/Trampa';
import { ProduccionBajasParams } from 'modelos/tecnico/ProduccionBajasParams';
import { PlagaEnfermedad } from 'modelos/tecnico/PlagaEnfermedad';
import { ReporteMonitoreoDirecto } from 'modelos/tecnico/ReporteMonitoreoDirecto';
import { ReporteMonitoreoDirectoBloque } from 'modelos/tecnico/ReporteMonitoreoDirectoBloque';
import { TipoIndicador } from 'modelos/tecnico/TipoIndicador';
import { ResultadoGrupoIndicador } from 'modelos/tecnico/ResultadoGrupoIndicador';
import { GrupoIndicador } from 'modelos/tecnico/GrupoIndicador';
import { Indicador } from 'modelos/tecnico/Indicador';
import { LaborCultural } from 'modelos/tecnico/LaborCultural';
import { ResultadoSiembra } from 'modelos/tecnico/ResultadoSiembra';
import { ResultadoErradicacion } from 'modelos/tecnico/ResultadoErradicacion';
import { ConfirmacionSiembras } from 'modelos/tecnico/ConfirmacionSiembras';
import { ConfirmacionErradicaciones } from 'modelos/tecnico/ConfirmacionErradicaciones';
import { MonitoreoTiemposTrabajados } from 'modelos/tecnico/MonitoreoTiemposTrabajados';
import { MemoCorte } from 'modelos/tecnico/MemoCorte';
import { PuntoCorteEspecieCP } from 'modelos/tecnico/PuntoCorteEspecieCP';
import { FiltroCambios } from 'modelos/tecnico/filtroCambios';
import { VProduccion } from 'modelos/tecnico/VProduccion';
import { VProduccionBloques } from 'modelos/tecnico/VProduccionBloques';
import { VProdBloqNaves } from 'modelos/tecnico/VProdBloqNaves';
import { CambioDato } from 'modelos/tecnico/CambioDato';
import { VAlineamientoBasal } from 'modelos/tecnico/VAlineamientoBasal';
import { VBajas } from 'modelos/tecnico/VBajas';
import { VMonitoreoCamaraHum } from 'modelos/tecnico/VMonitoreoCamaraHum';
import { ReporteProduccionFinca } from 'modelos/tecnico/ReporteProduccionFinca';
import { VVariableSuelo } from 'modelos/tecnico/VVariableSuelo';
import { VCalidadEsqueje } from 'modelos/tecnico/VCalidadEsqueje';
import { VMonitoreoIndTrampa } from 'modelos/tecnico/VMonitoreoIndTrampa';
import { ProveedorEsquejes } from 'modelos/tecnico/ProveedorEsquejes';
import { CausaCalidadEsquejes } from 'modelos/tecnico/CausaCalidadEsquejes';
import { ParametrosVariableSuelo } from 'modelos/tecnico/ParametrosVariableSuelo';
import { Semana } from 'modelos/Admin/Semana';
import { ParametrosUnidadPlaga } from 'modelos/tecnico/ParametrosUnidadPlaga';
import { ParametrosLaborCultural } from 'modelos/tecnico/ParametrosLaborCultural';
import { CalidadEsquejeCausa } from 'modelos/tecnico/CalidadEsquejeCausa';
import { VConsultaLaboresCulturales } from 'modelos/tecnico/VConsultaLaboresCulturales';
import { VConsultaMonitoreoDirecto } from 'modelos/tecnico/VConsultaMonitoreoDirecto';
import { ReporteVariablesSuelo } from 'modelos/tecnico/ReporteVariablesSuelo';
import { ParamVariableSuelo } from 'modelos/tecnico/ParamVariableSuelo';
import { ReporteCamasMonitores } from 'modelos/tecnico/ReporteCamasMonitores';
import { Modulo } from 'modelos/tecnico/Modulo';
import { CambiosTecnico } from 'modelos/tecnico/CambiosTecnico';
import { CambiosTecnicoResumido } from 'modelos/tecnico/CambiosTecnicoResumido';
import { CambioDatoMasivo } from 'modelos/tecnico/CambioDatoMasivo';
import { ProduccionSoporte } from 'modelos/tecnico/ProduccionSoporte';
import { ReporteMapa } from 'modelos/tecnico/ReporteMapa';
import { Tercio } from 'modelos/tecnico/Tercios';
import { Siembra } from 'modelos/tecnico/Siembra';
import { TipoDato } from 'modelos/tecnico/TipoDato';
import { VSiembra } from 'modelos/tecnico/VSiembra';
import { VErradicacion } from 'modelos/tecnico/VErradicacion';
import { CodigoCamaVariedad } from 'modelos/tecnico/CodigoCamaVariedad';
import { CausaErradicacion } from 'modelos/tecnico/causaErradicacion';
import { Curva } from 'modelos/tecnico/Curva';
import { DatosCurva } from 'modelos/tecnico/DatosCurva';
import { ParametroCurvas } from 'modelos/tecnico/ParametroCurvas';
import { Salida } from 'modelos/tecnico/Salida';
import { DatoExcelSalida } from 'modelos/tecnico/DatoExcelSalida';
import { ProduccionVariedadFecha } from 'modelos/tecnico/ProduccionVariedadFecha';
import { ItemsSalidaAlmacenDynamics } from 'modelos/tecnico/ItemsSalidaAlmacenDynamics';

@Injectable()
export class TecnicoService extends HttpService {
  ObtenerBloques(idCentroProduccion: number): Observable<string[]> {
    return this.get<string[]>(`/Tecnico/Bloques/${idCentroProduccion}`);
  }

  ObtenerCodigosCamasBloque(idCentroProduccion: number, codigoBloque: string, soloCamasActivas: boolean): Observable<string[]> {
    return this.get<string[]>(`/Tecnico/CodigosCamaBloque/${idCentroProduccion}/${soloCamasActivas}/${codigoBloque}`);
  }

  ObtenerCamasBloque(idCentroProduccion: number, codigoBloque: string, codigoCamaInicial: string, codigoCamaFinal: string, soloCamasActivas: boolean): Observable<Cama[]> {
    if (!codigoCamaInicial)
      codigoCamaInicial = "";

    if (!codigoCamaFinal)
      codigoCamaFinal = "";

    return this.get<Cama[]>(`/Tecnico/CamasBloque/${idCentroProduccion}/${soloCamasActivas}/${codigoBloque}/${codigoCamaInicial}/${codigoCamaFinal}`);
  }

  GenerarEtiquetasCamas(idCentroProduccion: number, incluirDetalles: boolean, incluirNombreEspecie: boolean, codigosCamas: string[]): Observable<string> {
    return this.post<string>(`/Tecnico/GenerarEtiquetasCamas/${idCentroProduccion}/${incluirDetalles}/${incluirNombreEspecie}`, codigosCamas);
  }

  ObtenerProduccion(parametros: ProduccionBajasParams): Observable<any[]> {
    return this.post<any[]>("/Tecnico/ReporteTecnicoProduccion", parametros);
  }

  GenerarExcelProduccion(parametros: ProduccionBajasParams): Observable<string> {
    return this.post<string>("/Tecnico/ExcelTecnicoProduccion", parametros);
  }

  ObtenerBajas(parametros: ProduccionBajasParams): Observable<any[]> {
    return this.post<any[]>("/Tecnico/ReporteTecnicoBajas", parametros);
  }

  GenerarExcelBajas(parametros: ProduccionBajasParams): Observable<string> {
    return this.post<string>("/Tecnico/ExcelTecnicoBajas", parametros);
  }

  ObtenerTrampas(idCentroProduccion: number, idBloque: string): Observable<Trampa[]> {
    return this.get<Trampa[]>(`/Tecnico/Trampas/${idCentroProduccion}/${idBloque}`);
  }

  GenerarEtiquetasTrampas(idCentroProduccion: number, incluirDetalles: boolean, codigosTrampas: string[]): Observable<string> {
    return this.post<string>(`/Tecnico/GenerarEtiquetasTrampas/${idCentroProduccion}/${incluirDetalles}`, codigosTrampas);
  }

  ObtenerMonitoreoDirectoBloque(idCentroProduccion: number, codBloque: string, fechaInicial: Date, fechaFinal: Date, idPlaga: number, idTipoDato: number): Observable<ReporteMonitoreoDirectoBloque> {
    return this.get<ReporteMonitoreoDirectoBloque>(`/Tecnico/MonitoreoDirectoBloque/${idCentroProduccion}/${codBloque}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}/${idPlaga}/${idTipoDato}`);
  }

  ObtenerMonitoreoDirecto(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date, idPlaga: number, idTipoDato: number): Observable<ReporteMonitoreoDirecto> {
    return this.get<ReporteMonitoreoDirecto>(`/Tecnico/MonitoreoDirecto/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}/${idPlaga}/${idTipoDato}`);
  }
  ObtenerMonitoreoDirectoCorreo(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date, idPlaga: number): Observable<ReporteMonitoreoDirecto> {
    return this.get<ReporteMonitoreoDirecto>(`/Tecnico/MonitoreoDirecto/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}/${idPlaga}`);
  }
  ObtenerPlagasEnfermedades(): Observable<PlagaEnfermedad[]> {
    return this.get<PlagaEnfermedad[]>("/Tecnico/PlagasEnfermedades");
  }

  ObtenerMonitoreoTrampas(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date, idBloque: string, idPlaga: number): Observable<any[]> {
    return this.get<any[]>(`/Tecnico/ReporteTecnicoMonitoreoTrampas/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}/${idBloque}/${idPlaga}`);
  }

  GenerarExcelMonitoreoTrampas(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date, idBloque: string, idPlaga: number): Observable<string> {
    return this.get<string>(`/Tecnico/ExcelTecnicoMonitoreoTrampas/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}/${idBloque}/${idPlaga}`);
  }

  ObtenerMonitoreoCamaraHumeda(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date): Observable<any[]> {
    return this.get<any[]>(`/Tecnico/ReporteTecnicoMonitoreoCamaraHumeda/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}`);
  }

  GenerarExcelMonitoreoCamaraHumeda(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date): Observable<string> {
    return this.get<string>(`/Tecnico/ExcelTecnicoMonitoreoCamaraHumeda/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}`);
  }

  ObtenerTiposIndicadores(): Observable<TipoIndicador[]> {
    return this.get<TipoIndicador[]>("/Tecnico/TiposIndicadores");
  }

  ObtenerGruposIndicadores(idCentroProduccion: number): Observable<ResultadoGrupoIndicador[]> {
    return this.get<ResultadoGrupoIndicador[]>(`/Tecnico/GruposIndicadores/${idCentroProduccion}`);
  }

  GuardarGrupoIndicador(grupoIndicador: GrupoIndicador): Observable<number> {
    return this.post<number>("/Tecnico/GuardarGrupoIndicador", grupoIndicador);
  }

  ObtenerIndicadores(idCentroProduccion: number): Observable<Indicador[]> {
    return this.get<Indicador[]>(`/Tecnico/Indicadores/${idCentroProduccion}`);
  }

  GuardarIndicador(indicador: Indicador): Observable<number> {
    return this.post<number>("/Tecnico/GuardarIndicador", indicador);
  }

  ObtenerLaboresCulturales(): Observable<LaborCultural[]> {
    return this.get<LaborCultural[]>("/Tecnico/LaboresCulturales");
  }

  ObtenerHumedadRelativa(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date): Observable<any[]> {
    return this.get<any[]>(`/Tecnico/ReporteHumedadRelativa/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}`);
  }

  GenerarExcelHumedadRelativa(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date): Observable<string> {
    return this.get<string>(`/Tecnico/ExcelHumedadRelativa/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}`);
  }

  ObtenerNitratos(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date): Observable<any[]> {
    return this.get<any[]>(`/Tecnico/ReporteNitratos/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}`);
  }

  GenerarExcelNitratos(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date): Observable<string> {
    return this.get<string>(`/Tecnico/ExcelNitratos/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}`);
  }

  ObtenerConductividadElectrica(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date): Observable<any[]> {
    return this.get<any[]>(`/Tecnico/ReporteConductividadElectrica/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}`);
  }

  GenerarExcelConductividadElectrica(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date): Observable<string> {
    return this.get<string>(`/Tecnico/ExcelConductividadElectrica/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}`);
  }

  ObtenerpH(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date): Observable<any[]> {
    return this.get<any[]>(`/Tecnico/ReportepH/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}`);
  }

  GenerarExcelpH(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date): Observable<string> {
    return this.get<string>(`/Tecnico/ExcelpH/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}`);
  }

  ObtenerSiembras(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date): Observable<ResultadoSiembra[]> {
    return this.get<ResultadoSiembra[]>(`/Tecnico/Siembras/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}`);
  }

  ConfirmarSiembras(confirmacionSiembras: ConfirmacionSiembras): Observable<void> {
    return this.post<void>("/Tecnico/ConfirmarSiembras", confirmacionSiembras);
  }

  ObtenerErradicaciones(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date): Observable<ResultadoErradicacion[]> {
    return this.get<ResultadoErradicacion[]>(`/Tecnico/Erradicaciones/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}`);
  }

  ConfirmarErradicaciones(confirmacionErradicaciones: ConfirmacionErradicaciones): Observable<void> {
    return this.post<void>("/Tecnico/GuardarControlesErradicaciones", confirmacionErradicaciones);
  }

  ObtenerMonitoreoTiemposTrabajados(idCentroProduccion: number, fecha: Date): Observable<MonitoreoTiemposTrabajados> {
    return this.get<MonitoreoTiemposTrabajados>(`/Tecnico/MonitoreoTiemposTrabajados/${idCentroProduccion}/${formatDate(fecha, "yyyy-MM-dd", "en")}`);
  }

  ObtenerMemosCorte(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date): Observable<MemoCorte[]> {
    return this.get<MemoCorte[]>(`/Tecnico/MemosCorte/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}`);
  }

  ObtenerPuntosCorteEspecieCP(idCentroProduccion: number, idEspecie: number): Observable<PuntoCorteEspecieCP[]> {
    return this.get<PuntoCorteEspecieCP[]>(`/Tecnico/PuntosCorteEspecieCP/${idCentroProduccion}/${idEspecie}`);
  }

  GuardarMemoCorte(memoCorte: MemoCorte): Observable<number> {
    return this.post<number>("/Tecnico/MemoCorte", memoCorte);
  }

  ObtenerProduccionFinca(idCentroProduccion: number, semana: number): Observable<ReporteProduccionFinca> {
    return this.get<ReporteProduccionFinca>(`/Tecnico/ProduccionFinca/${idCentroProduccion}/${semana}`);
  }

  ObtenerVariablesSueloFinca(idCentroProduccion: number, semana: number, idParamVariableSuelo: number): Observable<ReporteVariablesSuelo> {
    return this.get<ReporteVariablesSuelo>(`/Tecnico/VariablesSueloFinca/${idCentroProduccion}/${semana}/${idParamVariableSuelo}`);
  }

  ObtenerParamsVariablesSuelo(idCentroProduccion: number): Observable<ParamVariableSuelo[]> {
    return this.get<ParamVariableSuelo[]>(`/Tecnico/ParamsVariablesSuelo/${idCentroProduccion}`);
  }

  ObtenerCamasMonitores(idCentroProduccion: number): Observable<ReporteCamasMonitores> {
    return this.get<ReporteCamasMonitores>(`/Tecnico/CamasMonitores/${idCentroProduccion}`);
  }

  ObtenerCambiosProduccion(filtro: FiltroCambios): Observable<VProduccion[]> {
    return this.post<VProduccion[]>("/Tecnico/Cambios/Produccion", filtro);
  }

  ObtenerCambiosProduccionBloque(filtro: FiltroCambios): Observable<VProduccionBloques[]> {
    return this.post<VProduccionBloques[]>("/Tecnico/Cambios/ProduccionBloque", filtro);
  }

  ObtenerCambiosProduccionNave(filtro: FiltroCambios): Observable<VProdBloqNaves[]> {
    return this.post<VProdBloqNaves[]>("/Tecnico/Cambios/ProduccionNave", filtro);
  }

  ObtenerCambiosAlineamientoBasal(filtro: FiltroCambios): Observable<VAlineamientoBasal[]> {
    return this.post<VAlineamientoBasal[]>("/Tecnico/Cambios/AlineamientosBasales", filtro);
  }

  ObtenerCambiosBajas(filtro: FiltroCambios): Observable<VBajas[]> {
    return this.post<VBajas[]>("/Tecnico/Cambios/Bajas", filtro);
  }

  ObtenerCambiosCamaraHumeda(filtro: FiltroCambios): Observable<VMonitoreoCamaraHum[]> {
    return this.post<VMonitoreoCamaraHum[]>("/Tecnico/Cambios/CamaraHumeda", filtro);
  }

  ObtenerCambiosTrampas(filtro: FiltroCambios): Observable<VMonitoreoIndTrampa[]> {
    return this.post<VMonitoreoIndTrampa[]>("/Tecnico/Cambios/Trampas", filtro);
  }

  ObtenerCambiosCalidadEsqueje(filtro: FiltroCambios): Observable<VCalidadEsqueje[]> {
    return this.post<VCalidadEsqueje[]>("/Tecnico/Cambios/CalidadEsquejes", filtro);
  }

  ObtenerCambiosVariablesSuelo(filtro: FiltroCambios): Observable<VVariableSuelo[]> {
    return this.post<VVariableSuelo[]>("/Tecnico/Cambios/VariableSuelo", filtro);
  }

  CambioDato(cambioDato: CambioDato[]): Observable<number> {
    return this.post<number>("/Tecnico/Cambios/CrearCambio", cambioDato);
  }

  CambioDatoMasivo(cambioDato: CambioDatoMasivo): Observable<number> {
    return this.post<number>("/Tecnico/Cambios/CrearCambioMasivo", cambioDato);
  }

  ObtenerSemanaFecha(fecha): Observable<Semana> {
    return this.get<Semana>(`/SemanaPorFecha/${fecha}`);
  }

  ObtenerCambiosLaboresCulturales(filtro: FiltroCambios): Observable<VConsultaLaboresCulturales[]> {
    return this.post<VConsultaLaboresCulturales[]>("/Tecnico/Cambios/LaboresCulturales", filtro);
  }

  ObtenerCambiosMonitoreoDirecto(filtro: FiltroCambios): Observable<VConsultaMonitoreoDirecto[]> {
    return this.post<VConsultaMonitoreoDirecto[]>("/Tecnico/Cambios/MonitoreoDirecto", filtro);
  }

  ObtenerCambiosSiembras(filtro: FiltroCambios): Observable<VSiembra[]> {
    return this.post<VSiembra[]>("/Tecnico/Cambios/Siembras", filtro);
  }

  ObtenerCambiosErradicaciones(filtro: FiltroCambios): Observable<VErradicacion[]> {
    return this.post<VErradicacion[]>("/Tecnico/Cambios/Erradicaciones", filtro);
  }

  ObtenerCodigosCamas(idCentroProduccion: number, codigoBloque: string, estado: string, simplificado: boolean): Observable<string[]> {
    return this.get<string[]>(`/Tecnico/CodigosCamas/${idCentroProduccion}/${codigoBloque}/${estado}/${simplificado}`);
  }

  ObtenerCodigosCamasVariedades(idCentroProduccion: number, codigoBloque: string, estado: string, simplificado: boolean): Observable<CodigoCamaVariedad[]> {
    return this.get<CodigoCamaVariedad[]>(`/Tecnico/CodigosCamasVariedades/${idCentroProduccion}/${codigoBloque}/${estado}/${simplificado}`);
  }

  ObtenerProveedorEsquejes(): Observable<ProveedorEsquejes[]> {
    return this.get<ProveedorEsquejes[]>(`/Tecnico/ProveedoresEsquejes`);
  }

  ObtenerCausaCalidadEsqueje(idCentroProduccion: number): Observable<CausaCalidadEsquejes[]> {
    return this.get<CausaCalidadEsquejes[]>(`/Tecnico/CausaCalidadEsquejePorCentro/${idCentroProduccion}`);
  }

  ObtenerCausaCalidadEsquejePorIdCalidad(IdCalidadEsqueje: number): Observable<CalidadEsquejeCausa[]> {
    return this.get<CalidadEsquejeCausa[]>(`/Tecnico/CausaCalidadEsquejePorIdCalidad/${IdCalidadEsqueje}`);
  }
  ObtenerParametrosVariableSuelo(idParametro: number): Observable<ParametrosVariableSuelo> {
    return this.get<ParametrosVariableSuelo>(`/Tecnico/ParametroVariableSuelo/${idParametro}`);
  }

  ObtenerParametrosMonitoreoDirecto(idParametro: number): Observable<ParametrosUnidadPlaga> {
    return this.get<ParametrosUnidadPlaga>(`/Tecnico/ParametroMonitoreoDirecto/${idParametro}`);
  }

  ObtenerParametroLaborCultural(idParametro: number): Observable<ParametrosLaborCultural> {
    return this.get<ParametrosLaborCultural>(`/Tecnico/ParametroLaborCultural/${idParametro}`);
  }
  ObtenerCambiosModulos(): Observable<Modulo[]> {
    return this.get<Modulo[]>(`/Tecnico/Cambios/Modulos`);
  }

  ObtenerCambios(filtro: FiltroCambios): Observable<CambiosTecnico[]> {
    return this.post<CambiosTecnico[]>("/Tecnico/Cambios/ObtenerCambios", filtro);
  }

  ObtenerCambiosResumen(filtro: FiltroCambios): Observable<CambiosTecnicoResumido[]> {
    return this.post<CambiosTecnicoResumido[]>("/Tecnico/Cambios/ObtenerCambiosResumidos", filtro);
  }

  AutorizarCambios(filtro: FiltroCambios[]): Observable<number> {
    return this.post<number>("/Tecnico/Cambios/AutorizarCambio", filtro);
  }

  ObtenerSoporteFiesta(): Observable<ProduccionSoporte[]> {
    return this.get<ProduccionSoporte[]>(`/Tecnico/SoporteFiesta`);
  }

  ObtenerMapaBajas(idCentroProduccion: number, semana: number): Observable<ReporteMapa> {
    return this.get<ReporteMapa>(`/Tecnico/MapaBajas/${idCentroProduccion}/${semana}`);
  }

  ObtenerMapaAlineamientos(idCentroProduccion: number, semana: number): Observable<ReporteMapa> {
    return this.get<ReporteMapa>(`/Tecnico/MapaAlineamientos/${idCentroProduccion}/${semana}`);
  }
  ObtenerMapaBasales(idCentroProduccion: number, semana: number): Observable<ReporteMapa> {
    return this.get<ReporteMapa>(`/Tecnico/MapaBasales/${idCentroProduccion}/${semana}`);
  }

  ObtenerTercios(): Observable<Tercio[]> {
    return this.get<Tercio[]>(`/Tecnico/Tercios`);
  }

  ObtenerSiembrasAsociadas(idSiembra): Observable<Siembra> {
    return this.get<Siembra>(`/Tecnico/Siembra/${idSiembra}`);
  }

  ObtenerTiposDato(): Observable<TipoDato[]> {
    return this.get<TipoDato[]>(`/Tecnico/ObtenerTiposDato`);
  }

  ObtenerCausasErradicaciones(): Observable<CausaErradicacion[]> {
    return this.get<CausaErradicacion[]>(`/Tecnico/CausasErradicaciones`);
  }

  ObtenerCurvas(): Observable<Curva[]> {
    return this.get<Curva[]>('/Tecnico/Curvas');
  }

  CalcularCurvas(parametroCurvas: ParametroCurvas): Observable<DatosCurva[]> {
    return this.post<DatosCurva[]>("/Tecnico/CalcularCurvas", parametroCurvas);
  }

  ObtenerSalidasAlmacenSistemaUno(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date): Observable<Salida[]> {
    return this.get<Salida[]>(`/Tecnico/SalidasAlmacenSistemaUno/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}`);
  }

  GuardarSalidaAlmacenSistemaUno(salida: Salida): Observable<number> {
    return this.post<number>("/Tecnico/SalidaAlmacenSistemaUno", salida);
  }

  ObtenerDatoExcelSalidasTecnico(idCentroProduccion: number, formData: FormData): Observable<DatoExcelSalida[]> {
    return this.post<DatoExcelSalida[]>(`/Tecnico/CargarExcelSalidasTecnico/${idCentroProduccion}`, formData);
  }

  ObtenerArchivoSalidaAlmcenSistemaUno(salida: Salida): Observable<string> {
    return this.post<string>("/Tecnico/ArchivoSalidaAlmacen", salida);
  }

  ObtenerConsecutivoSalidasAlmacen(idCentroProduccion: number): Observable<number> {
    return this.get<number>(`/Tecnico/ConsecutivoSalidasAlmacen/${idCentroProduccion}`);
  }
  
  ObtenerProduccionVariedadFecha(idCentroProduccion: number, fechaInicial: Date, fechaFinal: Date, filtro: number, idEspecie?: number, idColor?: number, idVariedad?: number): Observable<ProduccionVariedadFecha[]> {
    let url: string = `/Tecnico/ProduccionVariedadFecha/${idCentroProduccion}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}/${filtro}`;

    if (idEspecie) {
      url = url + `/${idEspecie}`;

      if (idColor) {
        url = url + `/${idColor}`;

        if (idVariedad)
          url = url + `/${idVariedad}`;
      }
    }

    return this.get<ProduccionVariedadFecha[]>(url);
  }

  ObtenerItemsSalidasAlmacenDynamics(fechaInicial: Date, fechaFinal: Date, idCentroProduccion: number): Observable<ItemsSalidaAlmacenDynamics[]> {
    return this.get<ItemsSalidaAlmacenDynamics[]>(`/Tecnico/ObtenerItemsSalidasAlmacenDynamics/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}/${idCentroProduccion}`);
  }

  EjecutarJobSalidaAlmacen(fechaInicial: Date, fechaFinal: Date, idCentroProduccion: number): Observable<string> {
    return this.get<string>(`/Tecnico/EjecutarJobSalidaAlmacen/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}/${idCentroProduccion}`);
  }

  SalidaIndividualTecnicoDynamics(itemssalida: ItemsSalidaAlmacenDynamics[]): Observable<boolean> {
    return this.post<boolean>("/Tecnico/SalidaIndividualTecnicoDynamics", itemssalida);
  }
  
}