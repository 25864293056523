import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'servicios/http.service';
import { Tercero } from 'modelos/Tercero';
import { TipoTercero } from 'modelos/TipoTercero';
import { EmpresaContacto } from 'modelos/EmpresaContacto';
import { RevisorFiscal } from 'modelos/RevisorFiscal';
import { Contacto } from 'modelos/Contacto';
import { CentroProduccion } from 'modelos/CentroProduccion';
import { Proveedor } from 'modelos/Proveedor';
import { PlazoPago } from 'modelos/PlazoPago';
import { ClasificacionProveedor } from 'modelos/ClasificacionProveedor';
import { FuenteContacto } from 'modelos/FuenteContacto';
import { TemporadaExtraCupo } from 'modelos/TemporadaExtraCupo';
import { ContactoProveedor } from 'modelos/ContactoProveedor';
import { TipoProveedor } from 'modelos/TipoProveedor';
import { Cliente } from 'modelos/Cliente';
import { Mercado } from 'modelos/Mercado';
import { ClasificacionCliente } from 'modelos/ClasificacionCliente';
import { FormaPago } from 'modelos/FormaPago';
import { MarcacionCliente } from 'modelos/MarcacionCliente';
import { Subcliente } from 'modelos/Subcliente';
import { AplicacionCargoCliente } from 'modelos/AplicacionCargoCliente';
import { TipoCargoCliente } from 'modelos/TipoCargoCliente';
import { CargoEconomico } from 'modelos/CargoEconomico';
import { CargoCliente } from 'modelos/CargoCliente';
import { ExtraCupoCliente } from 'modelos/ExtraCupoCliente';
import { CargoSubcliente } from 'modelos/CargoSubcliente';
import { SubClienteCliente } from 'modelos/SubClienteCliente';
import { ContactoCliente } from 'modelos/ContactoCliente';
import { EmpresaTransporte } from 'modelos/CRM/EmpresaTransporte';
import { DistribuidorCliente } from 'modelos/CRM/DistribuidorCliente';
import { ResultadoOfrecimientos } from 'modelos/CRM/ResultadoOfrecimientos';
import { ClienteSimplificado } from 'modelos/CRM/ClienteSimplificado';
import { SubclienteSimplificado} from 'modelos/SubClienteSimplificado'; 

@Injectable()
export class CrmService extends HttpService {

  ObtenerTerceros(): Observable<Tercero[]> {
    return this.get<Tercero[]>("/Terceros");
  }

  ObtenerTercero(idTercero: number): Observable<Tercero> {
    return this.get<Tercero>(`/Tercero/${idTercero}`);
  }

  BorrarTercero(idTercero: number): Observable<void> {
    return this.delete<void>(`/BorrarTercero/${idTercero}`);
  }

  GuardarTercero(tercero: Tercero): Observable<number> {
    return this.post<number>("/Tercero", tercero);
  }

  ObtenerTiposTercero(): Observable<TipoTercero[]> {
    return this.get<TipoTercero[]>("/TiposTercero");
  }

  ObtenerOfrecimientos(): Observable<ResultadoOfrecimientos[]> {
    return this.get<ResultadoOfrecimientos[]>("/CRM/Ofrecimiento");
  }

  ObtenerEmpresaContactos(idempresa: number): Observable<EmpresaContacto[]> {
    return this.get<EmpresaContacto[]>(`/EmpresaContactos/${idempresa}`);
  }

  GuardarEmpresaContacto(empresacontacto: EmpresaContacto): Observable<number> {
    return this.post<number>("/EmpresaContacto", empresacontacto);
  }

  BorrarEmpresaContacto(idempresacontacto: number): Observable<void> {
    return this.delete<void>(`/BorrarEmpresaContacto/${idempresacontacto}`);
  }


  ObtenerRevisoresFiscales(idempresa: number): Observable<RevisorFiscal[]> {
    return this.get<RevisorFiscal[]>(`/RevisoresFiscales/${idempresa}`);
  }


  ObtenerContactos(): Observable<Contacto[]> {
    return this.get<Contacto[]>("/Contactos");
  }

  GuardarContacto(contacto: Contacto): Observable<number> {
    return this.post<number>("/Contacto", contacto);
  }

  BorrarContacto(idcontacto: number): Observable<void> {
    return this.delete<void>(`/BorrarContacto/${idcontacto}`);
  }

  ObtenerFuentesContacto(): Observable<FuenteContacto[]> {
    return this.get<FuenteContacto[]>("/FuentesContacto");
  }

  ObtenerContactosProveedor(idproveedor: number): Observable<Contacto[]> {
    return this.get<Contacto[]>(`/ContactosProveedor/${idproveedor}`);
  }

  ObtenerContactosCliente(idcliente: number): Observable<Contacto[]> {
    return this.get<Contacto[]>(`/ContactosCliente/${idcliente}`);
  }


  GuardarContactoCliente(contactocliente: ContactoCliente): Observable<number> {
    return this.post<number>("/ContactoCliente", contactocliente);
  }

  BorrarContactoCliente(idcontacto: number, idcliente: number): Observable<void> {
    return this.delete<void>(`/ContactoCliente/${idcontacto}/${idcliente}`);
  }


  GuardarContactoProveedor(contactoproveedor: ContactoProveedor): Observable<number> {
    return this.post<number>("/ContactoProveedor", contactoproveedor);
  }
  BorrarContactoProveedor(idcontacto: number, idproveedor: number): Observable<void> {
    return this.delete<void>(`/BorrarContactoProveedor/${idcontacto}/${idproveedor}`);
  }

  ObtenerCentrosProduccion(): Observable<CentroProduccion[]> {
    return this.get<CentroProduccion[]>("/CentrosProduccion");
  }

  GuardarCentroProduccion(centroproduccion: CentroProduccion): Observable<number> {
    return this.post<number>("/CentroProduccion", centroproduccion);
  }

  ObtenerProveedores(): Observable<Proveedor[]> {
    return this.get<Proveedor[]>("/Proveedores");
  }

  GenerarExcelProveedores(): Observable<string> {
    return this.get<string>("/CRM/ExcelProveedores");
  }

  GuardarProveedor(proveedor: Proveedor): Observable<number> {
    return this.post<number>("/Proveedor", proveedor);
  }

  BorrarProveedor(idproveedor: number): Observable<void> {
    return this.delete<void>(`/BorrarProveedor/${idproveedor}`);
  }

  ObtenerPlazosPago(): Observable<PlazoPago[]> {
    return this.get<PlazoPago[]>("/PlazosPago");
  }

  ObtenerClasificacionesProveedor(): Observable<ClasificacionProveedor[]> {
    return this.get<ClasificacionProveedor[]>("/ClasificacionesProveedor");
  }


  ObtenerTemporadasExtraCupo(): Observable<TemporadaExtraCupo[]> {
    return this.get<TemporadaExtraCupo[]>("/TemporadasExtraCupo");
  }

  GuardarTemporadasExtraCupo(temporadaExtraCupo: TemporadaExtraCupo): Observable<number> {
    return this.post<number>("/TemporadaExtraCupo", temporadaExtraCupo);
  }


  BorrarTemporadasExtraCupo(idtemporada: number): Observable<void> {
    return this.delete<void>(`/TemporadaExtraCupo/${idtemporada}`);
  }

  ObtenerTiposProveedor(): Observable<TipoProveedor[]> {
    return this.get<TipoProveedor[]>("/TiposProveedor");
  }

  ObtenerClientes(): Observable<Cliente[]> {
    return this.get<Cliente[]>("/Clientes");
  }

  ObtenerClientesSimplificado(): Observable<ClienteSimplificado[]> {
    return this.get<ClienteSimplificado[]>("/ClientesSimplificado");
  }

  GenerarExcelClientes(): Observable<string> {
    return this.get<string>("/CRM/ExcelClientes");
  }

  GuardarCliente(cliente: Cliente): Observable<number> {
    return this.post<number>("/Cliente", cliente);
  }

  BorrarCliente(idcliente: number): Observable<number> {
    return this.delete<number>(`/BorrarCliente/${idcliente}`);
  }


  ObtenerMercados(): Observable<Mercado[]> {
    return this.get<Mercado[]>("/Mercados");
  }

  ObtenerFormasPago(): Observable<FormaPago[]> {
    return this.get<FormaPago[]>("/FormasPago");
  }

  ObtenerClasificacionesCliente(): Observable<ClasificacionCliente[]> {
    return this.get<ClasificacionCliente[]>("/ClasificacionesCliente");
  }

  ObtenerMarcacionCliente(idCliente: number): Observable<MarcacionCliente[]> {
    return this.get<MarcacionCliente[]>(`/MarcacionesCliente/${idCliente}`);
  }
  
  ObtenerSubClientes(idcliente: number): Observable<Subcliente[]> {
    return this.get<Subcliente[]>(`/Subcliente/${idcliente}`);
  }

  ObtenerSubClientesSimplificado(idcliente: number): Observable<SubclienteSimplificado[]> {
    return this.get<SubclienteSimplificado[]>(`/SubclienteSimplificado/${idcliente}`);
  }

  GuardarSubCliente(subcliente: Subcliente): Observable<number> {
    return this.post<number>("/SubCliente", subcliente);
  }

  BorrarSubcliente(idsubcliente: number): Observable<void> {
    return this.delete<void>(`/SubCliente/${idsubcliente}`);
  }


  GuardarMarcacionCliente(marcacionCliente: MarcacionCliente): Observable<number> {
    return this.post<number>("/MarcacionCliente", marcacionCliente);
  }

  BorrarMarcacionCliente(idMarcacionCliente: number): Observable<void> {
    return this.delete<void>(`/MarcacionCliente/${idMarcacionCliente}`);
  }

  ObtenerAplicacionesCargoCliente(): Observable<AplicacionCargoCliente[]> {
    return this.get<AplicacionCargoCliente[]>("/AplicacionesCargoCliente");
  }

  ObtenerTiposCargoCliente(): Observable<TipoCargoCliente[]> {
    return this.get<TipoCargoCliente[]>("/TiposCargoCliente");
  }


  ObtenerCargosEconomicos(): Observable<CargoEconomico[]> {
    return this.get<CargoEconomico[]>("/CargosEconomicos");
  }

  ObtenerCargosCliente(idCliente: number): Observable<CargoCliente[]> {
    return this.get<CargoCliente[]>(`/CargosCliente/${idCliente}`);
  }

  GuardarCargoCliente(cargoCliente: CargoCliente): Observable<number> {
    return this.post<number>("/CargoCliente", cargoCliente);
  }

  BorrarCargoCliente(idCargoCliente: number): Observable<void> {
    return this.delete<void>(`/CargoCliente/${idCargoCliente}`);
  }

  ObtenerExtracupoCliente(idCliente: number): Observable<ExtraCupoCliente[]> {
    return this.get<ExtraCupoCliente[]>(`/ExtracuposCliente/${idCliente}`);
  }

  GuardarExtraCupoCliente(extraCupoCliente: ExtraCupoCliente): Observable<number> {
    return this.post<number>("/ExtracupoCliente", extraCupoCliente);
  }

  BorrarExtraCupoCliente(idExtraCupoCliente: number): Observable<void> {
    return this.delete<void>(`/ExtracupoCliente/${idExtraCupoCliente}`);
  }

  ObtenerCargosSubClientes(idsubcliente: number): Observable<CargoSubcliente[]> {
    return this.get<CargoSubcliente[]>(`/CargosSubCliente/${idsubcliente}`);
  }

  GuardarCargoSubCliente(cargosubcliente: CargoSubcliente): Observable<number> {
    return this.post<number>("/CargoSubcliente", cargosubcliente);
  }
  BorrarCargoSubCliente(idcargo: number): Observable<void> {
    return this.delete<void>(`/CargoSubCliente/${idcargo}`);
  }

  GuardarSubClienteCliente(subclientecliente: SubClienteCliente): Observable<number> {
    return this.post<number>("/SubClienteCliente", subclientecliente);
  }

  BorrarSubClienteCliente(idssubcliente: number, idcliente: number): Observable<void> {
    return this.delete<void>(`/SubClienteCliente/${idssubcliente}/${idcliente}`);
  }

  ObtenerClientePorId(idCliente: number): Observable<Cliente> {
    return this.get<Cliente>(`/Cliente/${idCliente}`);
  }

  //#region EmpresasTransporte
  ObtenerEmpresasTransporte(): Observable<EmpresaTransporte[]> {
    return this.get<EmpresaTransporte[]>("/Pedido/EmpresasTransporte");
  }

  GenerarExcelEmpresasTransporte(): Observable<string> {
    return this.get<string>("/Pedido/ExcelEmpresasTransporte");
  }

  GuardarEmpresasTransporte(empresaTransporte: EmpresaTransporte): Observable<number> {
    return this.post<number>("/Pedido/EmpresaTransporte", empresaTransporte);
  }
  //#endregion


  //#region DistribuidorCliente

  ObtenerDistribuidorCliente(idDistribuidorCliente: number): Observable<DistribuidorCliente> {
    return this.get<DistribuidorCliente>(`/Admin/Distribuidor/${idDistribuidorCliente}`);
  }

  GuardarDistribuidorCliente(distribuidor: DistribuidorCliente, idCliente: number): Observable<DistribuidorCliente> {
    return this.post<DistribuidorCliente>(`/Admin/Distribuidor/${idCliente}`, distribuidor);
  }
  //#endregion
}
