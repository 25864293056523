import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AutenticacionService } from 'servicios/autenticacion.service';

@Injectable()
export class GuardiaGuard implements CanActivate {
  constructor(private router: Router, private autenticacionService: AutenticacionService) { }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.autenticacionService.autenticado)
      this.router.navigateByUrl("login");
    else
      return true;
      
    return false;
  }
}
