import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AutenticacionService } from 'servicios/autenticacion.service';
import { GlobalesService } from 'servicios/globales.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MsalService } from '@azure/msal-angular';
import { Account, InteractionRequiredAuthError } from 'msal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  numero: number;
  errorLogin: boolean = false;
  errorMsal: boolean = false;
  mensajeMsal: string;

  constructor(
    private autenticacionService: AutenticacionService,
    private router: Router,
    private globalesService: GlobalesService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private authService: MsalService
  ) { }

  ngOnInit() {
    this.numero = Math.floor((Math.random() * 7) + 1);

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        this.errorMsal = true;
        this.mensajeMsal = authError.errorMessage;
      }
    });

    if (this.authService.getAccount() == null) {
      this.authService.loginRedirect();
    }
    else {
      if (!this.autenticacionService.autenticado) {
        let account: Account = this.authService.getAccount();
        this.authService.acquireTokenSilent({
          scopes: ["user.read"],
          account: account
        }).then((response) => this.autenticar(account.userName, response.accessToken)).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            // Intenta otra manera para adquirir el toekn
            this.authService.acquireTokenRedirect({
              scopes: ["user.read"],
              account: this.authService.getAccount()
            });
          }
          else {
            this.errorMsal = true;
            this.mensajeMsal = error;
          }
        });
      }
      else
        this.router.navigateByUrl("dashboard");
    }
  }

  autenticar(usuario: string, accessToken: string) {
    this.spinner.show();

    this.autenticacionService.autenticarUsuario(usuario, accessToken).subscribe(
      () => {
        this.globalesService.procesarAutenticacion();
     
        this.globalesService.roles.subscribe(
          roles => {
            if (roles.some(rol => rol.Nombre === "Asistente de Mercadeo")) {
              this.spinner.hide();
              this.router.navigateByUrl("mercadeo/pedidos");
            }
            else {
              this.spinner.hide();
              this.router.navigateByUrl("dashboard");           
            }
          },
          error => {
            this.spinner.hide();
            this.router.navigateByUrl("dashboard");
          }
        );
      },
      error => {
        this.spinner.hide();
        this.toastr.error(error, "ERROR");
        this.errorLogin = true;
      }
    );
  }

  cerrarSesion() {
    this.authService.logout();
  }

  /*autenticar(value: any) {
    let usuario: string = value.usuario;
    let password: string = value.password;

    this.autenticacionService.autenticarUsuario(usuario, password).subscribe(
      () => {
        this.spinner.show();
        this.globalesService.procesarAutenticacion();
     
        this.globalesService.roles.subscribe(
          roles => {
            if (roles.some(rol => rol.Nombre === "Asistente de Mercadeo")) {
              this.spinner.show();
              this.router.navigateByUrl("mercadeo/pedidos");

              setTimeout(() => {
                // spinner ends after 5 seconds
                this.spinner.hide();
              }, 5000);
            } else {
              this.router.navigateByUrl("dashboard");
           
                this.spinner.hide();
           
            }
          },
          error => {
            this.router.navigateByUrl("dashboard");
            this.spinner.hide();
          }
        )

      },
      error => this.toastr.error(error, "ERROR")
    );
  }*/
}