import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AdministracionService } from 'servicios/administracion.service';
import { AutenticacionService } from 'servicios/autenticacion.service';
import { Usuario } from 'modelos/Usuario';
import { Rol } from 'modelos/Rol';
import { ItemMenu } from 'modelos/ItemMenu';
import { TasaCambio } from 'modelos/TasaCambio';
import { Observable, Observer } from 'rxjs';

const scriptsExternos = [
  {
    nombre: 'googleMaps',
    src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBGq9hKu6lAkLoXLMqH5mtAnd8gIj943qA'
  }
];

@Injectable()
export class GlobalesService {
  private usuarioSesion: Usuario;
  private scripts: any = {};
  private itemsMenuSesion: ItemMenu[] = [];
  private idCentroProduccionObserver: Observer<number>;
  private idEmpresaObserver: Observer<number>;
  private idUsuarioObserver: Observer<number>;
  private rolesObserver: Observer<Rol[]>;
  private idUsuarioSesion: number;
  idCentroProduccion: Observable<number>;
  idEmpresa: Observable<number>;
  idUsuario: Observable<number>;
  roles: Observable<Rol[]>;
  tasaCambio: TasaCambio;

  constructor(
    private toastr: ToastrService,
    private administracionService: AdministracionService,
    private autenticacionService: AutenticacionService
  ) {
    // Inicializa los scripts externos
    scriptsExternos.forEach((script: any) => {
      this.scripts[script.nombre] = {
        cargado: false,
        src: script.src
      };
    });

    // Crea el observable para el centro de producción
    this.idCentroProduccion = new Observable(o => {
      this.idCentroProduccionObserver = o;

      if (this.usuarioSesion)
        this.notificarIdCentroProduccion(this.usuarioSesion.IdCentroProduccion);

      return {
        unsubscribe() { }
      };
    });

    // Crea el observable para la empresa
    this.idEmpresa = new Observable(o => {
      this.idEmpresaObserver = o;

      if (this.usuarioSesion)
        this.notificarIdEmpresa(this.usuarioSesion.IdEmpresa);

      return {
        unsubscribe() { }
      };
    });

    // Crea el observable para el identificador del usuario
    this.idUsuario = new Observable(o => {
      this.idUsuarioObserver = o;

      if (this.idUsuarioSesion)
        this.notificarIdUsuario(this.idUsuarioSesion);

      return {
        unsubscribe() { }
      };
    });

    // Crea el observable de los roles
    this.roles = new Observable(o => {
      this.rolesObserver = o;

      if (this.usuarioSesion)
        this.notificarRoles(this.usuarioSesion.Roles);

      return {
        unsubscribe() { }
      };
    });
  }

  private notificarIdCentroProduccion(idCentroProduccion: number) {
    if (this.idCentroProduccionObserver) {
      this.idCentroProduccionObserver.next(idCentroProduccion);
      this.idCentroProduccionObserver.complete();
    }
  }

  private notificarIdEmpresa(idEmpresa: number) {
    if (this.idEmpresaObserver) {
      this.idEmpresaObserver.next(idEmpresa);
      this.idEmpresaObserver.complete();
    }
  }

  private notificarIdUsuario(idUsuario: number) {
    if (this.idUsuarioObserver) {
      this.idUsuarioObserver.next(idUsuario);
      this.idUsuarioObserver.complete();
    }
  }

  private notificarRoles(roles: Rol[]) {
    if (this.rolesObserver) {
      this.rolesObserver.next(roles);
      this.rolesObserver.complete();
    }
  }

  urlReportes: string = environment.urlReportes;

  get autenticado(): boolean {
    return this.autenticacionService.autenticado;
  }
  
  get usuario(): string {
    return this.autenticacionService.usuario;
  }

  get token(): string {
    return this.autenticacionService.token;
  }

  get nombreCompletoUsuario(): string {
    return this.usuarioSesion ? this.usuarioSesion.NombresPersona + " " + this.usuarioSesion.ApellidosPersona : "";
  }

  get itemsMenu(): ItemMenu[] {
    return this.itemsMenuSesion;
  }

  procesarAutenticacion() {
    // Carga la información asociada al usuario
    this.administracionService.ObtenerIdUsuario(this.autenticacionService.usuario).subscribe(
      idUsuario => {
        this.idUsuarioSesion = idUsuario;

        if (idUsuario > 0) {
          // Notifica el identificador del usuario
          this.notificarIdUsuario(idUsuario);

          // Carga el menú del usuario
          this.administracionService.ObtenerMenuUsuario(idUsuario).subscribe(
            itemsMenu => this.itemsMenuSesion = itemsMenu,
            error => this.toastr.error("Error al obtener el menú del usuario. Intente más tarde por favor.", "ERROR")
          );

          // Carga la información del usuario
          this.administracionService.ObtenerUsuario(idUsuario).subscribe(
            usuario => {
              // Notifica el identificador del centro de producción
              this.notificarIdCentroProduccion(usuario.IdCentroProduccion);

              // Notifica el identificador de la empresa
              this.notificarIdEmpresa(usuario.IdEmpresa);

              // Notifica los roles
              this.notificarRoles(usuario.Roles);

              this.usuarioSesion = usuario;
            },
            error => this.toastr.error("Error al cargar la información del usuario. Intente más tarde por favor.", "ERROR")
          );
        }
        else
          this.toastr.error("El usuario no está activo en el sistema.", "ERROR");
      },
      error => this.toastr.error(error, "ERROR")
    );

    // Carga la tasa de cambio
    let fechaString = formatDate(new Date(), "yyyy-MM-dd", "en");

    this.administracionService.ObtenerTrm(fechaString).subscribe(
      trm => {
        this.tasaCambio = trm;

        if (this.tasaCambio == null)
          this.toastr.warning("No se ha cargado la tasa de cambio para el dia de hoy.", "Aviso");
      },
      error => this.toastr.error("Error al obtener la tasa de cambio.", "ERROR")
    );
  }

  obtenerColumnas(datos: any): any {
    if (Array.isArray(datos) && datos.length > 0)
      return Object.getOwnPropertyNames(datos[0]);
    else
      return null;
  }

  base64aBlob(b64Data: string, contentType: string, sliceSize: number): Blob {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  // Carga el script
  cargarScript(nombre: string) {
    return new Promise((resolve, reject) => {
      // Resuelve si está cargado
      if (this.scripts[nombre].cargado) {
        resolve({ script: nombre, cargado: true });
      } else {
        // load script
        const script: any = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[nombre].src;

        // cross browser handling of onLoaded event
        if (script.readyState) {  // IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.scripts[nombre].cargado = true;
              resolve({ script: nombre, cargado: true });
            }
          };
        } else {  // Others
          script.onload = () => {
            this.scripts[nombre].cargado = true;
            resolve({ script: nombre, cargado: true });
          };
        }
        script.onerror = (error: any) => resolve({ script: nombre, cargado: false });

        // finally append the script tag in the DOM
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}