import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalesService } from 'servicios/globales.service';
import { AutenticacionService } from 'servicios/autenticacion.service';
import { environment } from '../environments/environment';
import { TasaCambio } from 'modelos/TasaCambio';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { InfoCanalClima } from 'modelos/Clima/InfoCanalClima';
import { ServiciosExternosService } from 'servicios/serviciosexternos.service';
import { AdministracionService } from 'servicios/administracion.service';
import { CentroProduccion } from 'modelos/CentroProduccion';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset);
  error: any;
  @ViewChild('modal') modal: ElementRef;
  titulo: string;
  mensaje: string;
  version: string;
  fecha: Date;
  tasaCambio: TasaCambio;
  climas: InfoCanalClima[] = [];
  Temperatura: string;
  climaUsuario: InfoCanalClima;
  idCentroProduccion: number;
  centrosProduccion: CentroProduccion[];
  idCentroProduccionSeleccionado: number;
  nombreCP: string;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public globalesService: GlobalesService,
    public autenticacionService: AutenticacionService,
    public router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private serviciosExternosService: ServiciosExternosService,
    private administracionService: AdministracionService,
    private authService: MsalService
  ) {
    this.matIconRegistry.addSvgIcon("temperatura", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/iconos/temperature-high-solid.svg"));
    this.matIconRegistry.addSvgIcon("barometer", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/iconos/barometer.svg"));
    this.matIconRegistry.addSvgIcon("thermometerC", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/iconos/thermometer.svg"));
    this.matIconRegistry.addSvgIcon("wind", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/iconos/wind.svg"));
  }

  ngOnInit() {
    console.log("Componente principal");
    this.version = environment.version;
    this.titulo = environment.titulo;

    /*
    this.autenticacionService.inicializar();

    if (this.autenticacionService.autenticado)
      this.globalesService.procesarAutenticacion();
    else
      this.router.navigateByUrl("login");*/

    this.globalesService.idCentroProduccion.subscribe(
      idCentro => {
        this.administracionService.ObtenerCentrosProduccion().subscribe(
          centrosProduccion => {
            this.centrosProduccion = centrosProduccion;
            this.centrosProduccion.forEach(cp => {
              if (cp.IdCentroProduccion == idCentro) {
                this.nombreCP = cp.Nombre;
              }
            });
          }
        );
        this.ObtenerInformacionCanalClima(idCentro);
      }
    );
  }

  logout() {
    this.autenticacionService.borrarSesion();
    this.authService.logout();
    //location.reload();
    //this.router.navigateByUrl("login");
  }

  MostrarAyuda() {
    window.open("https://funzanetcloud.organizacionfunza.net/ayuda/Manual%20de%20usuario%20funz%40net.html");
  }

  ObtenerInformacionCanalClima(idCentro) {
    this.climaUsuario = new InfoCanalClima();

    this.serviciosExternosService.ObtenerInformacionCanalClima().subscribe(
      climas => {
        this.climas = climas;
        switch (idCentro) {

          //FLORES DEL RIO, ALBORADA Y BMC
          case 1:
            this.climaUsuario = this.climas.find(clima => clima.IdEstacion === 712);
            this.climaUsuario.Nombre = "F. del Rio - F. Alborada";
            break;

          case 5:
            this.climaUsuario = this.climas.find(clima => clima.IdEstacion === 712);
            this.climaUsuario.Nombre = "F. del Rio - F. Alborada";
            break;

          case 8:
            this.climaUsuario = this.climas.find(clima => clima.IdEstacion === 712);
            this.climaUsuario.Nombre = "F. del Rio - F. Alborada";
            break;

          //FLORES DE FUNZA Y BOSQUE
          case 2:
            this.climaUsuario = this.climas.find(clima => clima.IdEstacion === 710);
            this.climaUsuario.Nombre = "F. de Funza - F. del Bosque";
            break;

          case 3:
            this.climaUsuario = this.climas.find(clima => clima.IdEstacion === 710);
            this.climaUsuario.Nombre = "F. de Funza - F. del Bosque";
            break;

          //FACA Y ROSAL
          case 6:
            this.climaUsuario = this.climas.find(clima => clima.IdEstacion === 713);
            this.climaUsuario.Nombre = "Cardenal Faca";
            break;

          case 7:
            this.climaUsuario = this.climas.find(clima => clima.IdEstacion === 713);
            this.climaUsuario.Nombre = "Cardenal Faca";
            break;

          //FLEXPORT
          case 4:
            this.climaUsuario = this.climas.find(clima => clima.IdEstacion === 714);
            this.climaUsuario.Nombre = "Flexport";
            break;

          default:
            this.climaUsuario = this.climas[0];
            break;
        }

      },
      error => {
      }
    );

    setInterval(() => {
      this.serviciosExternosService.ObtenerInformacionCanalClima().subscribe(
        climas => {
          this.climas = climas;
          switch (this.idCentroProduccion) {
            //FLORES DEL RIO, ALBORADA Y BMC
            case 1:
              this.climaUsuario = this.climas.find(clima => clima.IdEstacion === 712);
              this.climaUsuario.Nombre = "F. del Rio - F. Alborada";
              break;

            case 5:
              this.climaUsuario = this.climas.find(clima => clima.IdEstacion === 712);
              this.climaUsuario.Nombre = "F. del Rio - F. Alborada";
              break;

            case 8:
              this.climaUsuario = this.climas.find(clima => clima.IdEstacion === 712);
              this.climaUsuario.Nombre = "F. del Rio - F. Alborada";
              break;

            //FLORES DE FUNZA Y BOSQUE
            case 2:
              this.climaUsuario = this.climas.find(clima => clima.IdEstacion === 710);
              this.climaUsuario.Nombre = "F. de Funza - F. del Bosque";
              break;

            case 3:
              this.climaUsuario = this.climas.find(clima => clima.IdEstacion === 710);
              this.climaUsuario.Nombre = "F. de Funza - F. del Bosque";
              break;

            //FACA Y ROSAL
            case 6:
              this.climaUsuario = this.climas.find(clima => clima.IdEstacion === 713);
              this.climaUsuario.Nombre = "Cardenal Faca";
              break;

            case 7:
              this.climaUsuario = this.climas.find(clima => clima.IdEstacion === 713);
              this.climaUsuario.Nombre = "Cardenal Faca";
              break;

            //FLEXPORT
            case 4:
              this.climaUsuario = this.climas.find(clima => clima.IdEstacion === 714);
              this.climaUsuario.Nombre = "Flexport";
              break;

            default:
              this.climaUsuario = this.climas[0];
              break;
          }
        },
        error => {

        }
      );
    }, 300000);
  }
}