export class InfoCanalClima {
  IdEstacion: number;
    Nombre: string;
    Fecha: Date;
    Precipitacion: string;
    Precipitacion_Diaria: string;
    Temperatura: string;
    Humedad: string;
    VelocidadViento: string;
    DirViento: string;
    Barometro: string;
    P_Rocio: string;
    Evotrans: string;
    RadSolar: string;
    UV: string
  }


  