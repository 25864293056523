import { Component, OnInit, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatDialog, MatDialogRef, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AdministracionService } from 'servicios/administracion.service';
import { GlobalesService } from 'servicios/globales.service';
import { Tabla } from 'modelos/Tabla';
import { OperacionParametrica } from 'modelos/OperacionParametrica';
import { CampoTabla } from 'modelos/CampoTabla';
import { DetalleParametricasDialogoComponent } from 'vistas/parametricas/detalle-parametricas-dialogo/detalle-parametricas-dialogo.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-parametricas',
  templateUrl: './parametricas.component.html',
  styleUrls: ['./parametricas.component.css']
})
export class ParametricasComponent implements OnInit {
  detalleParametricasDialogo: MatDialogRef<DetalleParametricasDialogoComponent>;
  tablas: Tabla[] = [];
  tabla: Tabla;
  registros: any[] = [];
  columnasDatos: string[] = [];
  columnasTabla: string[] = [];
  dataSource: MatTableDataSource<any>;
  camposTabla: CampoTabla[];
  camposEditables: CampoTabla[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  fechaCreacion: Date;
  creadoPor: string;
  fechaActualizacion: Date;
  actualizadoPor: Date;

  constructor(
    public globalesService: GlobalesService,
    private administracionService: AdministracionService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    // Obtiene las tablas
    this.administracionService.ObtenerTablasCatalogo().subscribe(
      tablas => {
        this.tablas = tablas
        this.tabla = tablas[0];
      },
      error => this.toastr.error("Error al obtener las tablas paramétricas. Intente más tarde por favor.", "ERROR")
    );
  }

  MostrarDetalleParametrica(registro) {
    let valorLlavePrimaria: any = null;

    if (registro) {
      let campo: CampoTabla = this.camposTabla.filter(ct => ct.EsLlavePrimaria)[0];

      // Determina la llave primaria
      for (let propiedad of Object.getOwnPropertyNames(registro))
        if (propiedad.toLocaleLowerCase() == campo.NombreColumna.toLocaleLowerCase()) {
          valorLlavePrimaria = registro[propiedad];

          // Muestra el cuadro de diálogo para editar el registro seleccionado
          this.administracionService.ObtenerRegistroTabla(this.tabla.IdTabla, campo.NombreColumna, valorLlavePrimaria).subscribe(
            registroTabla => {
              this.detalleParametricasDialogo = this.dialog.open(DetalleParametricasDialogoComponent, { width: "320px", data: { "camposTabla": this.camposEditables, "registro": registroTabla, "tabla": this.tabla.Alias } });

              this.detalleParametricasDialogo.afterClosed().subscribe(
                data => this.CierreDetalleParametricasDialogo(data, valorLlavePrimaria)
              );
            },
            error => this.toastr.error("Error al obtener el registro seleccionado. Intente más tarde por favor.", "ERROR")
          );

          break;
        }
    }
    else {
      // Muestra el cuadro de diálogo para insertar un nuevo registro
      this.detalleParametricasDialogo = this.dialog.open(DetalleParametricasDialogoComponent, { width: "320px", data: { "camposTabla": this.camposEditables, "registro": null, "tabla": this.tabla.Alias } });

      this.detalleParametricasDialogo.afterClosed().subscribe(
        data => this.CierreDetalleParametricasDialogo(data, valorLlavePrimaria)
      );
    }
  }

  private CierreDetalleParametricasDialogo(data: any, valorLlavePrimaria: any) {
    let columnaPK: string = null;

    if (data) {
      // Carga los campos editables con la información
      let columnas: string[] = Object.getOwnPropertyNames(data);
      let valores: string[] = [];

      for (let columna of columnas) {
        if (typeof data[columna] === "string")
          valores.push("'" + data[columna] + "'");
        else
          if (typeof data[columna] === "boolean")
            valores.push(data[columna] ? "1" : "0");
          else
            if (data[columna] instanceof Date)
              valores.push("'" + formatDate(data[columna], "yyyy-MM-dd", "en") + "'");
            else
              if (data[columna])
                valores.push(data[columna]);
              else
                valores.push("null");
      }

      // Agrega los campos no editables
      for (let campo of this.camposTabla)
        if (!this.camposEditables.includes(campo)) {
          if (valorLlavePrimaria) {
            if (campo.EsLlavePrimaria)
              columnaPK = campo.NombreColumna;
            else
              if (["updatedby", "actualizadopor"].includes(campo.NombreColumna.toLowerCase())) {
                columnas.push(campo.NombreColumna);
                valores.push("'" + this.globalesService.usuario + "'");
              }
              else
                if (["updateddate", "fechaactualizacion"].includes(campo.NombreColumna.toLowerCase())) {
                  columnas.push(campo.NombreColumna);
                  valores.push("'" + new Date().toISOString() + "'");
                }
          }
          else {
            if (["createdby", "updatedby", "creadopor", "actualizadopor"].includes(campo.NombreColumna.toLowerCase())) {
              columnas.push(campo.NombreColumna);
              valores.push("'" + this.globalesService.usuario + "'");
            }
            else
              if (["createddate", "updateddate", "fechacreacion", "fechaactualizacion"].includes(campo.NombreColumna.toLowerCase())) {
                columnas.push(campo.NombreColumna);
                valores.push("'" + new Date().toISOString() + "'");
              }
          }
        }

      let operacionParametrica: OperacionParametrica = new OperacionParametrica();
      operacionParametrica.Operacion = valorLlavePrimaria ? "U" : "I";
      operacionParametrica.IdTabla = this.tabla.IdTabla;
      operacionParametrica.Columnas = columnas.join(",");
      operacionParametrica.Valores = valores.join(",");
      operacionParametrica.ColumnaPK = columnaPK;
      operacionParametrica.ValorPK = valorLlavePrimaria;

      this.administracionService.TransaccionRegistrosTabla(operacionParametrica).subscribe(
        () => {
          this.toastr.success("Se ha guardado el valor.", this.tabla.Alias);
          this.ObtenerRegistrosTabla();
        },
        error => this.toastr.error(error, this.tabla.Alias)
      );
    }
  }

  ObtenerRegistrosTabla() {
    if (this.tabla)
      this.spinner.show();
    this.administracionService.ObtenerRegistrosTabla(this.tabla.IdTabla).subscribe(
      registros => {
        this.spinner.hide();
        // Carga la tabla
        this.columnasDatos = this.ExcluirInformacionHistorial(this.globalesService.obtenerColumnas(registros));
        this.columnasTabla = Object.assign([], this.columnasDatos);
        this.columnasTabla.push("Opciones");
        this.dataSource = new MatTableDataSource<any>(registros);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.paginator.firstPage();
        // Obtiene los campos completos de la tabla
        this.administracionService.ObtenerCamposTabla(this.tabla.IdTabla).subscribe(
          camposTabla => {
            this.camposTabla = camposTabla;
            this.camposEditables = camposTabla.filter(ct => this.esVisible(ct));

          },
          error => {
            this.toastr.error("Error al obtener los campos de las tablas. Intente más tarde por favor.", "ERROR")
            this.spinner.hide();
          }
        );
      },
      error => {
        this.toastr.error("Error al obtener los registros de las tablas. Intente más tarde por favor.", "ERROR")
        this.spinner.hide();
      });
  }

  AplicarFiltro(filtro: string) {
    this.dataSource.filter = filtro;
  }

  ObtenerTipo(valor: any): string {
    let resultado: string;

    if (typeof valor === "string") {
      if ((<string>valor).match(/\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]/)) {
        resultado = "Date";
      }
    }
    else
      if (typeof valor === "boolean")
        resultado = "boolean";

    return resultado;
  }

  private esVisible(campo: CampoTabla): boolean {
    if (campo.EsLlavePrimaria)
      return false;

    if (["createdby", "createddate", "updatedby", "updateddate", "creadopor", "fechacreacion", "actualizadopor", "fechaactualizacion"].includes(campo.NombreColumna.toLowerCase()))
      return false;

    return true;
  }

  AbrirEliminarModal(modal: any, registro: any) {
    // Determina el campo que tiene la llave primaria
    let campo: CampoTabla = this.camposTabla.filter(ct => ct.EsLlavePrimaria)[0];

    // Obtiene el valor de la llave primaria
    for (let propiedad of Object.getOwnPropertyNames(registro))
      if (propiedad.toLocaleLowerCase() == campo.NombreColumna.toLocaleLowerCase()) {
        this.modalService.open(modal, { size: 'sm' }).result.then(
          result => {
            if (result == "eliminar") {
              let operacionParametrica: OperacionParametrica = new OperacionParametrica();
              operacionParametrica.Operacion = "D";
              operacionParametrica.IdTabla = this.tabla.IdTabla;
              operacionParametrica.Columnas = null;
              operacionParametrica.Valores = null;
              operacionParametrica.ColumnaPK = campo.NombreColumna;
              operacionParametrica.ValorPK = registro[propiedad];

              this.administracionService.TransaccionRegistrosTabla(operacionParametrica).subscribe(
                () => {
                  this.toastr.success("Se ha borrado el valor.", this.tabla.Alias);
                  this.ObtenerRegistrosTabla();
                },
                error => this.toastr.error(error, this.tabla.Alias)
              );
            }
          },
          reason => { }
        );
      }
  }

  AbrirHistorialModal(modal: any, registro: any) {
    if (registro["CreatedDate"])
      this.fechaCreacion = registro["CreatedDate"];
    else
      if (registro["FechaCreacion"])
        this.fechaCreacion = registro["FechaCreacion"];
      else
        this.fechaCreacion = null;

    if (registro["CreatedBy"])
      this.creadoPor = registro["CreatedBy"];
    else
      if (registro["CreadoPor"])
        this.creadoPor = registro["CreadoPor"];
      else
        this.creadoPor = null;

    if (registro["UpdatedDate"])
      this.fechaActualizacion = registro["UpdatedDate"];
    else
      if (registro["FechaActualizacion"])
        this.fechaActualizacion = registro["FechaActualizacion"];
      else
        this.fechaActualizacion = null;

    if (registro["UpdatedBy"])
      this.actualizadoPor = registro["UpdatedBy"];
    else
      if (registro["ActualizadoPor"])
        this.actualizadoPor = registro["ActualizadoPor"];
      else
        this.actualizadoPor = null;

    // Muestra el modal
    this.modalService.open(modal, { size: 'sm' });
  }

  ExcluirInformacionHistorial(columnas: string[]): string[] {
    return columnas.filter((e, i, a) => {
      let elc: string = e.toLowerCase();

      return !(elc == "createddate" || elc == "createdby" || elc == "updateddate" || elc == "updatedby" ||
        elc == "fechacreacion" || elc == "creadopor" || elc == "fechaactualizacion" || elc == "actualizadopor" || elc.includes("id"));
    });
  }
}