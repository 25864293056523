import { Injectable } from '@angular/core';
import { HttpService } from 'servicios/http.service';
import { Observable } from 'rxjs';
import { User } from 'modelos/Estadisticos/User';
import { Role } from 'modelos/Estadisticos/Role';
import { Report } from 'modelos/Estadisticos/Report';
import { environment } from 'environments/environment';

@Injectable()
export class EstadisticosService extends HttpService {
    urlBase = environment.urlApiEstadisticos;

    ObtenerUsuarios(): Observable<User[]> {
        return this.get<User[]>('/Usuarios');
    }

    ObtenerUsuario(accountName: string): Observable<User> {
        return this.get<User>(`/Usuario/${accountName}`);
    }

    ObtenerReportesUsuario(userId: number): Observable<string[]> {
        return this.get<string[]>(`/ReportesUsuario/${userId}`);
    }

    GuardarUsuario(user: User): Observable<number> {
        return this.post<number>("/Usuario", user);
    }

    BorrarUsuario(userId: number): Observable<void> {
        return this.delete<void>(`/Usuario/${userId}`);
    }

    ObtenerRoles(): Observable<Role[]> {
        return this.get<Role[]>('/Roles');
    }

    GuardarRol(role: Role): Observable<number> {
        return this.post<number>("/Rol", role);
    }

    BorrarRol(roleId: number): Observable<void> {
        return this.delete<void>(`/Rol/${roleId}`);
    }

    ObtenerReportes(): Observable<Report[]> {
        return this.get<Report[]>('/Reportes');
    }

    ObtenerReporte(name: string): Observable<Report> {
        return this.get<Report>(`/Reporte/${name}`);
    }

    ObtenerUsuariosReporte(reportId: number): Observable<string[]> {
        return this.get<string[]>(`/UsuariosReporte/${reportId}`);
    }

    GuardarReporte(report: Report): Observable<number> {
        return this.post<number>("/Reporte", report);
    }

    BorrarReporte(reportId: number): Observable<void> {
        return this.delete<void>(`/Reporte/${reportId}`);
    }
}