import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'servicios/http.service';
import { Especie } from 'modelos/Especie';
import { Color } from 'modelos/Color';
import { Variedad } from 'modelos/Variedad';
import { VariedadCentroProduccion } from 'modelos/VariedadCentroProduccion';
import { EspecieCentroProduccion } from 'modelos/EspecieCentroProduccion';
import { PrecioEspecie } from 'modelos/PrecioEspecie';
import { CategoriaVariedad } from 'modelos/CategoriaVariedad';
import { Grado } from 'modelos/Grado';
import { Arancelaria } from 'modelos/Arancelaria';
import { Empaque } from 'modelos/Empaque';
import { VEspecieCentroProduccion } from 'modelos/VEspecieCentroProduccion';
import { ResultadoBusqueda } from 'modelos/ResultadoBusqueda';
import { FiltroBusquedaCatalogo } from 'modelos/FiltroBusquedaCatalogo';
import { Filtro } from 'modelos/Filtro';
import { VProductoCatalogoReferencia } from 'modelos/VProductoCatalogoReferencia';
import { CategoriaProducto } from 'modelos/CategoriaProducto';
import { CatalogoProducto } from 'modelos/CatalogoProducto';
import { VRecetaCatalogoReferencia } from 'modelos/VRecetaCatalogoReferencia';
import { VItemCatalogoReferencia } from 'modelos/VItemCatalogoReferencia';
import { TipoEmpaque } from 'modelos/TipoEmpaque';
import { PrecioReceta } from 'modelos/PrecioReceta';
import { CategoriaTallos } from 'modelos/CategoriaTallos';
import { CatalogoReceta } from 'modelos/CatalogoReceta';
import { CatalogoPieza } from 'modelos/CatalogoPieza';
import { VariedadCP } from 'modelos/VariedadCP';
import { TipoEmpaqueSimplificado } from 'modelos/TipoEmpaqueSimplificado';
import { EmpaqueSimplificado } from 'modelos/EmpaqueSimplificado';

@Injectable()
export class ProductoService extends HttpService {

  ObtenerColores(): Observable<Color[]> {
    return this.get<Color[]>("/Colores");
  }

  ObtenerColoresEspecie(idEspecie: number): Observable<Color[]> {
    return this.get<Color[]>(`/ColorEspecie/${idEspecie}`);
  }
  ObtenerColoresEspecieEtiquetas(idEspecie: number, entradasTecnico?: boolean): Observable<Color[]> {
    if (entradasTecnico)
      return this.get<Color[]>(`/ColorEspecieEstiquetas/${idEspecie}/${entradasTecnico}`);
    else
      return this.get<Color[]>(`/ColorEspecieEstiquetas/${idEspecie}`);
  }

  ObtenerColoresPorVariedad(idVariedad: number): Observable<Color[]> {
    return this.get<Color[]>(`/ColoresVariedad/${idVariedad}`);
  }

  GuardarColor(color: Color): Observable<number> {
    return this.post<number>("/Color", color);
  }

  ObtenerVariedades(): Observable<Variedad[]> {
    return this.get<Variedad[]>("/Variedades");
  }

  ObtenerVariedadesEspecieColor(idEspecie: number, idColor: number): Observable<Variedad[]> {
    return this.get<Variedad[]>(`/Variedades/${idEspecie}/${idColor}`);
  }

  GuardarVariedad(variedad: Variedad): Observable<number> {
    return this.post<number>("/Variedad", variedad);
  }

  ObtenerEspecies(): Observable<Especie[]> {
    return this.get<Especie[]>("/Especies");
  }

  ObtenerCategoriasVariedad(): Observable<CategoriaVariedad[]> {
    return this.get<CategoriaVariedad[]>("/CategoriasVariedad");
  }

  ObtenerPreciosEspecie(): Observable<PrecioEspecie[]> {
    return this.get<PrecioEspecie[]>("/PreciosEspecies");
  }

  BorrarPreciosEspecie(idPrecioEspecie: number): Observable<void> {
    return this.delete<void>(`/BorrarPrecioEspecie/${idPrecioEspecie}`);
  }

  ObtenerVariedadesCentroProduccion(idCentroProduccion: number, idEspecie?: number): Observable<VariedadCP[]> {
    if (idEspecie != null)
      return this.get<VariedadCP[]>(`/VariedadesCentroProduccion/${idCentroProduccion}/${idEspecie}`);
    else
      return this.get<VariedadCP[]>(`/VariedadesCentroProduccion/${idCentroProduccion}`);
  }

  ObtenerVariedadesCentroProduccionEtiquetas(idCentroProduccion: number, idEspecie: number, idColor: number, entradasTecnico?: boolean): Observable<Variedad[]> {
    if (entradasTecnico)
      return this.get<Variedad[]>(`/VariedadesCentroProduccionEtiquetas/${idCentroProduccion}/${idEspecie}/${idColor}/${entradasTecnico}`);
    else
      return this.get<Variedad[]>(`/VariedadesCentroProduccionEtiquetas/${idCentroProduccion}/${idEspecie}/${idColor}`);
  }

  GuardarVariedadCentroProduccion(variedadCentroProduccion: VariedadCentroProduccion): Observable<number> {
    return this.post<number>("/VariedadCentroProduccion", variedadCentroProduccion);
  }

  ObtenerEspeciesCentroProduccion(idCentroProduccion: number): Observable<VEspecieCentroProduccion[]> {
    return this.get<VEspecieCentroProduccion[]>(`/EspecieCentroProduccion/${idCentroProduccion}`);
  }

  ObtenerEspeciesPorCentroProduccionEtiquetas(idCentroProduccion: number, entradasTecnico?: boolean): Observable<VEspecieCentroProduccion[]> {
    if (entradasTecnico)
      return this.get<VEspecieCentroProduccion[]>(`/EspecieCentroProduccionEtiquetas/${idCentroProduccion}/${entradasTecnico}`);
    else
      return this.get<VEspecieCentroProduccion[]>(`/EspecieCentroProduccionEtiquetas/${idCentroProduccion}`);
  }

  ObtenerEspecieCentroProduccionIdespecie(idCentroProduccion: number, idEspecie: number) {
    return this.get<VEspecieCentroProduccion[]>(`/EspecieCentroProduccion/${idCentroProduccion}/${idEspecie}`);
  }

  GuardarEspeciesCentroProduccion(especieCentroProduccion: EspecieCentroProduccion): Observable<number> {
    return this.post<number>("/EspecieCentroProduccion", especieCentroProduccion);
  }

  BorrarVariedadCentroProduccion(idVariedad: number, idCentroProduccion: number): Observable<void> {
    return this.delete<void>(`/BorrarVariedadCentroProduccion/${idVariedad}/${idCentroProduccion}`);
  }

  BorrarEspecieCentroProduccion(idEspecieCentroProduccion: number): Observable<void> {
    return this.delete<void>(`/BorrarEspecieCentroProduccion/${idEspecieCentroProduccion}`);
  }

  GuardarPreciosEspecie(precioespecie: PrecioEspecie): Observable<PrecioEspecie[]> {
    return this.post<PrecioEspecie[]>("/PrecioEspecie", precioespecie);
  }

  ObtenerGrados(): Observable<Grado[]> {
    return this.get<Grado[]>("/Grados");
  }

  ObtenerGradosEspecie(idEspecie: number): Observable<Grado[]> {
    return this.get<Grado[]>(`/GradosEspecie/${idEspecie}`);
  }

  GuardarGrado(grado: Grado): Observable<number> {
    return this.post<number>("/Grado", grado);
  }

  ObtenerArancelarias(): Observable<Arancelaria[]> {
    return this.get<Arancelaria[]>("/Arancelarias");
  }

  GuardarEspecie(especie: Especie): Observable<number> {
    return this.post<number>("/Especie", especie);
  }

  ObtenerEmpaques(): Observable<Empaque[]> {
    return this.get<Empaque[]>("/Empaques");
  }

  ObtenerEmpaquesSimplificado(): Observable<EmpaqueSimplificado[]> {
    return this.get<EmpaqueSimplificado[]>("/EmpaquesSimplificado");
  }

  GuardarEmpaque(empaque: Empaque): Observable<number> {
    return this.post<number>("/Empaque", empaque);
  }

  BuscarReferencias(palabrasClave: string, idTipoProducto: number, pagina: number, filtro: FiltroBusquedaCatalogo[]): Observable<ResultadoBusqueda> {
    return this.post<ResultadoBusqueda>(`/BuscarReferencias/${palabrasClave}/${idTipoProducto}/${pagina}`, filtro);
  }

  ObtenerProductosCatalogo(filtro: Filtro): Observable<VProductoCatalogoReferencia[]> {
    return this.post<VProductoCatalogoReferencia[]>("/Productos", filtro);
  }

  ObtenerCategoriasProducto(): Observable<CategoriaProducto[]> {
    return this.get<CategoriaProducto[]>("/CategoriaProductos");
  }

  ActivarInactivarReferencia(filtro: Filtro): Observable<boolean> {
    return this.post<boolean>("/ActualizaReferencia", filtro);
  }

  GuardarCatalogoProducto(producto: CatalogoProducto): Observable<number> {
    return this.post<number>("/Producto", producto);
  }


  ObtenerPreciosReceta(): Observable<PrecioReceta[]> {
    return this.get<PrecioReceta[]>("/PreciosReceta");
  }

  GuardarPreciosReceta(precioreceta: PrecioReceta): Observable<PrecioReceta> {
    return this.post<PrecioReceta>("/PrecioReceta", precioreceta);
  }

  BorrarPreciosReceta(idPrecioReceta: number): Observable<void> {
    return this.delete<void>(`/BorrarPreciosReceta/${idPrecioReceta}`);
  }

  ObtenerRecetasCatalogo(filtro: Filtro): Observable<VRecetaCatalogoReferencia[]> {
    return this.post<VRecetaCatalogoReferencia[]>("/Recetas", filtro);
  }


  ObtenerPiezasCatalogo(filtro: Filtro): Observable<VItemCatalogoReferencia[]> {
    return this.post<VItemCatalogoReferencia[]>("/Piezas", filtro);
  }


  ObtenerTiposEmpaque(): Observable<TipoEmpaque[]> {
    return this.get<TipoEmpaque[]>("/ObtenerTiposEmpaque");
  }

  ObtenerTiposEmpaqueSimplificado(): Observable<TipoEmpaqueSimplificado[]> {
    return this.get<TipoEmpaqueSimplificado[]>("/ObtenerTiposEmpaqueSimplificado");
  }

  ObtenerCategoriaTallo(): Observable<CategoriaTallos[]> {
    return this.get<CategoriaTallos[]>("/CategoriaTallos");
  }

  GuardarReceta(receta: CatalogoReceta): Observable<number> {
    return this.post<number>("/Receta", receta);
  }

  GuardarPieza(pieza: CatalogoPieza): Observable<number> {
    return this.post<number>("/Pieza", pieza);
  }

  ObtenerEspecie(idespecie: number): Observable<Especie> {

    return this.get<Especie>(`/Especie/${idespecie}`);

  }

  ObtenerColor(idcolor: number): Observable<Color> {

    return this.get<Color>(`/Color/${idcolor}`);

  }

  ObtenerVariedad(idvariedad: number): Observable<Variedad> {

    return this.get<Variedad>(`/Variedad/${idvariedad}`);

  }

  ObtenerGrado(idgrado: number): Observable<Grado> {

    return this.get<Grado>(`/Grado/${idgrado}`);

  }

}
