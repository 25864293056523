import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GlobalesService } from 'servicios/globales.service';

@Component({
  selector: 'app-visor-reporte',
  templateUrl: './visor-reporte.component.html',
  styleUrls: ['./visor-reporte.component.css']
})
export class VisorReporteComponent implements OnInit {
  nombre: string;
  url: SafeResourceUrl;

  constructor(
    private activatedRoute: ActivatedRoute,
    private globalesService: GlobalesService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      let ruta: string;
      let verParametros: boolean = true;
      let verBarraHerramientas: boolean = true;
      let parametros: {
        nombre: string;
        valor: string;
      }[] = [];
      let url: string;

      // Lee los parámetros
      for (let nombre in params) {
        switch (nombre.toLowerCase()) {
          case "nombre": {
            this.nombre = params[nombre];
            break;
          }

          case "ruta": {
            ruta = params[nombre];
            break;
          }

          case "parametros": {
            verParametros = <boolean>params[nombre];
            break;
          }

          case "barraherramientas": {
            verBarraHerramientas = <boolean>params[nombre];
            break;
          }

          default: {
            parametros.push({
              "nombre": nombre,
              "valor": params[nombre]
            });
            break;
          }
        }
      }

      // Genera la url base del reporte
      url = `${this.globalesService.urlReportes}/${ruta}?rs:Embed=true&rc:parameters=${verParametros}&rc:toolbar=${verBarraHerramientas}`;

      // Agrega los parámetros
      for (let parametro of parametros)
        url += `&${parametro.nombre}=${parametro.valor}`;

      // Desinfecta la url para que no arroje error
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }
}
