import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'servicios/http.service';
import { HttpHeaders } from '@angular/common/http';
import { Filtro } from 'modelos/Filtro';
import { Ruta } from 'modelos/Logistica/Ruta';
import { TipoVehiculo } from 'modelos/Logistica/TipoVehiculo';
import { Contratista } from 'modelos/Logistica/Contratista';
import { Conductor } from 'modelos/Logistica/Conductor';
import { Vehiculo } from 'modelos/Logistica/Vehiculo';
import { ConductorRelVehiculo } from 'modelos/Logistica/ConductorRelVehiculo';
import { Concepto } from 'modelos/Logistica/Concepto';
import { ConceptoLiquidacion } from 'modelos/Logistica/ConceptoLiquidacion';
import { ResultadoTransporteAeropuerto } from 'modelos/Logistica/ResultadoTransporteAeropuerto';
import { TransporteAdicional } from 'modelos/logistica/TransporteAdicional';
import { TransporteLiquidacion } from 'modelos/logistica/TransporteLiquidacion';
import { Transporte } from 'modelos/logistica/Transporte';
import { LoginSispap } from 'modelos/logistica/Sispap/LoginSispap';
import { ResultadoItemsPedidoLogistica } from 'modelos/logistica/ResutadoItemsPedidoLogistica';
import { PlanillaCargue } from 'modelos/logistica/PlanillaCargue';
import { PrePlanillaCargue } from 'modelos/logistica/PrePlanillaCargue';
import { VItemTransporteCP } from 'modelos/logistica/VItemTransporteCP';
import { AduanaSispap } from 'modelos/logistica/Sispap/AduanaSispap';
import { DestinatarioSispap } from 'modelos/logistica/Sispap/DestinatarioSispap';
import { PaisSispap } from 'modelos/logistica/Sispap/PaisSispap';
import { PuertoEntradaSispap } from 'modelos/logistica/Sispap/PuertoEntradaSispap';
import { PuertoSalidaSispap } from 'modelos/logistica/Sispap/PuertoSalidaSispap';
import { UnidadMedidaSispap } from 'modelos/logistica/Sispap/UnidadMedidaSispap';
import { DistribuidorSispap } from 'modelos/logistica/Sispap/DistribuidorSispap';
import { ResultadoItemsSispapGuia } from 'modelos/logistica/Sispap/ResultadoItemsSispapGuia';
import { EspecieSispap } from 'modelos/logistica/Sispap/EspecieSispap';
import { EmpaqueSispap } from 'modelos/logistica/Sispap/EmpaqueSispap';
import { ParametrosXMLSispap } from 'modelos/logistica/Sispap/ParametrosXMLSispap';
import { FitoSispap } from 'modelos/logistica/Sispap/FitoSispap';
import { AerolineaSispap } from 'modelos/logistica/Sispap/AerolineaSispap';
import { formatDate } from '@angular/common';

import { Response } from '_debugger';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/pdf'
  })
};


@Injectable()
export class LogisticaService extends HttpService {

  //#region conductores
  ObtenerCondutores(): Observable<Conductor[]> {
    return this.get<Conductor[]>("/Logistica/Conductores");
  }

  GuardarConductor(conductor: Conductor): Observable<number> {
    return this.post<number>("/Logistica/Conductor", conductor);
  }

  BorrarConductor(idConductor: number): Observable<void> {
    return this.delete<void>(`/Logistica/Conductor/${idConductor}`);
  }

  //#endregion

  //#region vehiculos
  ObtenerVehiculos(): Observable<Vehiculo[]> {
    return this.get<Vehiculo[]>("/Logistica/Vehiculos");
  }



  GuardarVehiculo(vehiculo: Vehiculo): Observable<number> {
    return this.post<number>("/Logistica/Vehiculo", vehiculo);
  }

  ObtenerTiposVehiculos(): Observable<TipoVehiculo[]> {
    return this.get<TipoVehiculo[]>("/Logistica/TiposVehiculo");
  }

  ObtenerVehiculosConductor(idConductor: number): Observable<ConductorRelVehiculo[]> {
    return this.get<ConductorRelVehiculo[]>(`/Logistica/VehiculosConductor/${idConductor}`);
  }

  ObtenerConductoresVehiculo(idVehiculo: number): Observable<ConductorRelVehiculo[]> {
    return this.get<ConductorRelVehiculo[]>(`/Logistica/ConductoresVehiculo/${idVehiculo}`);
  }


  AsignarConductorAVehiculo(vehiculo: ConductorRelVehiculo): Observable<number> {
    return this.post<number>("/Logistica/VehiculoConductor", vehiculo);
  }


  BorrarConductorVehiculo(idVehiculoConductor: number): Observable<void> {
    return this.delete<void>(`/Logistica/VehiculoConductor/${idVehiculoConductor}`);
  }
  //#endregion

  //#region rutas

  ObtenerRutas(soloHabilitadas: boolean = false): Observable<Ruta[]> {
    if (soloHabilitadas)
      return this.get<Ruta[]>("/Logistica/Rutas/true");
    else
      return this.get<Ruta[]>("/Logistica/Rutas");
  }

  GenerarExcelRutas(): Observable<string> {
    return this.get<string>("/Logistica/ExcelRutas");
  }

  GuardarRuta(ruta: Ruta): Observable<number> {
    return this.post<number>("/Logistica/Ruta", ruta);
  }


  IncrementarValorRutas(filtro: Filtro): Observable<number> {
    return this.post<number>("/IncrementarValorRutas", filtro);
  }

  //#endregion

  //#region contratistas
  ObtenerContratistas(soloHabilitadas: boolean = false): Observable<Contratista[]> {
    if (soloHabilitadas)
      return this.get<Contratista[]>("/Logistica/Contratistas/true");
    else
      return this.get<Contratista[]>("/Logistica/Contratistas");
  }

  GuardarContratista(contratista: Contratista): Observable<number> {
    return this.post<number>("/Logistica/Contratista", contratista);
  }
  //#endregion

  //#region conceptos


  ObtenerConceptos(): Observable<Concepto[]> {
    return this.get<Concepto[]>("/Logistica/Conceptos");
  }


  ObtenerConceptosLiquidacion(): Observable<ConceptoLiquidacion[]> {
    return this.get<ConceptoLiquidacion[]>("/Logistica/ConceptosLiquidacion");
  }

  GuardarConcepto(concepto: Concepto): Observable<number> {
    return this.post<number>("/Logistica/Concepto", concepto);
  }

  GuardarConceptoLiquidacion(conceptoliquidacion: ConceptoLiquidacion): Observable<number> {
    return this.post<number>("/Logistica/ConceptoLiquidacion", conceptoliquidacion);
  }



  //#endregion

  //#region  TransporteLiquidacion

  ObtenerTransporteLiquidacion(fechaInicial: Date, fechaFinal: Date): Observable<TransporteLiquidacion[]> {

    return this.get<TransporteLiquidacion[]>(`/Logistica/TransportesLiquidacion/${fechaInicial.toJSON().substring(0, 10)}/${fechaFinal.toJSON().substring(0, 10)}`);
  }

  GuardarTransporteLiquidacion(transporteLiquidacion: TransporteLiquidacion): Observable<number> {
    return this.post<number>("/Logistica/TransporteLiquidacion/", transporteLiquidacion);
  }

  BorrarTransporteLiquidacion(idTransporteLiquidacion: number): Observable<void> {
    return this.delete<void>(`/Logistica/TransporteLiquidacion/${idTransporteLiquidacion}`);
  }
  //#endregion

  //#region  TransporteAdicional

  ObtenerTransporteAdicional(fechaInicial: Date, fechaFinal: Date): Observable<TransporteAdicional[]> {
    return this.get<TransporteAdicional[]>(`/Logistica/TransportesAdicionales/${fechaInicial.toJSON().substring(0, 10)}/${fechaFinal.toJSON().substring(0, 10)}`);
  }

  GuardarTransporteAdicional(transporteAdicional: TransporteAdicional): Observable<number> {
    return this.post<number>("/Logistica/TransporteAdicional/", transporteAdicional);
  }

  BorrarTransporteAdicional(idTransporteAdicional: number): Observable<void> {
    return this.delete<void>(`/Logistica/TransporteAdicional/${idTransporteAdicional}`);
  }

  ObtenerCpTransporteAdicional(idTransporteAdicional: number): Observable<string> {
    return this.get<string>(`/Logistica/ObtenerCpTransporteAdicional/${idTransporteAdicional}`);
  }

  //#endregion

  //#region TransporteAeropuerto

  ObtenerTransporte(filtro: Filtro): Observable<Transporte[]> {
    return this.post<Transporte[]>("/Logistica/Transportes", filtro);
  }

  GuardarTransporte(transporte: Transporte): Observable<number> {
    return this.post<number>("/Logistica/Transporte", transporte);
  }


  ObtenerTransporteAeropuerto(filtro: Filtro): Observable<ResultadoTransporteAeropuerto[]> {
    return this.post<ResultadoTransporteAeropuerto[]>("/Logistica/TransporteAeropuerto", filtro);
  }


  ObtenerTransportecp(idtransporte: number): Observable<VItemTransporteCP[]> {

    return this.get<VItemTransporteCP[]>(`/Logistica/Transportescp/${idtransporte}`);
  }

  GuardarTransportecp(filtro: Filtro): Observable<number> {
    return this.post<number>("/Logistica/Transportecp", filtro);
  }

  ObtenerItemsPedidoLogistica(filtro: Filtro): Observable<ResultadoItemsPedidoLogistica[]> {
    return this.post<ResultadoItemsPedidoLogistica[]>("/Logistica/ItemsPedidoLogistica", filtro);
  }

  ObtenerConsecutivoRutaMultiple(): Observable<number> {
    return this.get<number>("/Logistica/ConsecutivoRutaMultiple");
  }

  GuardarTransporteCPMultiple(filtro: Filtro): Observable<number> {
    return this.post<number>("/Logistica/TransportecpMultiple", filtro);
  }

  GuardarItemsPedidoLogistica(filtro: Filtro): Observable<number> {
    return this.post<number>("/Logistica/ItemPedidoLogistica", filtro);
  }

  BorrarTransporte(idtransporte: number): Observable<void> {
    return this.delete<void>(`/Logistica/BorrarTransporte/${idtransporte}`);
  }

  BorrarTransporteCp(idtransportecp: number): Observable<void> {
    return this.delete<void>(`/Logistica/BorrarTransporteCp/${idtransportecp}`);
  }

  //#endregion

  //#region SISPAP

  ObtenerLoginSispap(): Observable<LoginSispap[]> {
    return this.get<LoginSispap[]>("/Logistica/LoginSISPAP/");
  }

  GuardarLoginSispap(login: LoginSispap): Observable<number> {
    return this.post<number>("/Logistica/LoginSISPAP/", login);
  }

  ObtenerCredencialesICA(idempresa: number, idcentroproduccion: number): Observable<LoginSispap[]> {
    return this.get<LoginSispap[]>(`/Logistica/ObtenerCredencialesICA/${idempresa}/${idcentroproduccion}`);
  }

  ObtenerDestinatariosICA(filtro: Filtro): Observable<DestinatarioSispap[]> {
    return this.post<DestinatarioSispap[]>("/Logistica/DestinatariosICA/", filtro);
  }

  ObtenerPaisesICA(filtro: Filtro): Observable<PaisSispap[]> {
    return this.post<PaisSispap[]>("/Logistica/PaisesICA/", filtro);
  }

  ObtenerPuertosEntradaICA(filtro: Filtro): Observable<PuertoEntradaSispap[]> {
    return this.post<PuertoEntradaSispap[]>("/Logistica/PuertosEntradaICA/", filtro);
  }

  ObtenerAduanasICA(filtro: Filtro): Observable<AduanaSispap[]> {
    return this.post<AduanaSispap[]>("/Logistica/AduanasICA/", filtro);
  }

  ObtenerPuertosSalidaICA(filtro: Filtro): Observable<PuertoSalidaSispap[]> {
    return this.post<PuertoSalidaSispap[]>("/Logistica/PuertosSalidaICA/", filtro);
  }

  ObtenerUnidadesICA(filtro: Filtro): Observable<UnidadMedidaSispap[]> {
    return this.post<UnidadMedidaSispap[]>("/Logistica/PuertosSalidaICA/", filtro);
  }

  ObtenerClientesICA(idexportador: number): Observable<DistribuidorSispap[]> {
    return this.get<DistribuidorSispap[]>(`/Logistica/DisttribuidoresICA/${idexportador}`);
  }

  ObtenerItemsSispapGuia(filtro: Filtro): Observable<ResultadoItemsSispapGuia[]> {
    return this.post<ResultadoItemsSispapGuia[]>("/Logistica/ItemsGuiaSISPAP/", filtro);
  }

  ObtenerEspeciesICA(filtro: Filtro): Observable<EspecieSispap[]> {
    return this.post<EspecieSispap[]>("/Logistica/EspeciesICA/", filtro);
  }
  ObtenerEmpaquesICA(filtro: Filtro): Observable<EmpaqueSispap[]> {
    return this.post<EmpaqueSispap[]>("/Logistica/EmpaquesICA/", filtro);
  }
  ObtenerXMLMarcacionICA(parametros: ParametrosXMLSispap): Observable<string> {
    return this.post<string>("/Logistica/XMLMarcacionSISPAP/", parametros);
  }
  ObtenerXMLSubClienteICA(parametros: ParametrosXMLSispap): Observable<string> {
    return this.post<string>("/Logistica/XMLSubClienteSISPAP/", parametros);
  }

  ObtenerXMLGuiaICA(parametros: ParametrosXMLSispap): Observable<string> {
    return this.post<string>("/Logistica/XMLGuiaSISPAP/", parametros);
  }


  ObtenerHistorialSispap(filtro: Filtro): Observable<FitoSispap[]> {
    return this.post<FitoSispap[]>("/Logistica/HistorialSISPAP/", filtro);
  }


  ObtenerFitoSispap(idCentroProduccion: number, constancia: string): Observable<any> {
    return this.get<any>(`/Logistica/ObtenerConstanciaICA/${idCentroProduccion}/${constancia}`);
  }

  ObtenerAerolineasSispap(filtro: Filtro): Observable<AerolineaSispap[]> {
    return this.post<AerolineaSispap[]>("/Logistica/AerolineasICA/", filtro);
  }


  //#endregion


  //#region SISPAP REST

  ObtenerDestinatariosICAREST(idCentroProduccion: number): Observable<DestinatarioSispap[]> {
    return this.get<DestinatarioSispap[]>(`/Logistica/ObtenerDestinatariosICAREST/${idCentroProduccion}`);
  }

  ObtenerPaisesICAREST(idCentroProduccion: number, idDestinatario: number): Observable<PaisSispap[]> {
    return this.get<PaisSispap[]>(`/Logistica/ObtenerPaisesICAREST/${idCentroProduccion}/${idDestinatario}`);
  }

  ObtenerPuertosEntradaICAREST(idCentroProduccion: number, idPais: number): Observable<PuertoEntradaSispap[]> {
    return this.get<PuertoEntradaSispap[]>(`/Logistica/ObtenerPuertoEntradaICAREST/${idCentroProduccion}/${idPais}`);
  }

  ObtenerAduanasICAREST(idCentroProduccion: number): Observable<AduanaSispap[]> {
    return this.get<AduanaSispap[]>(`/Logistica/ObtenerAduanasICAREST/${idCentroProduccion}`);
  }

  ObtenerPuertosSalidaICAREST(idCentroProduccion: number): Observable<PuertoSalidaSispap[]> {
    return this.get<PuertoSalidaSispap[]>(`/Logistica/ObtenerPuertoSalidaICAREST/${idCentroProduccion}`);
  }

  ObtenerUnidadesICAREST(idCentroProduccion: number): Observable<UnidadMedidaSispap[]> {
    return this.get<UnidadMedidaSispap[]>(`/Logistica/ObtenerUnidadesICAREST/${idCentroProduccion}`);
  }

  ObtenerEspeciesICAREST(idCentroProduccion: number): Observable<EspecieSispap[]> {
    return this.get<EspecieSispap[]>(`/Logistica/ObtenerEspeciesICAREST/${idCentroProduccion}`);
  }
  ObtenerEmpaquesICAREST(idCentroProduccion: number): Observable<EmpaqueSispap[]> {
    return this.get<EmpaqueSispap[]>(`/Logistica/ObtenerEmpaquesICAREST/${idCentroProduccion}`);
  }
  ObtenerAerolineasICAREST(idCentroProduccion: number): Observable<AerolineaSispap[]> {
    return this.get<AerolineaSispap[]>(`/Logistica/ObtenerAerolineasICAREST/${idCentroProduccion}`);
  }
  GenerarXMLGuiaICAREST(parametros:ParametrosXMLSispap ): Observable<string> {
    return this.post<string>("/Logistica/GenerarXMLGuiaICAREST/", parametros);
  }
  ObtenerConstanciasICAREST(idCentroProduccion: number, constancia: string): Observable<any> {
    return this.get<any>(`/Logistica/ObtenerConstanciasICAREST/${idCentroProduccion}/${constancia}`);
  } 
  
  ObtenerSispapDynamics(filtro: Filtro): Observable<ResultadoItemsSispapGuia[]> {
    return this.post<ResultadoItemsSispapGuia[]>("/Logistica/ObtenerSispapDynamics/", filtro);
  }
  
  ObtenerClientesICADynamics(idexportador: number): Observable<DistribuidorSispap[]> {
    return this.get<DistribuidorSispap[]>(`/Logistica/ObtenerClientesICADynamics/${idexportador}`);
  }
  //#endregion


  //#region Planillas de Cargue
  ObtenerPlanillasCargue(fecha: Date, idEmpresa: number): Observable<PlanillaCargue[]> {
    return this.get<PlanillaCargue[]>(`/Logistica/PlanillasCargue/${formatDate(fecha, "yyyy-MM-dd", "en")}/${idEmpresa}`);
  }

  ObtenerPrePlanillasCargue(fecha: Date, idEmpresa: number): Observable<PrePlanillaCargue[]> {
    return this.get<PrePlanillaCargue[]>(`/Logistica/CrearPlanillasCargue/${formatDate(fecha, "yyyy-MM-dd", "en")}/${idEmpresa}`);
  }

  GuardarPlanillaCargue(planilla: PlanillaCargue): Observable<number> {
    return this.post<number>("/Logistica/PlanillaCargue/", planilla);
  }

  AsignarPlanillaCargue(planilla: PrePlanillaCargue[], fecha: Date, idEmpresa: number): Observable<number[]> {
    return this.post<number[]>(`/Logistica/RegistrarPlanillasCargue/${fecha.toJSON().substring(0, 10)}/${idEmpresa}`, planilla);
  }

  //#endregion

}
