import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, Observer, timer, Subscription } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class AutenticacionService {
  private estaAutenticado: boolean = false;
  private nombreUsuario: string;
  private tokenAutenticacion: string;
  private expiracion: number;
  private sk: string;
  private key: string;
  private timerRefresco: Subscription;

  constructor(
    private http: HttpClient
  ) { }

  get autenticado(): boolean {
    return this.estaAutenticado;
  }

  get usuario(): string {
    return this.nombreUsuario;
  }

  get token(): string {
    return this.tokenAutenticacion;
  }

  autenticarUsuario(usuario: string, password: string): Observable<void> {
    let url: string = environment.urlServicios + "/token";
    let cuerpo: string = `grant_type=password&username=${usuario}&password=${password}`;
    let encabezado: any = { "Content-Type": "application/x-www-form-urlencoded" };
    let resultadoAutenticacion: Observer<void>;

    this.http.post<any>(url, cuerpo, { headers: encabezado }).subscribe(
      resultado => {
        // Carga los valores
        this.estaAutenticado = true;
        this.nombreUsuario = usuario;
        this.tokenAutenticacion = resultado.access_token;

        // Decodifica el token
        const helper = new JwtHelperService();
        let tokenDecodificado: any = helper.decodeToken(this.tokenAutenticacion);
        this.sk = tokenDecodificado.sk;
        this.expiracion = tokenDecodificado.exp;

        // Almacena el token
        localStorage.setItem("access_token", resultado.access_token);

        // Encripta la contraseña con la clave proporcionada por el servidor
        this.key = CryptoJS.AES.encrypt(password, this.sk);
        localStorage.setItem("access_key", this.key);

        // Configura el timer de autorefresco
        this.configurarTimerAutorefresco();

        // Notifica a los observadores
        resultadoAutenticacion.next(null);
        resultadoAutenticacion.complete();
      },
      error => resultadoAutenticacion.error("Usuario o contraseña inválidos")
    );

    return new Observable(o => {
      resultadoAutenticacion = o;

      return {
        unsubscribe() { }
      };
    })
  }

  inicializar() {
    this.tokenAutenticacion = localStorage.getItem("access_token");
    this.key = localStorage.getItem("access_key");

    if (this.tokenAutenticacion && this.key) {
      const helper = new JwtHelperService();

      if (!helper.isTokenExpired(this.tokenAutenticacion)) {
        this.estaAutenticado = true;
        let tokenDecodificado: any = helper.decodeToken(this.tokenAutenticacion);        
        this.nombreUsuario = tokenDecodificado.sub;
        this.sk = tokenDecodificado.sk;
        this.expiracion = tokenDecodificado.exp;

        // Configura el timer de autorefresco
        this.configurarTimerAutorefresco();
      }
    }
  }

  borrarSesion() {
    if (this.estaAutenticado) {
      if (this.timerRefresco) {
        this.timerRefresco.unsubscribe();
        this.timerRefresco = null;
      }

      localStorage.removeItem("access_token");
      localStorage.removeItem("access_key");
      this.estaAutenticado = false;
      this.nombreUsuario = null;
      this.tokenAutenticacion = null;
      this.expiracion = null;
      this.sk = null;
      this.key = null;
    }
  }

  private configurarTimerAutorefresco(tiempoEspera?: number) {
    let clave: string = CryptoJS.AES.decrypt(this.key, this.sk).toString(CryptoJS.enc.Utf8);
    let tiempoExpiracion: number = (this.expiracion * 1000) - new Date().getTime();

    if ((tiempoEspera == undefined && tiempoExpiracion > 300000) || (tiempoEspera != undefined && tiempoExpiracion > tiempoEspera)) {
      if (this.timerRefresco)
        this.timerRefresco.unsubscribe();

      this.timerRefresco = timer(tiempoEspera != undefined ? tiempoEspera : tiempoExpiracion - 300000).subscribe(
        x => {
          this.autenticarUsuario(this.nombreUsuario, clave).subscribe(
            () => { },
            error => this.configurarTimerAutorefresco(60000) // Programa el timer para que se dispare en un minuto
          );
        }
      );
    }
    else {
      this.borrarSesion();
      location.reload();
    }
  }
}