import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'servicios/http.service';
import { Pedido } from 'modelos/mercadeo/Pedido';
import { Filtro } from 'modelos/Filtro';
import { ItemPedido } from 'modelos/mercadeo/ItemPedido';
import { Marca } from 'modelos/Admin/Marca';
import { Marcacion } from 'modelos/CRM/Marcacion';
import { Temporada } from 'modelos/Admin/Temporada';
import { DistribuidorCliente } from 'modelos/CRM/DistribuidorCliente';
import { RecetaItemPedido } from 'modelos/mercadeo/RecetaItemPedido';
import { EncabezadoPieza } from 'modelos/mercadeo/EncabezadoPieza';
import { UnidadFacturacion } from 'modelos/UnidadFacturacion';
import { ItemConceptoAnulacion } from 'modelos/mercadeo/ItemConceptoAnulacion';
import { ItemConceptoCancelacion } from 'modelos/mercadeo/ItemConceptoCancelacion';
import { DesgloseRecetaItem } from 'modelos/mercadeo/DesgloseRecetaItem';
import { EstadoFlujoTrabajo } from 'modelos/Admin/EstadoFlujoTrabajo';
import { ManejoItemsIncompletos } from 'modelos/mercadeo/ManejoItemsIncompletos';
import { ReglasValidacion } from 'modelos/ReglasValidacion';
import { OrdenProgramada } from 'modelos/mercadeo/OrdenProgramada';
import { GuiaHija } from 'modelos/mercadeo/GuiaHija';
import { ManejoGuias } from 'modelos/mercadeo/ManejoGuias';
import { EncabezadoGuia } from 'modelos/mercadeo/EncabezadoGuia';
import { PedidoGuia } from 'modelos/mercadeo/PedidoGuia';
import { VItemPedidoGuia } from 'modelos/mercadeo/VItemPedidoGuia';
import { VCliente } from 'modelos/CRM/VCliente';
import { EmpresaTransporte } from 'modelos/CRM/EmpresaTransporte';
import { RevisionPrecioPedido } from 'modelos/mercadeo/RevisionPrecioPedido';
import { CorreoRevisionPrecio } from 'modelos/mercadeo/CorreoRevisionPrecios';
import { Usuario } from 'modelos/Usuario';
import { ItemPedidoRevisionPrecios } from 'modelos/mercadeo/RevisionPrecioItem';
import { Especie } from 'modelos/Especie';
import { ManejoGuiaGuiaHija } from 'modelos/mercadeo/ManejoGuiaGuiaHija';
import { ValidacionEspeciesDocExp } from 'modelos/Facturacion/ValidacionEspeciesDocExp';
import { ItemPedidoMasivo } from 'modelos/mercadeo/ItemPedidoMasivo';
import { ConsultaGuia } from 'modelos/mercadeo/ConsultaGuia';
import { formatDate } from '@angular/common';
import { ConsultaPlanillaCargue } from 'modelos/mercadeo/ConsultaPlanillaCargue';
import { ConsultaGuiaHija } from 'modelos/mercadeo/ConsultaGuiaHija';
import { EmpresaTransporteSimplificado } from 'modelos/EmpresaTransporteSimplificado';
import { EncabezadoGuiaSimplificado } from 'modelos/EncabezadoGuiaSimplificado';
import { VClienteSimplificado } from 'modelos/CRM/VClienteSimplificado';
import { UsuarioSimplificado } from 'modelos/UsuarioSimplificado';
import { MarcaSimplificado } from 'modelos/Admin/MarcaSimplificado';
import { TemporadaSimplificado } from 'modelos/Admin/TemporadaSimplificado';
import { PedidoSimplificado } from 'modelos/mercadeo/PedidoSimplificado';
import { RevisionPrecioPedidoSimplificado } from 'modelos/mercadeo/RevisionPrecioPedidoSimplificado';
import { EspecieSimplificado } from 'modelos/EspecieSimplificado';
import { MarcacionSimplificado } from 'modelos/CRM/MarcacionSimplificado';
import { DistribuidorClienteSimplificado } from 'modelos/CRM/DistribuidorClienteSimplificado';

@Injectable()
export class MercadeoService extends HttpService {

  //#region Pedidos

  ObtenerPedidos(filtro: Filtro): Observable<Pedido[]> {
    return this.post<Pedido[]>("/Pedido/Pedidos", filtro);

  }

  ObtenerPedido(idpedido: number): Observable<Pedido> {
    return this.get<Pedido>(`/Pedido/Pedido/${idpedido}`);
  }

  ObtenerPedidoSimplificado(idpedido: number): Observable<PedidoSimplificado> {
    return this.get<PedidoSimplificado>(`/Pedido/PedidoSimplificado/${idpedido}`);
  }

  ObtenerItemsPedido(idpedido: number): Observable<ItemPedido[]> {
    return this.get<ItemPedido[]>(`/Pedido/ItemsPedido/${idpedido}`);
  }

  ObtenerItemsPedidoSinReceta(idPedido: number): Observable<ItemPedido[]> {
    return this.get<ItemPedido[]>(`/Pedido/ItemsPedido2/${idPedido}`);
  }

  ObtenerRecetasItem(idItemPedido: number): Observable<RecetaItemPedido[]> {
    return this.get<RecetaItemPedido[]>(`/Pedido/RecetaItem/${idItemPedido}`);
  }

  ObtenerItemsPedidoPorId(iditempedido: number): Observable<ItemPedido> {
    return this.get<ItemPedido>(`/Pedido/ItemPedido/${iditempedido}`);
  }

  ObtenerItemPedido(iditempedido: number): Observable<ItemPedido> {
    return this.get<ItemPedido>(`/Pedido/ItemPedido/${iditempedido}`);

  }

  GuardarPedido(pedido: Pedido): Observable<Pedido> {
    return this.post<Pedido>("/Pedido/GuardarPedido", pedido);
  }

  ObtenerMarcas(): Observable<Marca[]> {
    return this.get<Marca[]>("/Pedido/Marcas");
  }

  ObtenerMarcasSimplificado(): Observable<MarcaSimplificado[]> {
    return this.get<MarcaSimplificado[]>("/Pedido/Marcassimplificado");
  }

  ObtenerMarcacionesCliente(idcliente: number): Observable<Marcacion[]> {
    return this.get<Marcacion[]>(`/Pedido/Marcaciones/${idcliente}`);
  }

  ObtenerMarcacionesClienteSimplificado(idcliente: number): Observable<MarcacionSimplificado[]> {
    return this.get<MarcacionSimplificado[]>(`/Pedido/MarcacionesSimplificado/${idcliente}`);
  }

  ObtenerTemporadas(): Observable<Temporada[]> {
    return this.get<Temporada[]>("/Pedido/Temporadas");
  }

  ObtenerTemporadasSimplificado(): Observable<TemporadaSimplificado[]> {
    return this.get<TemporadaSimplificado[]>("/Pedido/TemporadasSimplificado");
  }

  ObtenerDistribuidoresCliente(idcliente: number): Observable<DistribuidorCliente[]> {
    return this.get<DistribuidorCliente[]>(`/Pedido/ObtenerDistribuidoresCliente/${idcliente}`);
  }

  ObtenerDistribuidoresClienteSimplificado(idcliente: number): Observable<DistribuidorClienteSimplificado[]> {
    return this.get<DistribuidorClienteSimplificado[]>(`/Pedido/ObtenerDistribuidoresClienteSimplificado/${idcliente}`);
  }

  ObtenerEncabezadoPieza(idRefpieza: number): Observable<EncabezadoPieza> {
    return this.get<EncabezadoPieza>(`/Pedido/ObtenerEncabezadoPiezaIdRef/${idRefpieza}`);
  }


  ObtenerRecetasPieza(idRefpieza: number): Observable<RecetaItemPedido[]> {
    return this.get<RecetaItemPedido[]>(`/Pedido/ObtenerRecetasIdRefPieza/${idRefpieza}`);
  }

  ObtenerRecetasPorIdRef(idreferencia: number): Observable<RecetaItemPedido[]> {
    return this.get<RecetaItemPedido[]>(`/Pedido/ObtenerRecetasIdRef/${idreferencia}`);
  }

  ObtenerDesgloceReceta(idrefreceta: number): Observable<DesgloseRecetaItem[]> {
    return this.get<DesgloseRecetaItem[]>(`/Pedido/ObtenerDesglocesIdRefReceta/${idrefreceta}`);
  }

  ObtenerDesglocePorIdRef(idreferencia: number): Observable<DesgloseRecetaItem[]> {
    return this.get<DesgloseRecetaItem[]>(`/Pedido/ObtenerDesglocesIdRef/${idreferencia}`);
  }

  ObtenerDesglocePieza(idRefpieza: number): Observable<DesgloseRecetaItem[]> {
    return this.get<DesgloseRecetaItem[]>(`/Pedido/ObtenerDesglocesIdRefPieza/${idRefpieza}`);
  }

  ObtenerUnidadesFacturacion(): Observable<UnidadFacturacion[]> {
    return this.get<UnidadFacturacion[]>(`/UnidadesFacturacion`);
  }

  GuardarItemPedido(itempedido: ItemPedido): Observable<number> {
    return this.post<number>("/Pedido/GuardarItemPedido", itempedido);
  }

  GuardarItemsPedido(itemspedido: ItemPedido[]): Observable<number[]> {
    return this.post<number[]>("/Pedido/GuardarItemsPedido", itemspedido);
  }

  CancelarItemsPedido(filtro: Filtro[]): Observable<boolean> {
    return this.post<boolean>("/Pedido/CancelarItemsPedido", filtro);
  }


  AnularItemsPedido(filtro: Filtro[]): Observable<boolean> {
    return this.post<boolean>("/Pedido/AnularItemPedido", filtro);
  }

  AnularItemsPedidoMasivo(filtro: Filtro): Observable<void> {
    return this.post<void>(`/Pedido/AnularItemMasivo`, filtro);
  }

  RechazarItemsPedido(filtro: Filtro): Observable<boolean> {
    return this.post<boolean>("/Pedido/RechazarItemPedido", filtro);
  }

  ObtenerConceptosCancelacionItemPedido(): Observable<ItemConceptoCancelacion[]> {
    return this.get<ItemConceptoCancelacion[]>("/Pedido/ObtenerConceptosCancelacionItemPedido");
  }


  ObtenerConceptosAnulacionItemPedido(): Observable<ItemConceptoAnulacion[]> {
    return this.get<ItemConceptoAnulacion[]>("/Pedido/ObtenerConceptosAnulacionItemPedido");
  }


  CopiarItemPedido(filtro: Filtro): Observable<number> {
    return this.post<number>("/Pedido/CopiarItemPedido", filtro);
  }

  CopiarItemPedidoV2(filtro: Filtro[]): Observable<string> {
    return this.post<string>("/Pedido/CopiarItemPedidoV2", filtro);
  }

  ObtenerConsecutivoItemPedido(consecpedido: number): Observable<number> {
    return this.get<number>(`/Pedido/ObtenerConsecutivoItemPedido/${consecpedido}`);
  }

  CopiarPedido(filtro: Filtro): Observable<boolean> {
    return this.post<boolean>("/Pedido/CopiarPedido", filtro);
  }

  BorrarItemsPedido(Filtro: Filtro): Observable<void> {
    return this.post<void>(`/Pedido/BorrarItemPedido`, Filtro);
  }
  BorrarItemsPedidoMasivo(items: number[], idUsuario: number): Observable<void> {
    return this.post<void>(`/Pedido/BorrarItemMasivo/${idUsuario}`, items);
  }

  ObtenerEstadosFlujoItemPedido(): Observable<EstadoFlujoTrabajo[]> {
    return this.get<EstadoFlujoTrabajo[]>("/Pedido/ObtenerEstadosFlujoItemPedido");
  }

  CortarItemPedido(filtro: Filtro): Observable<boolean> {
    return this.post<boolean>("/Pedido/CortarItemPedido", filtro);
  }

  ManejoItemsPedidoIncompletos(itemsincompletos: ManejoItemsIncompletos): Observable<boolean> {
    return this.post<boolean>("/Pedido/ManejoItemsPedidoIncompletos", itemsincompletos);
  }

  ValidacionItemsRutaLogistica(idpedido: number): Observable<Number> {
    return this.get<number>(`/Pedido/ValidacionItemsRutaLogistica/${idpedido}`);
  }

  EdicionPedidoItemPedido(pedido: Pedido): Observable<boolean> {
    return this.post<boolean>("/Pedido/EdicionPedidoItemPedido", pedido);
  }

  ValidarEmpaqueRecetaDesgloce(reglas: ReglasValidacion): Observable<boolean> {
    return this.post<boolean>("/Pedido/ReglasValidaciones", reglas);
  }

  ProgramarPedido(OrdenProgramada: OrdenProgramada): Observable<boolean> {
    return this.post<boolean>("/Pedido/ProgramarPedido", OrdenProgramada);
  }

  EliminarProgramacionPedido(OrdenProgramada: OrdenProgramada): Observable<boolean> {
    return this.post<boolean>("/Pedido/EliminarProgramacionPedido", OrdenProgramada);
  }


  AsignarCPItemsMasivo(ItemPedido: ItemPedido): Observable<number> {
    return this.post<number>("/Pedido/AsignarCPItemsMasivo", ItemPedido);
  }


  AsignarDistribuidorItemsMasivo(ItemPedido: ItemPedido): Observable<number> {
    return this.post<number>("/Pedido/AsignarDistribuidorItemsMasivo", ItemPedido);
  }

  GuardarItemsCamposMasivo(ItemPedido: ItemPedido): Observable<number> {
    return this.post<number>("/Pedido/GuardarItemsCamposMasivo", ItemPedido);
  }

  ObtenerEspeciesPedido(idPedido: number): Observable<Especie[]> {
    return this.get<Especie[]>(`/Pedido/Especie/${idPedido}`);
  }

  ObtenerEspeciesPedidoSimplificado(idPedido: number): Observable<EspecieSimplificado[]> {
    return this.get<EspecieSimplificado[]>(`/Pedido/EspecieSimplificado/${idPedido}`);
  }

  AsignarDistribuidorItemsMasivoV2(ItemPedido: ItemPedidoMasivo[]): Observable<string> {
    return this.post<string>("/Pedido/AsignarDistribuidorItemsMasivoV2", ItemPedido);
  }

  GuardarItemsCamposMasivoV2(ItemPedido: ItemPedidoMasivo[]): Observable<string> {
    return this.post<string>("/Pedido/GuardarItemsCamposMasivoV2", ItemPedido);
  }

  GuardarItemsMultiple(ItemPedido: ItemPedidoMasivo[]): Observable<string[]> {
    return this.post<string[]>("/Pedido/GuardarItemsMultiple", ItemPedido);
  }

  AsignarCPItemsMasivoV2(ItemPedido: ItemPedidoMasivo[]): Observable<string> {
    return this.post<string>("/Pedido/AsignarCPItemsMasivoV2", ItemPedido);
  }


  //#endregion

  //#region Guias

  ObtenerGuiasFecha(fechaInicial: Date, fechaFinal: Date, guiaMaster: string): Observable<ConsultaGuia[]> {
    if (guiaMaster && guiaMaster != "")
      return this.get<ConsultaGuia[]>(`/Facturacion/ConsultarGuias/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}/${guiaMaster}`);
    else
      return this.get<ConsultaGuia[]>(`/Facturacion/ConsultarGuias/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}`);
  }

  ObtenerPlanillaMaster(idGuiaMaster: number): Observable<ConsultaPlanillaCargue[]> {
    return this.get<ConsultaPlanillaCargue[]>(`/Facturacion/ConsultarPlanillasGuia/${idGuiaMaster}`);
  }

  ObtenerGuiasHijaMaster(idGuiaMaster: number): Observable<ConsultaGuiaHija[]> {
    return this.get<ConsultaGuiaHija[]>(`/Facturacion/ConsultarGuiasHijaMaster/${idGuiaMaster}`);
  }

  ObtenerEncabezadosGuias(filtro: Filtro): Observable<EncabezadoGuia[]> {
    return this.post<EncabezadoGuia[]>("/Pedido/EncabezadoGuia", filtro);
  }

  ObtenerEncabezadosGuiasSimplificado(filtro: Filtro): Observable<EncabezadoGuiaSimplificado[]> {
    return this.post<EncabezadoGuiaSimplificado[]>("/Pedido/EncabezadoGuiaSimplificado", filtro);
  }

  ObtenerPedidosGuia(filtro: Filtro): Observable<PedidoGuia[]> {
    return this.post<PedidoGuia[]>("/Pedido/ObtenerPedidosGuia", filtro);

  }

  ObtenerPedidosGuiaAsignados(filtro: Filtro): Observable<PedidoGuia[]> {
    return this.post<PedidoGuia[]>("/Pedido/ObtenerPedidosGuiaAsignados", filtro);

  }

  ObtenerItemsPedidoGuia(filtro: Filtro): Observable<VItemPedidoGuia[]> {

    return this.post<VItemPedidoGuia[]>("/Pedido/ObtenerItemsPedidoGuia", filtro);

  }

  GuardarGuia(guia: ManejoGuiaGuiaHija): Observable<number> {

    return this.post<number>("/Pedido/GuardarGuia", guia);

  }

  GuardarGuiaHija(guiahija: GuiaHija): Observable<number> {

    return this.post<number>("/Pedido/GuardarGuiaHija", guiahija);

  }


  AsignarGuiasItemPedido(manjeguias: ManejoGuias): Observable<number[]> {

    return this.post<number[]>("/Pedido/AsignarGuiasItemPedido", manjeguias);

  }

  DesasignarGuiasItemPedido(manjeguias: ManejoGuias): Observable<number[]> {

    return this.post<number[]>("/Pedido/DesasignarGuiasItemPedido", manjeguias);

  }

  EliminarGuia(manejoguias: ManejoGuias): Observable<void> {

    return this.post<void>("/Pedido/EliminarGuia", manejoguias);

  }

  EliminarGuias(manejoguias: ManejoGuias[]): Observable<string> {
    return this.post<string>("/Pedido/EliminarGuias", manejoguias);
  }

  ObtenerClientesFechaDespacho(fechadespacho: Date): Observable<VCliente[]> {
    return this.get<VCliente[]>(`/Pedido/ClientesFechaDespacho/${fechadespacho}`);
  }

  ObtenerClientesFechaDespachoSimplificado(fechadespacho: Date): Observable<VClienteSimplificado[]> {
    return this.get<VClienteSimplificado[]>(`/Pedido/ClientesFechaDespachoSimplificado/${fechadespacho}`);
  }

  EmpresasTransporteTipoTransporte(tipotransporte: number): Observable<EmpresaTransporte[]> {

    return this.get<EmpresaTransporte[]>(`/Pedido/EmpresasTransporteTipoTransporte/${tipotransporte}`);
  }

  EmpresasTransporteTipoTransporteSimplificado(tipotransporte: number): Observable<EmpresaTransporteSimplificado[]> {

    return this.get<EmpresaTransporteSimplificado[]>(`/Pedido/EmpresasTransporteTipoTransporteSimplificado/${tipotransporte}`);
  }

  InactivarGuia(filtro: ManejoGuias[]): Observable<boolean> {
    return this.post<boolean>("/Pedido/InactivarGuia", filtro);
  }
  //#endregion

  //#region revisionPecios
  ObtenerRevisionPrecioItem(idItem: number): Observable<ItemPedidoRevisionPrecios> {
    return this.get<ItemPedidoRevisionPrecios>(`/Pedido/RevisionItemPedido/${idItem}`);
  }

  ObtenerRevisionesPrecioItem(idItem: number): Observable<ItemPedidoRevisionPrecios[]> {
    return this.get<ItemPedidoRevisionPrecios[]>(`/Pedido/RevisionesItemPedido/${idItem}`);
  }

  GuardarRevisionesPrecioItem(revisionPrecioItem: ItemPedidoRevisionPrecios[]): Observable<number[]> {
    return this.post<number[]>("/Pedido/GuardarRevisionesItemPedido", revisionPrecioItem);
  }

  GuardarRevisionPrecioItem(revisionPrecioItem: ItemPedidoRevisionPrecios): Observable<number> {
    return this.post<number>("/Pedido/GuardarRevisionItemPedido", revisionPrecioItem);
  }

  ObtenerRevisionPrecioPedido(idItem: number): Observable<RevisionPrecioPedido> {
    return this.get<RevisionPrecioPedido>(`/Pedido/RevisionPedido/${idItem}`);
  }

  ObtenerRevisionPrecioPedidoSimplificado(idItem: number): Observable<RevisionPrecioPedidoSimplificado> {
    return this.get<RevisionPrecioPedidoSimplificado>(`/Pedido/RevisionPedidoSimplificado/${idItem}`);
  }

  GuardarRevisionPrecioPedido(revisionPrecioPedido: RevisionPrecioPedido): Observable<number> {
    return this.post<number>("/Pedido/GuardarRevisionPedido", revisionPrecioPedido);
  }

  EnviarCorreoRevisionPrecio(correoRevisionPrecio: CorreoRevisionPrecio): Observable<string> {
    return this.post<string>("/Pedido/correoRevisionPrecio", correoRevisionPrecio);
  }

  ObtenerUsuariosMercadeo(): Observable<Usuario[]> {
    return this.get<Usuario[]>("/Pedido/ObtenerUsuariosMercadeo");
  }

  ObtenerUsuariosMercadeoSimplificado(): Observable<UsuarioSimplificado[]> {
    return this.get<UsuarioSimplificado[]>("/Pedido/ObtenerUsuariosMercadeoSimplificado");
  }

  ObtenerUltimaFechaOrdenFija(idPedido: number): Observable<string> {
    return this.get<string>(`/Pedido/UltimaFechaOrdenFija/${idPedido}`);
  }

  //#endregion

  //#region enviocorreos

  EnviarCorreoCambioEstado(filtro: Filtro): Observable<boolean> {
    return this.post<boolean>("/Pedido/EnviarCorreoCambioEstado", filtro);
  }

  EnviarCorreoCambiosMasivos(filtro: Filtro): Observable<boolean> {
    return this.post<boolean>("/Pedido/EnviarCorreoCambiosMasivos", filtro);
  }

  //#endregion

  ObtenerPrecioNacionalPorPedido(idPieza: number, idPedido: number, idTipoEmpaque: number): Observable<number> {
    return this.get<number>(`/Pedido/PrecioPieza/${idPieza}/${idPedido}/${idTipoEmpaque}`);
  }

  ActualizarPrecioNacionalPorPedido(idPedido: number) {
    return this.get(`/Pedido/ValoresNacionales/${idPedido}`);
  }

  BorrarDistribuidorCliente(idDistribuidor: number): Observable<void> {
    return this.delete<void>(`/Pedido/BorrarDistribuidorCliente/${idDistribuidor}`);
  }


  ValidarDesgloceFacturaNotaDebito(ValidacionEspeciesDocExp: ValidacionEspeciesDocExp): Observable<boolean> {
    return this.post<boolean>("/Pedido/ValidarDesgloceFacturaNotaDebito", ValidacionEspeciesDocExp);
  }
}
