import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'servicios/http.service';
import { Rol } from 'modelos/Rol';
import { Usuario } from 'modelos/Usuario';
import { UsuarioDA } from 'modelos/UsuarioDA';
import { Empresa } from 'modelos/Empresa';
import { CentroProduccion } from 'modelos/CentroProduccion';
import { Cargo } from 'modelos/Cargo';
import { ItemMenu } from 'modelos/ItemMenu';
import { TasaCambio } from 'modelos/TasaCambio';
import { Dependencia } from 'modelos/Dependencia';
import { Tabla } from 'modelos/Tabla';
import { CampoTabla } from 'modelos/CampoTabla';
import { Pais } from 'modelos/Pais';
import { Ciudad } from 'modelos/ciudad';
import { Estado } from 'modelos/Estado';
import { TipoDocumentoIdentificacion } from 'modelos/TipoDocumentoIdentificacion';
import { VEmpresa } from 'modelos/VEmpresa';
import { Moneda } from 'modelos/Moneda';
import { CargoEconomico } from 'modelos/CargoEconomico';
import { OperacionParametrica } from 'modelos/OperacionParametrica';
import { FirmaPlanilla } from 'modelos/Admin/FirmaPlanilla';
import { AgenciaSIA } from 'modelos/Admin/AgenciaSIA';
import { AgenciaCarga } from 'modelos/Admin/AgenciaCarga';
import { EntidadFinanciera } from 'modelos/Admin/EntidadFinanciera';
import { TipoTransporte } from 'modelos/Admin/TipoTransporte';
import { ContactoEntidadFinanciera } from 'modelos/Admin/ContactoEntidadFinanciera';
import { CuentasEntidadFinanciera } from 'modelos/Admin/CuentasEntidadFinanciera';
import { TipoCuenta } from 'modelos/Admin/TipoCuenta';
import { Semana } from 'modelos/Admin/Semana';
import { DirigidoA } from 'modelos/Admin/DirigidoA';
import { CentroProduccionSimplificado } from 'modelos/CentroProduccionSimplificado';
import { AgenciaSIASimplificado } from 'modelos/AgenciaSIASimplificado';
import { AgenciasCargaSimplificado } from 'modelos/AgenciasCargaSimplificado';
import { TipoTransporteSimplificado } from 'modelos/TipoTransporteSimplificado';
import { EmpresaSimplificado } from 'modelos/EmpresaSimplificado';

@Injectable()
export class AdministracionService extends HttpService {

  ObtenerRoles(obtenerItemsMenu: boolean): Observable<Rol[]> {
    return this.get<Rol[]>(`/Roles/${obtenerItemsMenu}`);
  }

  GuardarRol(rol: Rol): Observable<number> {
    return this.post<number>("/Rol", rol);
  }

  ObtenerUsuarios(): Observable<Usuario[]> {
    return this.get<Usuario[]>("/Usuarios");
  }

  ObtenerUsuariosDA(token: string): Observable<UsuarioDA[]> {
    return this.post<UsuarioDA[]>("/UsuariosDA", '"' + token + '"');
  }

  ObtenerUsuario(idUsuario: number): Observable<Usuario> {
    return this.get<Usuario>(`/Usuario/${idUsuario}`);
  }

  ObtenerIdUsuario(nombreUsuario: string): Observable<number> {
    return this.get<number>(`/IdUsuario/${nombreUsuario}/`);
  }

  GuardarUsuario(usuario: Usuario): Observable<number> {
    return this.post<number>("/Usuario", usuario);
  }

  ObtenerEmpresas(excluirEmpresasDynamics?: boolean): Observable<Empresa[]> {
    if (excluirEmpresasDynamics != undefined)
      return this.get<Empresa[]>(`/Empresas/${excluirEmpresasDynamics}`);
    else
      return this.get<Empresa[]>("/Empresas");
  }

  ObtenerEmpresasSimplificado(excluirEmpresasDynamics?: boolean): Observable<EmpresaSimplificado[]> {
    if (excluirEmpresasDynamics != undefined)
      return this.get<EmpresaSimplificado[]>(`/EmpresasSimplificado/${excluirEmpresasDynamics}`);
    else
      return this.get<EmpresaSimplificado[]>("/EmpresasSimplificado");
  }

  GenerarExcelEmpresas(): Observable<string> {
    return this.get<string>("/Admin/ExcelEmpresas");
  }

  ObtenerEmpresaID(idempresa: number):Observable<Empresa>{
    return this.get<Empresa>(`/EmpresaId/${idempresa}`);
  }

  ObtenerCentrosProduccion(excluirEmpresasDynamics?: boolean): Observable<CentroProduccion[]> {
    if (excluirEmpresasDynamics != undefined)
      return this.get<CentroProduccion[]>(`/CentrosProduccion/${excluirEmpresasDynamics}`);
    else
      return this.get<CentroProduccion[]>("/CentrosProduccion");
  }

  ObtenerCentrosProduccionSimplificado(excluirEmpresasDynamics?: boolean): Observable<CentroProduccionSimplificado[]> {
    if (excluirEmpresasDynamics != undefined)
      return this.get<CentroProduccionSimplificado[]>(`/CentrosProduccionSimplificado/${excluirEmpresasDynamics}`);
    else
      return this.get<CentroProduccionSimplificado[]>("/CentrosProduccionSimplificado");
  }

  ObtenerCargos(): Observable<Cargo[]> {
    return this.get<Cargo[]>("/Cargos");
  }

  ObtenerItemsMenu(): Observable<ItemMenu[]> {
    return this.get<ItemMenu[]>("/ItemsMenu");
  }

  ObtenerItemsMenuUsuario(idUsuario: number): Observable<ItemMenu[]> {
    return this.get<ItemMenu[]>(`/ItemsMenuUsuario/${idUsuario}`);
  }

  ObtenerMenuUsuario(idUsuario: number): Observable<ItemMenu[]> {
    return this.get<ItemMenu[]>(`/MenuUsuario/${idUsuario}`);
  }

  GuardarItemMenu(itemMenu: ItemMenu): Observable<number> {
    return this.post<number>("/ItemMenu", itemMenu);
  }

  ObtenerTrm(fecha: string): Observable<TasaCambio> {
    return this.get<TasaCambio>(`/TasaCambio/${fecha}/2/1`);
  }

  ObtenerDependencias(): Observable<Dependencia[]> {
    return this.get<Dependencia[]>("/Dependencias");
  }

  ObtenerTablasCatalogo(): Observable<Tabla[]> {
    return this.get<Tabla[]>("/Tablas");
  }

  ObtenerCamposTabla(idTabla: number): Observable<CampoTabla[]> {
    return this.get<CampoTabla[]>(`/CamposTabla/${idTabla}`);
  }

  ObtenerRegistrosTabla(idTabla: number): Observable<any[]> {
    return this.get<any[]>(`/RegistrosTabla/${idTabla}`);
  }

  ObtenerRegistrosTablaForanea(nombreCampo: string): Observable<any[]> {
    return this.get<any[]>(`/RegistrosTablaForanea/${nombreCampo}`);
  }

  ObtenerRegistroTabla(idTabla: number, columna_pk: string, valor_pk: string): Observable<any> {
    return this.get<any>(`/RegistroTabla/${idTabla}/${columna_pk}/${valor_pk}`);
  }

  TransaccionRegistrosTabla(operacionParametrica: OperacionParametrica): Observable<void> {
    return this.post<void>("/TransaccionRegistrosTabla", operacionParametrica);
  }

  ObtenerPaises(): Observable<Pais[]> {
    return this.get<Pais[]>("/Paises");
  }

  ObtenerCiudades(): Observable<Ciudad[]> {
    return this.get<Ciudad[]>("/Ciudades");
  }

  ObtenerCiudadesPais(idpais: number): Observable<Ciudad[]> {
    return this.get<Ciudad[]>(`/CiudadesPais/${idpais}`);
  }

  ObtenerCiudadesEstado(idestado: number): Observable<Ciudad[]> {
    return this.get<Ciudad[]>(`/CiudadesEstado/${idestado}`);
  }

  ObtenerEstados(idpais: number): Observable<Estado[]> {
    return this.get<Estado[]>(`/Estados/${idpais}`);
  }

  ObtenerTiposDocumentoIdentificacion(): Observable<TipoDocumentoIdentificacion[]> {
    return this.get<TipoDocumentoIdentificacion[]>("/TiposDocumentoIdentificacion");
  }

  ObtenerVEmpresas(excluirEmpresasDynamics?: boolean): Observable<VEmpresa[]> {
    if (excluirEmpresasDynamics != undefined)
      return this.get<VEmpresa[]>(`/VEmpresas/${excluirEmpresasDynamics}`);
    else
      return this.get<VEmpresa[]>("/VEmpresas");
  }

  GuardarEmpresa(empresa: Empresa): Observable<number> {
    return this.post<number>("/Empresa", empresa);
  }

  ObtenerMonedas(): Observable<Moneda[]> {
    return this.get<Moneda[]>("/Monedas");
  }

  ObtenerCargosEconomicos(): Observable<CargoEconomico[]> {
    return this.get<CargoEconomico[]>("/CargosEconomicos");
  }

  //#region Firmas
  ObtenerFirmaPlanilla(): Observable<FirmaPlanilla[]> {
    return this.get<FirmaPlanilla[]>("/Admin/FirmasPlanillas");
  }
  //#endregion

  //#region AgenciaSIA
  ObtenerAgenciaSIA(): Observable<AgenciaSIA[]> {
    return this.get<AgenciaSIA[]>("/Admin/AgenciaSIA");
  }

  ObtenerAgenciaSIASimplificado(): Observable<AgenciaSIASimplificado[]> {
    return this.get<AgenciaSIASimplificado[]>("/Admin/AgenciaSIASimplificado");
  }

  GenerarExcelAgenciasSIA(): Observable<string> {
    return this.get<string>("/Admin/ExcelAgenciasSIA");
  }

  GuardarAgenciaSIA(agenciaSIA: AgenciaSIA): Observable<number> {
    return this.post<number>("/Admin/AgenciaSIA", agenciaSIA);
  }
  //#endregion

  //#region AgenciaCarga
  ObtenerAgenciaCarga(): Observable<AgenciaCarga[]> {
    return this.get<AgenciaCarga[]>("/Admin/AgenciasCarga");
  }

  ObtenerAgenciasCargaSimplificado(): Observable<AgenciasCargaSimplificado[]> {
    return this.get<AgenciasCargaSimplificado[]>("/Admin/AgenciasCargaSimplificado");
  }

  GenerarExcelAgenciasCarga(): Observable<string> {
    return this.get<string>("/Admin/ExcelAgenciasCarga");
  }

  GuardarAgenciaCarga(agenciaCarga: AgenciaCarga): Observable<number> {
    return this.post<number>("/Admin/AgenciasCarga", agenciaCarga);
  }
  //#endregion

  //#region EntidadesFinancieras
  ObtenerEntidadesFinancieras(): Observable<EntidadFinanciera[]> {
    return this.get<EntidadFinanciera[]>("/Admin/EntidadesFinancieras");
  }

  GenerarExcelEntidadesFinancieras(): Observable<string> {
    return this.get<string>("/Admin/ExcelEntidadesFinancieras");
  }

  GuardarEntidadFinanciera(entidadFinanciera: EntidadFinanciera): Observable<number> {
    return this.post<number>("/Admin/EntidadFinanciera", entidadFinanciera);
  }
  //#endregion

  //#region ContactosEntidadFinancier
  ObtenerContactosEntidadesFinancieras(idEntidadFinanciera: number): Observable<ContactoEntidadFinanciera[]> {
    return this.get<ContactoEntidadFinanciera[]>(`/Admin/ContactosEntidadFinanciera/${idEntidadFinanciera}`);
  }

  GuardarContactoEntidadFinanciera(contacto: ContactoEntidadFinanciera): Observable<number> {
    return this.post<number>("/Admin/ContactoEntidadFinanciera", contacto);
  }


  BorrarContactoEntidadFinanciera(idContactoEntidadFinanciera: number): Observable<void> {
    return this.delete<void>(`/Admin/ContactoEntidadFinanciera/${idContactoEntidadFinanciera}`);
  }

 
  //#endregion


  //#region CuentasEntidadesFinancieras
  ObtenerCuentasEntidadesFinancieras(idEntidadFinanciera: number): Observable<CuentasEntidadFinanciera[]> {
    return this.get<CuentasEntidadFinanciera[]>(`/Admin/CuentasEntidadFinanciera/${idEntidadFinanciera}`);
  }

  GuardarCuentasEntidadesFinancieras(entidadFinanciera: CuentasEntidadFinanciera): Observable<number> {
    return this.post<number>("/Admin/CuentaEntidadFinanciera", entidadFinanciera);
  }

  BorrarCuentaEntidadFinanciera(idCuentaEntidadFinanciera: number): Observable<void> {
    return this.delete<void>(`/Admin/CuentaEntidadFinanciera/${idCuentaEntidadFinanciera}`);
  }
  //#endregion

  //#region TiposTransporte
  ObtenerTiposTransporte(): Observable<TipoTransporte[]> {
    return this.get<TipoTransporte[]>("/TiposTransporte");
  }

  ObtenerTiposTransporteSimplificado(): Observable<TipoTransporteSimplificado[]> {
    return this.get<TipoTransporteSimplificado[]>("/TiposTransporteSimplificado");
  }
  //#region TiposTransporte

  //#region TiposCuenta
  ObtenerTiposCuenta(): Observable<TipoCuenta[]> {
    return this.get<TipoCuenta[]>("/Admin/TiposCuenta");
  }
  //#region TiposCuenta

  ObtenerSemanas(añoInicial: number, añoFinal: number): Observable<Semana[]> {
    return this.get<Semana[]>(`/Semanas/${añoInicial}/${añoFinal}`);
  }

  ObtenerSemanaActual(): Observable<Semana> {
    return this.get<Semana>("/SemanaActual");
  }

    //#region DistribuidoA

    ObtenerDirigidos(): Observable<DirigidoA[]> {
      return this.get<DirigidoA[]>("/Admin/ObtenerDirigidos");
    }

    ObtenerDirigidosEmpresaTransporte(idEmpresaTransporte: number): Observable<DirigidoA[]> {
      return this.get<DirigidoA[]>(`/Admin/ObtenerDirigidoEmpresa/${idEmpresaTransporte}`);
    }

    GuardarDirigidoA(dirigidoA: DirigidoA): Observable<number> {
      return this.post<number>("/Admin/GuardarDirigioA", dirigidoA);
    }

    //#endregion
}
