import { Component, OnInit, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AutenticacionService } from 'servicios/autenticacion.service';
import { GlobalesService } from 'servicios/globales.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiciosExternosService } from 'servicios/serviciosexternos.service';
import { InfoCanalClima } from 'modelos/Clima/InfoCanalClima';

@Component({
  selector: 'app-clima',
  templateUrl: './clima.component.html',
  styleUrls: ['./clima.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ClimaComponent implements OnInit {
  //climas:InfoCanalClima[]=[];
  @Input() clima: InfoCanalClima = null;
  constructor(
    private globalesService: GlobalesService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private serviciosExternosService:ServiciosExternosService,
  ) { }

  ngOnInit() {

  }
 }