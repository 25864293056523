import { Rol } from 'modelos/Rol';
import { Usuario } from 'modelos/Usuario';

export class ItemMenu {
  IdItemMenu: number;
  Nombre: string;
  URL: string;
  Orden: number;
  IdItemMenuPadre: number;
  Activa: boolean;
  CreadoPor: string;
  FechaCreacion: Date;
  ActualizadoPor: string;
  FechaActualizacion: Date;
  Icono: string;
  Roles: Rol[];
  Usuarios: Usuario[];
  ItemMenuHijos: ItemMenu[];
  ItemMenuPadre: ItemMenu;
}
