import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'servicios/http.service';

import { TipoCarga } from 'modelos/COMEX/Admin/TipoCarga';
import { TipoCargaSimplificado } from 'modelos/TipoCargaSimplificado';
import { ValidacionArancelariaSemana } from 'modelos/COMEX/Admin/ValidacionArancelariaSemana';
import { SemanaArancelaria } from 'modelos/COMEX/Admin/SemanaArancelaria';
import { VSemanaAranceleriaValidacion } from 'modelos/COMEX/Admin/VSemanaAranceleriaValidacion';
import { ValidacionArancelariaMasiva } from 'modelos/COMEX/Admin/ValidacionArancelariaMasiva';




@Injectable()
export class COMEXService  extends HttpService{


    //#region Administracion
    ObtenerTiposCarga(): Observable<TipoCarga[]> {
      return this.get<TipoCarga[]>("/Admin/TiposCarga");
    }
  
    ObtenerTiposCargaSimplificado(): Observable<TipoCargaSimplificado[]> {
      return this.get<TipoCargaSimplificado[]>("/Admin/TiposCargaSimplificado");
    }
    //#endregion


    //#region ValidacionArancelarias

    ObtenerArancelariasValidacion(): Observable<ValidacionArancelariaSemana[]> {
      return this.get<ValidacionArancelariaSemana[]>("/Comex/ObtenerArancelariasValidacion");
    }

    ObtenerValidacionesSemanaArancelaria(idSemana: number): Observable<VSemanaAranceleriaValidacion[]> {
      return this.get<VSemanaAranceleriaValidacion[]>(`/Comex/ObtenerValidacionesSemanaArancelaria/${idSemana}`);
    }

    
    GuardarArancelariaValidacion(arancelaria: ValidacionArancelariaSemana): Observable<number> {
      return this.post<number>("/Comex/GuardarArancelariaValidacion", arancelaria);
    }
    
    GuardarSemanaValidacion(semana: SemanaArancelaria): Observable<number> {
      return this.post<number>("/Comex/GuardarSemanaValidacion", semana);
    }

    GuardeMasivoArancelariaValidacion(semana: ValidacionArancelariaMasiva): Observable<boolean> {
      return this.post<boolean>("/Comex/GuardeMasivoArancelariaValidacion", semana);
    }

    //#endregion




}
