import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'servicios/http.service';
import { HttpHeaders } from '@angular/common/http';
import { TiposArchivo } from 'modelos/Admin/TiposArchivo';
import { ArchivoExcel } from 'modelos/Interfaces/ArchivoExcel';
import { FondoEmpleado } from 'modelos/Admin/FondoEmpleado';

@Injectable()
export class ArchivosdynamicsService extends HttpService {

  
  TiposArchivo(): Observable<TiposArchivo[]> {
    return this.get<TiposArchivo[]>("/ArchivosPlanos/GestionHumana/TiposArchivo");
  }

  FondosEmpleado(): Observable<FondoEmpleado[]> {
    return this.get<FondoEmpleado[]>("/ArchivosPlanos/GestionHumana/FondoEmpleados");
  }


  PlantillaNovedades(archivo: ArchivoExcel ): Observable<string> {
    return this.post<string>(`/ArchivosPlanos/GestionHumana/Novedades`, archivo);
  }

  PlantillaVacaciones(archivo: ArchivoExcel ): Observable<string> {
    return this.post<string>(`/ArchivosPlanos/GestionHumana/Vacaciones`, archivo);
  }


  PlantillaIncapacidades(archivo: ArchivoExcel ): Observable<string> {
    return this.post<string>(`/ArchivosPlanos/GestionHumana/Incapacidades`, archivo);
  }


  PlantillaExtras(archivo: ArchivoExcel ): Observable<string> {
    return this.post<string>(`/ArchivosPlanos/GestionHumana/Extras`, archivo);
  }

  PlantillaAusentismos(archivo: ArchivoExcel ): Observable<string> {
    return this.post<string>(`/ArchivosPlanos/GestionHumana/Ausentismos`, archivo);
  }

  ArchivoTercerosTimework(archivo: ArchivoExcel ): Observable<string> {
    return this.post<string>(`/ArchivosPlanos/GestionHumana/TercerosTimework`, archivo);
  }

  PlantillaNovedadesCasino(archivo: ArchivoExcel ): Observable<string> {
    return this.post<string>(`/ArchivosPlanos/GestionHumana/NovedadesCasino`, archivo);
  }

  GenerarExcelAreasCultivadas(idempresa: number, mes: number): Observable<string> {
    return this.get<string>(`/ArchivosPlanos/Finanzas/ObtenerDiarioAreasCultivadas/${idempresa}/${mes}`);
  }

  GenerarExcelCargoMaster(archivo: ArchivoExcel): Observable<string> {
    return this.post<string>(`/ArchivosPlanos/Ventas/CargoMaster`, archivo);
  }
  
  GenerarCsvCargoMaster(archivo: ArchivoExcel): Observable<string> {
    return this.post<string>(`/ArchivosPlanos/Ventas/CargoMasterCsv`, archivo);
  }

  GenerarExcelMultipleCargoMaster(archivo: ArchivoExcel[]): Observable<string> {
    return this.post<string>(`/ArchivosPlanos/Ventas/CargoMasterMultiple`, archivo);
  }


}
