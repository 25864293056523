import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { Observable, of } from 'rxjs';
import { HttpService } from 'servicios/http.service';
import { CargoMaster } from 'modelos/Dynamics/CargoMaster';
import { FacturaCargoMaster } from 'modelos/Dynamics/FacturaCargoMaster';
import { EmpresaDynamics } from 'modelos/Dynamics/EmpresaDynamics';
import { DSV } from 'modelos/Dynamics/DSV';
import { Aplicacion } from 'modelos/Dynamics/Aplicacion';
import { AplicacionCampo } from 'modelos/Dynamics/AplicacionCampo';
import { Diccionario } from 'modelos/Dynamics/Diccionario';
import { EstimadosDynamics } from 'modelos/Dynamics/EstimadosDynamics';
import { EspeciesDynamics } from 'modelos/Dynamics/EspeciesDynamics';
import { ProyeccionesDynamics } from 'modelos/Dynamics/ProyeccionesDynamics';
import { PorcentajeGradoDynamics } from 'modelos/Dynamics/PorcentajeGradoDynamics';
import { GuardeProyeccion } from 'modelos/Dynamics/GuardeProyeccion';
import { ResumenKuehne } from 'modelos/Dynamics/ResumenKuehne';
import { ClientesKuehne } from 'modelos/Dynamics/ClientesKuehne';
import { CodigoClienteKuehne } from 'modelos/Dynamics/CodigoClienteKuehne';
import { ResumenEtiquetasPieza } from 'modelos/Dynamics/ResumenEtiquetasPieza';
import { PedidosLineas } from 'modelos/Dynamics/PedidosLineas';
import { TransporadorasCargaDynamics } from 'modelos/Dynamics/TransporadorasCargaDynamics';
import { AduanasDynamics } from 'modelos/Dynamics/AduanasDynamics';
import { GuiasMasterDSV } from 'modelos/Dynamics/GuiasMasterDSV';
import { GuiasDynamicsDSV } from 'modelos/Dynamics/GuiasDynamicsDSV';
import { VGuiasCreadasDynamics } from 'modelos/Dynamics/VGuiasCreadasDynamics';
import { VGuiasHijaCreadasDynamics } from 'modelos/Dynamics/VGuiasHijaCreadasDynamics';
import { LineasPedidoDynamics } from 'modelos/Dynamics/LineasPedidoDynamics';
import { GuiasAsignadasDynamics } from 'modelos/Dynamics/GuiasAsignadasDynamics';
import { VGuiaAsignadaDynamics } from 'modelos/Dynamics/VGuiaAsignadaDynamics';
import { CargoMasterDSV } from 'modelos/Dynamics/CargoMasterDSV';
import { ParametrosExcelDSV } from 'modelos/Dynamics/ParametrosExcelDSV';
import { LineasEncabezadoOP } from 'modelos/Dynamics/LineasEncabezadoOP';


@Injectable()
export class DynamicsService extends HttpService {
    ObtenerResumenFacturasCargoMaster(dataAreaID: string, fecha: Date, factura?: string): Observable<FacturaCargoMaster[]> {
        if (factura)
            return this.get<FacturaCargoMaster[]>(`/Dynamics/ResumenFacturasCargoMaster/${dataAreaID}/${formatDate(fecha, "yyyy-MM-dd", "en")}/${factura}`);
        else
            return this.get<FacturaCargoMaster[]>(`/Dynamics/ResumenFacturasCargoMaster/${dataAreaID}/${formatDate(fecha, "yyyy-MM-dd", "en")}`);
    }

    ObtenerFacturasCargoMaster(dataAreaID: string, fecha: Date, facturas: string[]): Observable<CargoMaster[]> {
        return this.post<CargoMaster[]>(`/Dynamics/FacturasCargoMaster/${dataAreaID}/${formatDate(fecha, "yyyy-MM-dd", "en")}`, facturas);
    }

    GenerarExcelCargoMaster(datos: CargoMaster[]): Observable<string> {
        return this.post<string>("/Dynamics/ExcelCargoMaster", datos);
    }

    ObtenerEmpresasDynamics(dataAreaID: string): Observable<EmpresaDynamics[]> {
        return this.get<EmpresaDynamics[]>(`/Dynamics/ObtenerEmpresasDynamics/${dataAreaID}`);
    }

    ObtenerAplicaciones(): Observable<Aplicacion[]> {
        return this.get<Aplicacion[]>("/Dynamics/Aplicaciones");
    }

    GuardarAplicacion(aplicacion: Aplicacion): Observable<number> {
        return this.post<number>("/Dynamics/Aplicacion", aplicacion);
    }

    BorrarAplicacion(aplicacionId: number): Observable<void> {
        return this.delete<void>(`/Dynamics/Aplicacion/${aplicacionId}`);
    }

    ObtenerAplicacionCampos(aplicacionId: number): Observable<AplicacionCampo[]> {
        return this.get<AplicacionCampo[]>(`/Dynamics/AplicacionCampos/${aplicacionId}`);
    }

    GuardarAplicacionCampo(aplicacionCampo: AplicacionCampo): Observable<number> {
        return this.post<number>("/Dynamics/AplicacionCampo", aplicacionCampo);
    }

    BorrarAplicacionCampo(idAplicacionCampo: number): Observable<void> {
        return this.delete<void>(`/Dynamics/AplicacionCampo/${idAplicacionCampo}`);
    }

    ObtenerDiccionarios(aplicacionId: number, idAplicacionCampo?: number): Observable<Diccionario[]> {
        if (!idAplicacionCampo)
            return this.get<Diccionario[]>(`/Dynamics/Diccionarios/${aplicacionId}`);
        else
            return this.get<Diccionario[]>(`/Dynamics/Diccionarios/${aplicacionId}/${idAplicacionCampo}`);
    }

    GuardarDiccionario(diccionario: Diccionario): Observable<number> {
        return this.post<number>("/Dynamics/Diccionario", diccionario);
    }

    BorrarDiccionario(diccionarioId: number): Observable<void> {
        return this.delete<void>(`/Dynamics/Diccionario/${diccionarioId}`);
    }

    //#region DSV

    ObtenerFacturasDSV(dataAreaID: string, fecha: Date, facturas: string[]): Observable<DSV[]> {
        return this.post<DSV[]>(`/Dynamics/FacturasDSV/${dataAreaID}/${formatDate(fecha, "yyyy-MM-dd", "en")}`, facturas);
    }

    GenerarExcelDSV(datos: DSV[]): Observable<string> {
        return this.post<string>("/Dynamics/ExcelDSV", datos);
    }

    ObtenerResumenReservasDSV(dataAreaID: string, fecha: Date, custAccount: string): Observable<CargoMasterDSV[]> {
        return this.get<CargoMasterDSV[]>(`/Dynamics/ObtenerResumenReservasDSV/${dataAreaID}/${formatDate(fecha, "yyyy-MM-dd", "en")}/${custAccount}`);
    }
    
    ObtenerReservasDSV(parametros: ParametrosExcelDSV): Observable<string> {
        return this.post<string>("/Dynamics/ObtenerReservasDSV", parametros);
    }

    ObtenerPedidosDSV(dataAreaID: string, fecha: Date, custaccount: string): Observable<PedidosLineas[]> {
        return this.get<PedidosLineas[]>(`/Dynamics/ObtenerPedidosDSV/${dataAreaID}/${formatDate(fecha, "yyyy-MM-dd", "en")}/${custaccount}`);
    }

    ObtenerGuiasDSV(fecha: Date, dataAreaID: string): Observable<GuiasMasterDSV[]> {
        return this.get<GuiasMasterDSV[]>(`/Dynamics/ObtenerGuiasDSV/${formatDate(fecha, "yyyy-MM-dd", "en")}/${formatDate(fecha, "yyyy-MM-dd", "en")}/${dataAreaID}`);
    }

    ObtenerInformacionPedidosDSVDynamics(dataAreaID: string, fechaInicial: Date, fechaFinal: Date, custAccount: string): Observable<LineasPedidoDynamics[]> {
        return this.get<LineasPedidoDynamics[]>(`/Dynamics/ObtenerInformacionPedidosDSVDynamics/${dataAreaID}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}/${custAccount}`);
    }

    //#endregion

    //#region  Estimados

    ObtenerEspeciesCPDynamics(idcentroproduccion: number): Observable<EspeciesDynamics[]> {
        return this.get<EspeciesDynamics[]>(`/Dynamics/ObtenerEspeciesCPDynamics/${idcentroproduccion}`);
    }

    ObtenerInformacionCreacionEstimados(idcentroproduccion: number, semanaini: string, especie: string): Observable<EstimadosDynamics[]> {
        return this.get<EstimadosDynamics[]>(`/Dynamics/ObtenerInformacionCreacionEstimados/${idcentroproduccion}/${semanaini}/${especie}`);
    }

    ObtenerClasificacionDynamics(idcentroproduccion: number, semanaini: string, semanafin: string, especie: string): Observable<EstimadosDynamics[]> {
        return this.get<EstimadosDynamics[]>(`/Dynamics/ObtenerClasificacionDynamics/${idcentroproduccion}/${semanaini}/${semanafin}/${especie}`);
    }

    ObtenerInformacionPorcentajeGradosEstimados(idcentroproduccion: number, semanaini:string, especie: string): Observable<EstimadosDynamics[]> {
        return this.get<any[]>(`/Dynamics/ObtenerInformacionPorcentajeGradosEstimados/${idcentroproduccion}/${semanaini}/${especie}`);
    }

    GuardarProyecciones(proyeccion: ProyeccionesDynamics[]): Observable<number> {
        return this.post<number>("/Estimados/Proyecciones", proyeccion);
    }


    GuardarPorcentajesGrados(porcentajegrados: GuardeProyeccion): Observable<number> {
        return this.post<number>("/Estimados/PorcentajesGrados", porcentajegrados);
    }

    ObtenerProyecciones(idcentroproduccion: number, semanaini:string, semanafin:string, especie: number): Observable<ProyeccionesDynamics[]> {
        return this.get<any[]>(`/Estimados/Proyecciones/${idcentroproduccion}/${semanaini}/${semanafin}/${especie}`);
    }


    GuardarEstimados(registros: GuardeProyeccion): Observable<number> {
        return this.post<number>("/Estimados/GuardarEstimados", registros);
    }

    ProcesarProyeccion(centroproduccion:number, especie:number, semanaprocesar:string, idusuario:number): Observable<any> {
        return this.get<any>(`/Estimados/ProcesarProyeccion/${centroproduccion}/${especie}/${semanaprocesar}/${idusuario}`);
    }

    ObtenerDetalleProyeccion(idcentroproduccion: number, semanaini:string, semanafin:string, especie: number): Observable<ProyeccionesDynamics[]> {
        return this.get<ProyeccionesDynamics[]>(`/Estimados/DetalleProyeccion/${idcentroproduccion}/${semanaini}/${semanafin}/${especie}`);
    }

    ObtenerDetallePorcentajeGrado(idcentroproduccion: number, semanaini:string, semanafin:string, especie: number): Observable<PorcentajeGradoDynamics[]> {
        return this.get<PorcentajeGradoDynamics[]>(`/Estimados/PorcentajesGrados/${idcentroproduccion}/${semanaini}/${semanafin}/${especie}`);
    }

    ValidarProyeccionesCreadas(idcentroproduccion: number, semanaini:string, semanafin:string, especie: number): Observable<string> {
        return this.get<string>(`/Estimados/ValidarProyeccionesCreadas/${idcentroproduccion}/${semanaini}/${semanafin}/${especie}`);
    }

    ValidarPorcentajesGradosCreados(idcentroproduccion: number, semanaini:string, semanafin:string, semanaorigen:string, especie: number): Observable<string> {
        return this.get<string>(`/Estimados/ValidarPorcentajesGradosCreados/${idcentroproduccion}/${semanaini}/${semanafin}/${semanaorigen}/${especie}`);
    }

    ObtenerSemanaProyeccion(fecha:Date): Observable<number> {
        return this.get<number>(`/Estimados/ObtenerSemanaProyeccion/${formatDate(fecha, "yyyy-MM-dd", "en")}`);
    }

    //#endregion

    //#region  Kuehne

    ObtenerConsignatariosKuehne(dataAreaID: string, fecha: Date, cliente: string, idcp: number): Observable<CodigoClienteKuehne[]> {
        return this.get<CodigoClienteKuehne[]>(`/Dynamics/ObtenerConsignatariosKuehne/${dataAreaID}/${formatDate(fecha, "yyyy-MM-dd", "en")}/${formatDate(fecha, "yyyy-MM-dd", "en")}/${cliente}/${idcp}`);
    }

    ObtenerClientesKuehne(dataAreaID: string, fecha: Date): Observable<ClientesKuehne[]> {
        return this.get<ClientesKuehne[]>(`/Dynamics/ObtenerClientesKuehne/${dataAreaID}/${formatDate(fecha, "yyyy-MM-dd", "en")}`);
    }

    ObtenerClientesKuehneIntgr(): Observable<ClientesKuehne[]> {
        return this.get<ClientesKuehne[]>("/Dynamics/ObtenerClientesKuehneIntgr");
    }


    ObtenerInformacionDynamicsKuehne(dataAreaID: string, fecha: Date, cliente: string, consignatario:number, po:string, cp:number): Observable<ResumenKuehne[]> {
        return this.get<ResumenKuehne[]>(`/Dynamics/ObtenerInformacionDynamicsKuehne/${dataAreaID}/${formatDate(fecha, "yyyy-MM-dd", "en")}/${formatDate(fecha, "yyyy-MM-dd", "en")}/${cliente}/${consignatario}/${po}/${cp}`);
    }

    ObtenerReservasKuehne(dataAreaID: string, fecha: Date, cliente: string,  idsubcliente: number, idusuario:number, po:string, cp:number,fechareservadyn: Date, ): Observable<string> {
        return this.get<string>(`/Dynamics/ObtenerReservasKuehne/${dataAreaID}/${formatDate(fecha, "yyyy-MM-dd", "en")}/${formatDate(fecha, "yyyy-MM-dd", "en")}/${cliente}/${idsubcliente}/${idusuario}/${po}/${cp}/${formatDate(fechareservadyn, "yyyy-MM-dd", "en")}`);
    }

    //#endregion
    
    //#region  Etiquetas

    ObtenerClienteEtiquetas(): Observable<Diccionario[]> {
        return this.get<Diccionario[]>("/Dynamics/ObtenerClienteEtiquetas");
    }

    
    ObtenerResumenEtiquetasParametrizables(dataAreaID: string, fechaini: Date,fechafin: Date, cliente: string): Observable<ResumenEtiquetasPieza[]> {
        return this.get<ResumenEtiquetasPieza[]>(`/Dynamics/ObtenerResumenEtiquetasParametrizables/${dataAreaID}/${formatDate(fechaini, "yyyy-MM-dd", "en")}/${formatDate(fechafin, "yyyy-MM-dd", "en")}/${cliente}`);
    }

    ObtenerEtiquetasParametrizables(dataAreaID: string, fechaini: Date,fechafin: Date,  cliente: string): Observable<string> {
        return this.get<string>(`/Dynamics/ObtenerEtiquetasParametrizables/${dataAreaID}/${formatDate(fechaini, "yyyy-MM-dd", "en")}/${formatDate(fechafin, "yyyy-MM-dd", "en")}/${cliente}`);
    }

    
    //#endregion

    //#region Guias Dynamics


    CrearGuiasMasterDynamics( guiasmaster: GuiasDynamicsDSV): Observable<boolean> {
        return this.post<boolean>("/Dynamics/CrearGuiasMasterDynamics", guiasmaster);
    }

    CrearGuiasHijasDynamics( guiahija: GuiasDynamicsDSV): Observable<boolean> {
        return this.post<boolean>("/Dynamics/CrearGuiasHijasDynamics", guiahija);
    }

    ActualizarEncabezadoPedidoGuiaMaster( dataAreaId: string, salesId: string, guiaMaster: string, idGuiaMaster: number): Observable<boolean> {
        return this.get<boolean>(`/Dynamics/ActualizarEncabezadoPedidoGuiaMaster/${dataAreaId}/${salesId}/${guiaMaster}/${idGuiaMaster}`);
    }

    ActualizarLineaPedidoGuiaHija( dataAreaId: string, salesId: string, guiaHija: string, lineNum: number): Observable<boolean> {
        return this.get<boolean>(`/Dynamics/ActualizarLineaPedidoGuiaHija/${dataAreaId}/${salesId}/${guiaHija}/${lineNum}`);
    }

    ObtenerEmpresasTransportadoras(dataAreaID: string): Observable<TransporadorasCargaDynamics[]> {
        return this.get<TransporadorasCargaDynamics[]>(`/Dynamics/ObtenerEmpresasTransportadoras/${dataAreaID}`);
    }

    ObtenerAgentesCarga(dataAreaID: string): Observable<TransporadorasCargaDynamics[]> {
        return this.get<TransporadorasCargaDynamics[]>(`/Dynamics/ObtenerAgentesCarga/${dataAreaID}`);
    }

    ObtenerAgentesAduanas(dataAreaID: string): Observable<AduanasDynamics[]> {
        return this.get<AduanasDynamics[]>(`/Dynamics/ObtenerAgentesAduanas/${dataAreaID}`);
    }

    ObtenerGuiasMasterCreadasDynamics(fechaInicial: Date, fechaFinal: Date): Observable<VGuiasCreadasDynamics[]> {
        return this.get<VGuiasCreadasDynamics[]>(`/Dynamics/ObtenerGuiasMasterCreadasDynamics/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}`);
    }

    ObtenerGuiasHijaCreadasDynamics(fechaInicial: Date, fechaFinal: Date, guiaMaster: string, dataAreaId: string): Observable<VGuiasHijaCreadasDynamics[]> {
        return this.get<VGuiasHijaCreadasDynamics[]>(`/Dynamics/ObtenerGuiasHijaCreadasDynamics/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}/${guiaMaster}/${dataAreaId}`);
    }

    ObtenerGuiasAsignadasDynamics(guia:GuiasAsignadasDynamics): Observable<VGuiaAsignadaDynamics[]> {
        return this.post<VGuiaAsignadaDynamics[]>("/Dynamics/ObtenerGuiasAsignadasDynamics", guia);
    }

    ActualizarGuiasPedidoPoMarca(dataAreaId: string, fechaInicial: Date, fechaFinal: Date, custAccount: string, guiaMaster:string, recidGuia:number, guiaHija:string, po:string, marca:string): Observable<boolean> {
        return this.get<boolean>(`/Dynamics/ActualizarGuiasPedidoPoMarca/${dataAreaId}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${formatDate(fechaFinal, "yyyy-MM-dd", "en")}/${custAccount}/${guiaMaster}/${recidGuia}/${guiaHija}/${po}/${marca}`);
    }

    CrearGuiaAsignadaDynamics( guia: GuiasAsignadasDynamics): Observable<number> {
        return this.post<number>("/Dynamics/CrearGuiaAsignadaDynamics", guia);
    }

    //#endregion

    //#region OPD
    
    ObtenerResumenEmpaquesOPD(dataAreaID: string, fechaInicial: Date, custAccount: string): Observable<LineasEncabezadoOP[]> {
        return this.get<LineasEncabezadoOP[]>(`/Dynamics/ObtenerResumenEmpaquesOPD/${dataAreaID}/${formatDate(fechaInicial, "yyyy-MM-dd", "en")}/${custAccount}`);
    }

    GenerarOPDDynamics(datos: LineasEncabezadoOP[]): Observable<string> {
        return this.post<string>("/Dynamics/GenerarOPDDynamics", datos);
    }

    //#enregion

}