import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPrintModule } from 'ngx-print';
import {
  MatInputModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatDialogModule,
  MatListModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatAutocompleteModule,
  MatRadioModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatPaginatorModule,
  MatPaginatorIntl,
  MatSortModule,
  MatCheckboxModule,
  MatTabsModule,
  MatToolbarModule,
  MatSidenavModule,
  MatMenuModule,
  MatIconModule,
  MatExpansionModule,
  MatGridListModule,
  MatTreeModule,
  MatRippleModule,
  MatTooltipModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderModule } from 'ngx-order-pipe';
import { GlobalesService } from 'servicios/globales.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from 'vistas/login/login.component';
import { MenuComponent } from './base/menu/menu.component';
import { DashboardComponent } from 'vistas/dashboard/dashboard.component';
import { VisorReporteComponent } from 'vistas/compartido/visor-reporte/visor-reporte.component';
import { CatalogoBusquedaComponent } from 'vistas/compartido/catalogo-busqueda/catalogo-busqueda.component';
import { ParametricasComponent } from 'vistas/parametricas/parametricas.component';
import { DetalleParametricasDialogoComponent } from 'vistas/parametricas/detalle-parametricas-dialogo/detalle-parametricas-dialogo.component';
import { AdministracionService } from 'servicios/administracion.service';
import { ProductoService } from 'servicios/producto.service';
import { CrmService } from 'servicios/crm.service';
import { HttpService } from 'servicios/http.service';
import { MatPaginatorIntlEs } from 'servicios/MatPaginatorIntlEs';
import { AlertaComponent } from './base/dialogo-alerta/dialogo-alerta';
import { ChartsModule } from 'ng2-charts';
import { LogisticaService } from 'servicios/logistica.service';
import { IntegracionService } from 'servicios/integracion.service';
import { TecnicoService } from 'servicios/tecnico.service';
import { DetalleFlorDialogoComponent } from 'vistas/producto/catalogoproducto/detalle-flor-dialogo/detalle-flor-dialogo.component';
import { MercadeoService } from 'servicios/mercadeo.service';
import { DetalleInsumoDialogoComponent } from 'vistas/producto/catalogoproducto/detalle-insumo-dialogo/detalle-insumo-dialogo.component';
import { VisorPowerBIComponent } from 'vistas/compartido/visor-powerbi/visor-powerbi.component';
import { DetallePiezaDialogoComponent } from 'vistas/producto/catalogopiezas/detalle-pieza-dialogo/detalle-pieza-dialogo.component';
import { MenuUsuario } from './base/menu/menu.usuario';
import { FacturacionService } from 'servicios/facturacion.service';
import { StorageServiceModule } from 'angular-webstorage-service';
import { AutenticacionService } from 'servicios/autenticacion.service';
import { COMEXService } from 'servicios/comex.service';
import { ServiciosExternosService } from 'servicios/serviciosexternos.service';
import { TableModule } from 'primeng/table';
import { ClimaComponent } from 'vistas/clima/clima.component';
import { PostcosechaService } from 'servicios/postcosecha.service';
import { ArchivosdynamicsService } from 'servicios/archivosdynamics.service';
import { HttpKonektoService } from 'servicios/http-konekto.service';
import { ServicioskonektoService  } from 'servicios/servicioskonekto.service';
import { DynamicsService } from 'servicios/dynamics.service';
import { EstadisticosService } from 'servicios/estadisticos.service';
import { GuardiaGuard } from './guardias/guardia.guard';
import { MsalModule } from '@azure/msal-angular';
import { environment } from 'environments/environment';




export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read']]
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenuComponent,
    MenuUsuario,
    DashboardComponent,
    VisorReporteComponent,
    ParametricasComponent,
    CatalogoBusquedaComponent,
    AlertaComponent,
    DetalleParametricasDialogoComponent,
    DetalleFlorDialogoComponent,
    DetalleInsumoDialogoComponent,
    VisorPowerBIComponent,
    DetallePiezaDialogoComponent,
    ClimaComponent
    

  ],
  imports: [
    StorageServiceModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatTabsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    MatIconModule,
    MatExpansionModule,
    MatGridListModule,
    MatTreeModule,
    FlexLayoutModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    ChartsModule,
    NgbModule.forRoot(),
    OrderModule,
    NgxSpinnerModule,
    TableModule,
    MatRippleModule,
    MatTooltipModule,
    NgxPrintModule,
    MsalModule.forRoot({
      auth: {
        clientId: environment.clientId,
        authority: environment.authority,
        redirectUri: environment.redirectUri,
        postLogoutRedirectUri: environment.postLogoutRedirectUri
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
      },
    },
    {
      consentScopes: [
        'user.read',
        'openid',
        'profile',
        'api://a88bb933-319c-41b5-9f04-eff36d985612/access_as_user'
      ],
      unprotectedResources: ['https://www.microsoft.com/en-us/'],
      protectedResourceMap,
      extraQueryParameters: {}
    })
  ],
  providers: [
    IntegracionService,
    LogisticaService,
    AdministracionService,
    ProductoService,
    TecnicoService,
    MercadeoService,
    FacturacionService,
    COMEXService,
    HttpService,
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlEs
    },
    CrmService,
    PostcosechaService,
    AutenticacionService,
    GlobalesService,
    ServiciosExternosService,
    ArchivosdynamicsService,
    HttpKonektoService,
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlEs
    },
    ServicioskonektoService,
    EstadisticosService,
    GuardiaGuard,
    DynamicsService,
    EstadisticosService
  ],
  entryComponents: [
    CatalogoBusquedaComponent,
    DetalleParametricasDialogoComponent,
    DetalleFlorDialogoComponent,
    DetalleInsumoDialogoComponent,
    DetallePiezaDialogoComponent,
    ClimaComponent
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
