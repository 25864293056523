import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ProductoService } from 'servicios/producto.service';
import { CatalogoBusquedaComponent } from 'vistas/compartido/catalogo-busqueda/catalogo-busqueda.component';


import { CatalogoPieza } from 'modelos/CatalogoPieza';
import { TipoEmpaque } from 'modelos/TipoEmpaque';
import { Empaque } from 'modelos/Empaque';
import { ProductoItemDesgloce } from 'modelos/ProductoItemDesgloce';


@Component({
  selector: 'app-detalle-pieza-dialogo',
  templateUrl: './detalle-pieza-dialogo.component.html',
  styleUrls: ['./detalle-pieza-dialogo.component.css']
})
export class DetallePiezaDialogoComponent implements OnInit {
  CatalogoBusquedaDialogo: MatDialogRef<CatalogoBusquedaComponent>;
  piezaForm: FormGroup;
  Pieza: CatalogoPieza;
  ProductosItem: ProductoItemDesgloce[] = [];
  columnas = ['IdProductoCompuesto', 'DescripcionReceta', 'Unidades', 'Opciones'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  uname: string;
  idproductotemp: number;
  descripciontemp: string;
  TiposEmpaque: TipoEmpaque[] = [];
  Empaques: Empaque[] = [];

  constructor(
    private productoService: ProductoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DetallePiezaDialogoComponent>,
    private dialog: MatDialog,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.ObtenerEmpaques();
    this.ObtenerTiposEmpaque();
    this.piezaForm = new FormGroup({
      idPieza: new FormControl(0),
      tipompaque: new FormControl(0),
      empaque: new FormControl(0),
      descripcion: new FormControl(""),
      referencia: new FormControl({ value: "", disabled: true }),
      cantidad: new FormControl("")

    });
  }

  ObtenerEmpaques() {
    this.productoService.ObtenerEmpaques().subscribe(
      empaques => {
        this.Empaques = empaques;
      },
      error => this.toastr.error("Error al obtener las especies. Intente más tarde por favor.", "ERROR"));
  }

  ObtenerTiposEmpaque() {
    this.productoService.ObtenerTiposEmpaque().subscribe(
      tipoempaques => {
        this.TiposEmpaque = tipoempaques;
      },
      error => this.toastr.error("Error al obtener las variedades. Intente más tarde por favor.", "ERROR"));
  }

  MostrarCatalogo() {
    this.CatalogoBusquedaDialogo = this.dialog.open(CatalogoBusquedaComponent, { width: "700px", data: { idTipoProducto: 2 } });
    // Cuando se cierra el cuadro de diálogo guarda el usuario
    this.CatalogoBusquedaDialogo.afterClosed().subscribe(
      data => {
        if (data) {
          if (data.ref) {

            this.uname = data.ref.Descripcion;
            this.descripciontemp = data.ref.Descripcion;
            this.idproductotemp = data.ref.IdProducto;

          }
        }
      }
    );
  }

  onClickSubmit(data) {
    this.dialogRef.close({ data, composicion: { "item": this.ProductosItem } });
  }

  AgregarReceta(unidades: number) {


    if (unidades && this.idproductotemp) {
      let producto: ProductoItemDesgloce = new ProductoItemDesgloce();
      producto.IdProductoCompuesto = this.idproductotemp;
      producto.DescripcionReceta = this.descripciontemp;
      producto.Unidades = unidades;
      this.ProductosItem.push(producto);
      this.dataSource = new MatTableDataSource<any>(this.ProductosItem);
      this.dataSource.sort = this.sort;

    }
  }

  BorrarReceta(productoitem: ProductoItemDesgloce) {
    this.ProductosItem.splice(this.ProductosItem.indexOf(productoitem), 1)
    this.dataSource = new MatTableDataSource<any>(this.ProductosItem);
    this.dataSource.sort = this.sort;

  }

}
