import { Injectable } from '@angular/core';
import { HttpService } from 'servicios/http.service';
import { Observable } from 'rxjs';
import { EtiquetaEntrada } from 'modelos/Postcosecha/EtiquetaEntrada';
import { FiltroPedidoUpc } from 'modelos/Postcosecha/FiltroPedidoUpc';
import { PedidoUpc } from 'modelos/Postcosecha/PedidoUpc';
import { EntradaPostcosecha } from 'modelos/Postcosecha/EntradaPostcosecha';

@Injectable()
export class PostcosechaService extends HttpService {

  GenerarEtiquetasContenedorZPL(etiquetasEntrada: EtiquetaEntrada[]): Observable<string> {
    return this.post<string>('/Postcosecha/EtiquetasContenedorZPL', etiquetasEntrada);
  }

  GenerarEtiquetasContenedorCSV(etiquetasEntrada: EtiquetaEntrada[]): Observable<string> {
    return this.post<string>('/Postcosecha/EtiquetasContenedorCSV', etiquetasEntrada);
  }

  ObtenerPedidosUpc(filtro: FiltroPedidoUpc): Observable<PedidoUpc[]> {
    return this.post<PedidoUpc[]>('/Postcosecha/PedidosUpc', filtro);
  }

  GenerarEtiquetasUpcCSV(pedidosUpc: PedidoUpc[]): Observable<string> {
    return this.post<string>('/Postcosecha/EtiquetasUpcCSV', pedidosUpc);
  }

  GuardarEntradaPostcosechaTecnico(entradas: EntradaPostcosecha[]): Observable<number[]> {
    return this.post<number[]>('/Postcosecha/EntradaPostcosechaTecnico', entradas);
  }
}
