import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CrmService } from 'servicios/crm.service';
import { ResultadoOfrecimientos } from 'modelos/CRM/ResultadoOfrecimientos';
import { ToastrService } from 'ngx-toastr';
import { Color } from 'ng2-charts/ng2-charts';
import { ServiciosExternosService } from 'servicios/serviciosexternos.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']

})
export class DashboardComponent implements OnInit {
  Ofrecimientos: ResultadoOfrecimientos[] = [];
  public hayDatos: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private crmService: CrmService,
    private servicioExterno: ServiciosExternosService,
    private toastr: ToastrService,
    private httpClient: HttpClient
  ) {
    this.ObtenerOfrecimiento();
  }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);
  
    this.servicioExterno.ObtenerInformacionCanalClima().subscribe
      (
        resultado => {
        },
        error => this.toastr.warning("Error al obtener los ofrecimientos.", "ERROR")

      )


  }
  // lineChart
  public lineChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  public lineChartLegend: boolean = true;
  public lineChartData = [];

  colorsOverride: Array<Color> = [{
    backgroundColor: '#f39222',
    hoverBackgroundColor: '#86a526'
  }];

  public lineChartLabels = [];

  public lineChartType: string = 'bar';

  // Pie

  public chartClicked(e: any): void {
  }

  public chartHovered(e: any): void {
  }

  public ObtenerOfrecimiento() {
    this.crmService.ObtenerOfrecimientos().subscribe(
      resultado => {
        this.lineChartData = [];
        this.Ofrecimientos = resultado;

        let label: "Cajas Full";
        let data: number[] = [];
        this.lineChartLabels = [];
        this.lineChartData = [];
        for (let index = 0; index < this.Ofrecimientos.length; index++) {
          data.push(this.Ofrecimientos[index].CajasFull);
          this.lineChartLabels.push(this.Ofrecimientos[index].Especie);
        }

        this.lineChartData = [{ data: data, label: "Cajas Full", backgroundColor: ["#3e95cd"] }];
        this.hayDatos = true;
      },
      error => this.toastr.warning("Error al obtener los ofrecimientos.", "ERROR")
    );
  }
}
