import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ProductoService } from 'servicios/producto.service';
import { Especie } from 'modelos/Especie';
import { Color } from 'modelos/Color';
import { Grado } from 'modelos/Grado';
import { Variedad } from 'modelos/Variedad';

@Component({
  selector: 'app-detalle-flor-dialogo',
  templateUrl: './detalle-flor-dialogo.component.html',
  styleUrls: ['./detalle-flor-dialogo.component.css']
})
export class DetalleFlorDialogoComponent implements OnInit {
  florForm: FormGroup;
  Especies: Especie[] = [];
  Colores: Color[] = [];
  Grados: Grado[] = [];
  Variedades: Variedad[] = [];

  constructor(
    private productoService: ProductoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DetalleFlorDialogoComponent>,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.florForm = new FormGroup({
      idProducto: new FormControl(0),
      idEspecie: new FormControl(0),
      idColor: new FormControl(0),
      idGrado: new FormControl(0),
      idVariedad: new FormControl(0),
      descripcion: new FormControl({ value: 'Automatico', disabled: true }),
      referencia: new FormControl({ value: 'Automatico', disabled: true }),
      activo: new FormControl(1)
    });

    this.ObtenerEspecies();
    this.ObtenerGrados();
  }

  ObtenerEspecies() {
    this.productoService.ObtenerEspecies().subscribe(
      especies => {
        this.Especies = especies.filter(p => p.Estado == true);
      },
      error => this.toastr.error("Error al obtener las especies. Intente más tarde por favor.", "ERROR"));
  }

  ObtenerGrados() {
    this.productoService.ObtenerGrados().subscribe(
      grados => {
        this.Grados = grados.filter(p => p.Estado == true);;
      },
      error => this.toastr.error("Error al obtener los grados. Intente más tarde por favor.", "ERROR"));
  }

  ObtenerColores(idespecie: number) {
    this.productoService.ObtenerColoresEspecie(idespecie).subscribe(
      colores => {
        this.Colores = colores.filter(p => p.Estado == true);;
      },
      error => this.toastr.error("Error al obtener los colores. Intente más tarde por favor.", "ERROR"));

  }

  ObtenerVariedades(idespecie: number, idcolor: number) {
    this.productoService.ObtenerVariedadesEspecieColor(idespecie, idcolor).subscribe(
      variedades => {
        this.Variedades = variedades.filter(p => p.Estado == true);;
      },
      error => this.toastr.error("Error al obtener las variedades. Intente más tarde por favor.", "ERROR"));

  }

  ObtenerColoresPorVariedad(idVariedad: number) {

    if (idVariedad != 0) {
      this.productoService.ObtenerColoresPorVariedad(idVariedad).subscribe(
        colores => {
          this.Colores = colores.filter(p => p.Estado == true);;
        },
        error => this.toastr.error("Error al obtener las variedades. Intente más tarde por favor.", "ERROR"));
    }


  }

  onClickSubmit(data) {
    this.dialogRef.close(data);
  }

}
