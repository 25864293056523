import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AdministracionService } from 'servicios/administracion.service';
import { CampoTabla } from 'modelos/CampoTabla';

@Component({
  selector: 'app-detalle-parametricas-dialogo',
  templateUrl: './detalle-parametricas-dialogo.component.html',
  styleUrls: ['./detalle-parametricas-dialogo.component.css']
})
export class DetalleParametricasDialogoComponent implements OnInit {
  camposForm: FormGroup;
  listas: any = {};

  constructor(
    public administracionService: AdministracionService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DetalleParametricasDialogoComponent>
  ) { }

  ngOnInit() {
    let controles: any = {};

    for (let campo of this.data.camposTabla)
      if (campo.ColumnaForanea != null) {
        if (campo.EsObligatoria)
          controles[campo.NombreColumna] = new FormControl(this.obtenerValor(campo, undefined), Validators.required);
        else
          controles[campo.NombreColumna] = new FormControl(this.obtenerValor(campo, undefined));

        this.listas[campo.ColumnaForanea] = this.administracionService.ObtenerRegistrosTablaForanea(campo.ColumnaForanea).pipe(catchError(error => of(this.toastr.error(`Error al obtener los campos de la tabla foránea (${campo.ColumnaForanea}).`, "ERROR"))));
      }
      else
        switch (campo.TipoDato) {
          case "int":
          case "smallint":
          case "bigint":
          case "money":
          case "float":
          case "decimal":
            if (campo.EsObligatoria)
              controles[campo.NombreColumna] = new FormControl(this.obtenerValor(campo, 0), Validators.required);
            else
              controles[campo.NombreColumna] = new FormControl(this.obtenerValor(campo, 0));
            break;

          case "nvarchar":
          case "varchar":
            if (campo.EsObligatoria)
              controles[campo.NombreColumna] = new FormControl(this.obtenerValor(campo, ""), Validators.required);
            else
              controles[campo.NombreColumna] = new FormControl(this.obtenerValor(campo, ""));
            break;

          case "datetime":
          case "smalldatetime":
            if (campo.EsObligatoria)
              controles[campo.NombreColumna] = new FormControl(this.obtenerValor(campo, new Date()), Validators.required);
            else
              controles[campo.NombreColumna] = new FormControl(this.obtenerValor(campo, new Date()));
            break;

          case "bit":
            if (campo.EsObligatoria)
              controles[campo.NombreColumna] = new FormControl(this.obtenerValor(campo, false), Validators.required);
            else
              controles[campo.NombreColumna] = new FormControl(this.obtenerValor(campo, false));
            break;

          default:
            if (campo.EsObligatoria)
              controles[campo.NombreColumna] = new FormControl(this.obtenerValor(campo, ""), Validators.required);
            else
              controles[campo.NombreColumna] = new FormControl(this.obtenerValor(campo, ""));
            break;
        }

    this.camposForm = new FormGroup(controles);
  }

  obtenerTipoControl(campo: CampoTabla): string {
    let tipo: string;

    if (!campo.ColumnaForanea)
      switch (campo.TipoDato) {
        case "bit":
          tipo = "check";
          break;

        case "datetime":
        case "smalldatetime":
          tipo = "calendar";
          break;

        default:
          tipo = "input";
          break;
      }
    else
      tipo = "select";

    return tipo;
  }

  onClickSubmit(data) {
    this.dialogRef.close(data);
  }

  private obtenerValor(campo: CampoTabla, valorDefecto: any): any {
    if (this.data.registro)
      for (let propiedad of Object.getOwnPropertyNames(this.data.registro))
        if (propiedad.toLocaleLowerCase() == campo.NombreColumna.toLocaleLowerCase())
          return this.data.registro[propiedad];

    return valorDefecto;
  }

  obtenerNombre(campo: CampoTabla): string {
    if (campo.Descripcion)
      return campo.Descripcion == "Estado"? "Activo" :campo.Descripcion ;
    else
      if (campo.EtiquetaForanea)
        return campo.EtiquetaForanea ;
      else
        return campo.NombreColumna == "Estado"? "Activo" :campo.NombreColumna ;
  }
}