export const environment = {
  production: false,
  urlServicios: 'https://ffrestwprbfunzanet.azurewebsites.net',
  urlServiciosKonekto: 'https://ffrestwprbkonekto.azurewebsites.net',
  urlReportes: 'https://reportes.organizacionfunza.net/reports/report',
  urlApiEstadisticos: 'https://estadisticosprb.organizacionfunza.net/api',
  titulo: 'PRUEBAS APP SERVICE',
  version: 'CARNATION PRADO V.2.0',
  clientId: '55e0396a-3b95-416c-bd13-a7e1333328d5',
  authority: 'https://login.microsoftonline.com/d5975030-d97c-4412-8960-0ed4fdf42e7a',
  redirectUri: 'https://funzanetcloudprb.organizacionfunza.net/login',
  postLogoutRedirectUri: 'https://funzanetcloudprb.organizacionfunza.net/login'
};