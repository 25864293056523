import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ProductoService } from 'servicios/producto.service';

import { CategoriaProducto } from 'modelos/CategoriaProducto'

@Component({
  selector: 'app-detalle-insumo-dialogo',
  templateUrl: './detalle-insumo-dialogo.component.html',
  styleUrls: ['./detalle-insumo-dialogo.component.css']
})
export class DetalleInsumoDialogoComponent implements OnInit {
  insumoForm: FormGroup;
  Categorias: CategoriaProducto[] = [];

  constructor(
    private productoService: ProductoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DetalleInsumoDialogoComponent>,
    private toastr: ToastrService

  ) { }

  ngOnInit() {
    this.insumoForm = new FormGroup({
      idProducto:new FormControl(0),
      idCategoria: new FormControl(0), 
      descinsumo: new FormControl(""),
      referencia: new FormControl({ value: 'Automatico', disabled: true }),
      activo: new FormControl(1)
    });
    this.ObtenerCategorias();
  }

  ObtenerCategorias() {
    this.productoService.ObtenerCategoriasProducto().subscribe(
      categorias => {
        this.Categorias = categorias.filter(p=>p.IdCategoriaProductos!=9);
      },
      error => this.toastr.error("Error al obtener las especies. Intente más tarde por favor.", "ERROR"));
  }


  onClickSubmit(data) {
    this.dialogRef.close(data);
  }

}
