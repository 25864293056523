import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CentroProduccion } from 'modelos/CentroProduccion';

@Component({
  selector: 'app-menu-usuario',
  templateUrl: './menu.usuario.html',
  styleUrls: ['./menu.usuario.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuUsuario implements OnInit{
  @Input() nombre: string;
  @Input() login: string;
  centrosProduccion: CentroProduccion[];
  @Input() nombreCP: string;
  expanded: boolean;

  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;

  constructor() { }
ngOnInit(){
  // console.log(this.nombreCP)
}
}