import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GlobalesService } from 'servicios/globales.service';

@Component({
  selector: 'app-visor-powerbi',
  templateUrl: './visor-powerbi.component.html'  
})
export class VisorPowerBIComponent implements OnInit {
  nombre: string;
  url: SafeResourceUrl;

  constructor(
    private activatedRoute: ActivatedRoute,
    private globalesService: GlobalesService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      let ruta: string;
      let llave: string;
    
      let parametros: {
        nombre: string;
        valor: string;
      }[] = [];
      let url: string;

      // Lee los parámetros
      for (let nombre in params) {
        switch (nombre.toLowerCase()) {
         

          case "ruta": {
            ruta = params[nombre];
            break;
          }
          case "r": {
            llave = params[nombre];
            break;
          }

          

          
        }
      }
      // Genera la url base del reporte
      url = `https://app.powerbi.com/view?r=https://app.powerbi.com/view?r=eyJrIjoiZDkwMDQ3MTktZTQ3MS00NjYyLWFlZDYtOGYyMThiYjM5NGUzIiwidCI6ImQ1OTc1MDMwLWQ5N2MtNDQxMi04OTYwLTBlZDRmZGY0MmU3YSIsImMiOjR9`;

     
      // Desinfecta la url para que no arroje error
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }
}
