import { HttpService } from './http.service';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InfoCanalClima } from 'modelos/Clima/InfoCanalClima';

@Injectable()
export class ServiciosExternosService extends HttpService {


    ObtenerInformacionCanalClima(): Observable<InfoCanalClima[]> {       
            return this.get<InfoCanalClima[]>("/InfoCanalClima");
    }

}