import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'servicios/http.service';
import { GuiaFacturacion } from 'modelos/Facturacion/GuiaFacturacion';
import { RevisionFactura } from 'modelos/Facturacion/RevisionFactura';
import { FacturaDefinitiva } from 'modelos/Facturacion/FacturaDefinitiva';
import { ItemFacturaDefinitiva } from 'modelos/Facturacion/ItemFacturaDefinitiva';
import { FacturaNotaDebito } from 'modelos/Facturacion/FacturaNotaDebito';
import { ResultadoFacturaNotaDebito } from 'modelos/Facturacion/ResultadoFacturaNotaDebito';
import { ValidacionEspeciesDocExp } from 'modelos/Facturacion/ValidacionEspeciesDocExp';
import { EncabezadoGuiaFactura } from 'modelos/Facturacion/EncabezadoGuiaFactura';
import { ItemPedido } from 'modelos/mercadeo/ItemPedido';
import { TipoFacturacion } from 'modelos/Facturacion/TipoFacturacion';
import { TipoFactura } from 'modelos/Facturacion/TipoFactura';
import { EstadoFactura } from 'modelos/Facturacion/EstadoFactura';
import { TipoFormatoFactura } from 'modelos/Facturacion/TipoFormatoFactura';
import { formatDate } from '@angular/common';


@Injectable()
export class FacturacionService extends HttpService {

  ObtenerGuiasFacturacion(): Observable<EncabezadoGuiaFactura[]> {
    return this.get<EncabezadoGuiaFactura[]>('/Facturacion/Internacional/Guias');
  }


  ObterItemsGuiaFacturacion(idGuia: number, idCliente: number, esMuestra:boolean ): Observable<ItemPedido[]> {
    return this.get<ItemPedido[]>(`/Facturacion/Internacional/ItemsGuia/${idGuia}/${idCliente}/${esMuestra}`);
  }

  ObterItemsFacturacionNacional(idCliente: number, idEmpresa: number, fechasalida: Date, esMuestra: boolean): Observable<ItemPedido[]> {
    return this.get<ItemPedido[]>(`/Facturacion/Nacional/ItemsPedido/${idCliente}/${idEmpresa}/${formatDate(fechasalida, "yyyy-MM-dd", "en")}/${esMuestra}`);
  }
  
  FacturacionValidaciones(guia: GuiaFacturacion): Observable<GuiaFacturacion> {
    return this.post<GuiaFacturacion>("/Facturacion/Internacional/Validaciones", guia);
  }

  RevisarFactura(guia: GuiaFacturacion): Observable<RevisionFactura[]> {
    return this.post<RevisionFactura[]>("/Facturacion/Internacional/RevisarFactura", guia);
  }

  RevisarFacturaNacional(guia: GuiaFacturacion): Observable<RevisionFactura[]>{
    return this.post<RevisionFactura[]>("/Facturacion/Nacional/RevisarFacturaNacional", guia);
  }

  ObtenerNacionales(): Observable<GuiaFacturacion[]> {
    return this.get<GuiaFacturacion[]>('/Facturacion/Nacional/Guias');
  }

  guardarFactura(guia: GuiaFacturacion): Observable<GuiaFacturacion> {
    return this.post<GuiaFacturacion>("/Facturacion/GuardarFactura", guia);
  }

  ObtenerFacturasPendientes(idEmpresa: number, idCliente: number): Observable<FacturaDefinitiva[]> {
    return this.get<FacturaDefinitiva[]>(`/Facturacion/FacturasPendientes/${idEmpresa}/${idCliente}`);
  }

  ObtenerItemsFacturasPendientes(idItemFacturaDefinitiva: Number): Observable<ItemFacturaDefinitiva[]> {
    return this.get<ItemFacturaDefinitiva[]>(`/Facturacion/ItemsFacturaPendiente/${idItemFacturaDefinitiva}`);
  }

  FacturaNotaDebito(FacturaNotaDebito: FacturaNotaDebito): Observable<ResultadoFacturaNotaDebito> {
    return this.post<ResultadoFacturaNotaDebito>("/Facturacion/FacturaNotaDebito", FacturaNotaDebito);
  }
  ValidarEspecieDocumentoExportacion(ValidacionEspeciesDocExp: ValidacionEspeciesDocExp): Observable<boolean> {
    return this.post<boolean>("/Comex/ValidarEspecieDocumentoExportacion", ValidacionEspeciesDocExp);
  }
  ObtenerTiposFactura(): Observable<TipoFactura[]> {
    return this.get<TipoFactura[]>(`/Facturacion/TiposFactura`);
  }
  ObtenerTiposFormatoFactura(): Observable<TipoFormatoFactura[]> {
    return this.get<TipoFormatoFactura[]>(`/Facturacion/TiposFormatosFactura`)
  }

  ObtenerTipoFacturacion(): Observable<TipoFacturacion[]> {
    return this.get<TipoFacturacion[]>(`/Facturacion/TiposFacturacion`);
  }

  ObtenerEstadoFactura(): Observable<EstadoFactura[]> {
    return this.get<EstadoFactura[]>(`/Facturacion/EstadosFactura`);
  }

  ValidarComposicionComboBoquet(idEmpresa : number, idCLiente : number, Consecutivo : number): Observable<boolean> {
    return this.get<boolean>(`/Facturacion/ValidarComposicionComboBoquet/${idEmpresa}/${idCLiente}/${Consecutivo}`);
  }

  ValidarComposicionComboBoquetUsuario(fechaIni : Date,fechaFin : Date,idEmpresa : number, idCLiente : number, Consecutivo : number): Observable<boolean> {
    return this.get<boolean>(`/Facturacion/ValidarComposicionComboBoquetUsuario/${formatDate(fechaIni,"yyyy-MM-dd", "en")}/${formatDate(fechaFin,"yyyy-MM-dd", "en")}/${idEmpresa}/${idCLiente}/${Consecutivo}`);
  }

  GuiaMasterIdFactura(idfacturadefinitiva : number): Observable<string> {
    return this.get<string>(`/Facturacion/GuiaMasterIdFactura/${idfacturadefinitiva}`);
  }

}

