import { Component, Input } from '@angular/core';

@Component({
  selector: 'dialogo-alerta',
  templateUrl: './dialogo-alerta.component.html'
})
export class AlertaComponent {
  @Input() Titulo: string;
  @Input() Mensaje: string;

}
