import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'servicios/http.service';
import { OrdenRiverdale } from 'modelos/Interfaces/OrdenRiverdale';
//import { CambiosRiverdaleEncabezado } from 'modelos/Interfaces/CambiosRiverdaleEncabezado';
import { ItemCambioRiverdale } from 'modelos/Interfaces/ItemCambioRiverdale';
import { ItemsEnInvoice } from 'modelos/Interfaces/ItemsEnInvoice';
import { ArchivoExcel } from 'modelos/Interfaces/ArchivoExcel';
import { Pedido } from 'modelos/mercadeo/Pedido';
import { CambioAutomatico } from 'modelos/Interfaces/CambioAutomatico';

@Injectable()
export class IntegracionService extends HttpService {
  //#region riverdale

  ObtenerOrdenesPorFinca(finca: string, fechaInicial: Date, fechaFinal: Date): Observable<OrdenRiverdale[]> {
    return this.get<OrdenRiverdale[]>(`/Integracion/OrdenesPorFinca/${finca}/${fechaInicial.toJSON().substring(0, 10)}/${fechaFinal.toJSON().substring(0, 10)}`);
  }

  ObtenerCambiosPorFinca(finca: string, fechaInicial: Date, fechaFinal: Date): Observable<ItemCambioRiverdale[]> {
    return this.get<ItemCambioRiverdale[]>(`/Integracion/CambiosRiverdalePorFinca/${finca}/${fechaInicial.toJSON().substring(0, 10)}/${fechaFinal.toJSON().substring(0, 10)}`);
  }

  ObtenerCambiosPorNumero(finca: string, numeroOrden: string, renglon: string): Observable<ItemCambioRiverdale[]> {
    return this.get<ItemCambioRiverdale[]>(`/Integracion/CambiosRiverdalePorOrden/${finca}/${numeroOrden}/${renglon}`);
  }

  ObtenerOrdenesPorNumero(numeroOrden: string, renglon: number): Observable<OrdenRiverdale[]> {
    return this.get<OrdenRiverdale[]>(`/Integracion/OrdenesPorNumero/${numeroOrden}/${renglon}`);
  }

  CrearEquivalenciaOrdenes(orden: OrdenRiverdale): Observable<OrdenRiverdale> {
    return this.post<OrdenRiverdale>(`/Integracion/EquivalenciaPiezas`, orden);
  }

  CrearOrden(ordenes: OrdenRiverdale[]): Observable<number> {
    return this.post<number>(`/Integracion/OrdenRiverdale`, ordenes);
  }
  
  ProcesarCambioOrdenes(ordenes: CambioAutomatico[]): Observable<number> {
    return this.post<number>(`/Integracion/CambiosRiverdale`, ordenes);
  }

  ItemsEnInvoicePorFinca(centroProduccion: number, fechaInicial: Date, fechaFinal: Date): Observable<ItemsEnInvoice[]> {
    return this.get<ItemsEnInvoice[]>(`/Integracion/ItemsEnInvoicePorFinca/${centroProduccion}/${fechaInicial.toJSON().substring(0, 10)}/${fechaFinal.toJSON().substring(0, 10)}`);
  }

  ItemsEnInvoicePorOrden(centroProduccion: number, numeroOrden: string, renglon: number): Observable<ItemsEnInvoice[]> {
    return this.get<ItemsEnInvoice[]>(`/Integracion/ItemsEnInvoicePorOrden/${centroProduccion}/${numeroOrden}/${renglon}`);
  }

  ProcesarImpresionRiverdale(ordenes: ItemsEnInvoice[]): Observable<string> {
    return this.post<string>(`/Integracion/ProcesarImpresionCambiosRiverdale`, ordenes);
  }

  //#endregionfechaFinalfechaFinalfechaFinal

  //#region interfaces

  ProcesarArchivo(archivo: ArchivoExcel, idFinca): Observable<Pedido> {
    return this.post<Pedido>(`/Integracion/ConvertirExcel/${idFinca}`, archivo);
  }

  CrearPedidoInterfaces(pedido: Pedido): Observable<number> {
    return this.post<number>(`/Integracion/CrearPedido/`, pedido);
  }

  //#endregion
}