import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AutenticacionService } from './autenticacion.service';

@Injectable()
export class HttpService {
  protected urlBase: string = environment.urlServicios;

  constructor(
    private http: HttpClient,
    private autenticacionService: AutenticacionService
  ) { }

  protected get<T>(ruta: string): Observable<T> {
    const url: string = this.urlBase + ruta;
    const httpOptions = { headers: this.ConstruirEncabezados() };

    return this.http.get<T>(url, httpOptions).pipe(
      catchError(this.manejoError)
    );
  }

  public post<T>(ruta: string, cuerpo: any, encabezados?: any): Observable<T> {
    const url: string = this.urlBase + ruta;
    const httpOptions = { headers: this.ConstruirEncabezados(encabezados ? encabezados : { "Content-Type": "application/json" }) };

    return this.http.post<T>(url, cuerpo, httpOptions).pipe(
      catchError(this.manejoError)
    );
  }

  protected delete<T>(ruta: string): Observable<T> {
    const url: string = this.urlBase + ruta;
    const httpOptions = { headers: this.ConstruirEncabezados() };

    return this.http.delete<T>(url, httpOptions).pipe(
      catchError(this.manejoError)
    );
  }

  private manejoError(error: HttpErrorResponse) {
    let mensaje: string;

    if (error.status == 0)
      mensaje = "Verifique la conexión del equipo a la red.";
    else
      if (error.status == 400)
        mensaje = error.error.Message;
      else
        mensaje = "Ha sucedido un error al procesar la solicitud. Intente más tarde por favor.";

    return throwError(mensaje);
  }

  private ConstruirEncabezados(encabezados?: any): HttpHeaders {
    if (!encabezados)
      encabezados = {};

    if (this.autenticacionService.token)
      encabezados["Authorization"] = "Bearer " + this.autenticacionService.token;

    return new HttpHeaders(encabezados);
  }
}