import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpKonektoService } from 'servicios/http-konekto.service';
import { ItemsSalidaAlmacenDynamics } from 'modelos/tecnico/ItemsSalidaAlmacenDynamics';

@Injectable()
export class ServicioskonektoService extends HttpKonektoService {

  SalidaMultipleTecnicoDynamics(itemssalida: ItemsSalidaAlmacenDynamics[]): Observable<boolean> {
    return this.post<boolean>("/Tecnico/SalidaMultipleTecnicoDynamics", itemssalida);
  }

}
