import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from 'vistas/dashboard/dashboard.component';
import { VisorReporteComponent } from 'vistas/compartido/visor-reporte/visor-reporte.component';
import { ParametricasComponent } from 'vistas/parametricas/parametricas.component';
import { VisorPowerBIComponent } from 'vistas/compartido/visor-powerbi/visor-powerbi.component';
import { LoginComponent } from 'vistas/login/login.component';
import { GuardiaGuard } from './guardias/guardia.guard';

const routes: Routes = [
  { path: 'seguridad', loadChildren: 'vistas/seguridad/seguridad.module#SeguridadModule', canActivate: [GuardiaGuard] },
  { path: 'crm', loadChildren: 'vistas/crm/crm.module#CrmModule', canActivate: [GuardiaGuard] },
  { path: 'producto', loadChildren: 'vistas/producto/producto.module#ProductoModule', canActivate: [GuardiaGuard] },
  { path: 'logistica', loadChildren: 'vistas/logistica/logistica.module#LogisticaModule', canActivate: [GuardiaGuard]},
  { path: 'integracion', loadChildren: 'vistas/integracion/integracion.module#IntegracionModule', canActivate: [GuardiaGuard] },
  { path: 'mercadeo', loadChildren: 'vistas/mercadeo/mercadeo.module#MercadeoModule', canActivate: [GuardiaGuard]},  
  { path: 'facturacion', loadChildren: 'vistas/facturacion/facturacion.module#facturacionModule', canActivate: [GuardiaGuard]},
  { path: 'postcosecha', loadChildren: 'vistas/postcosecha/postcosecha.module#PostcosechaModule', canActivate: [GuardiaGuard] },
  { path: 'tecnico', loadChildren: 'vistas/tecnico/tecnico.module#TecnicoModule', canActivate: [GuardiaGuard] },
  { path: 'archivosplanos', loadChildren: 'vistas/archivosplanos/archivosplanos.module#ArchivosPlanosModule', canActivate: [GuardiaGuard] },
  { path: 'homologaciones', loadChildren: 'vistas/homologaciones/homologaciones.module#HomologacionesModule', canActivate: [GuardiaGuard] },
  { path: 'estadisticos', loadChildren: 'vistas/estadisticos/estadisticos.module#EstadisticosModule', canActivate: [GuardiaGuard] },
  { path: 'integracionesdynamics', loadChildren: 'vistas/integracionesdynamics/integracionesdynamics.module#IntegracionesDynamicsModule', canActivate: [GuardiaGuard]  },
  { path: 'dashboard', component: DashboardComponent, canActivate: [GuardiaGuard] },
  { path: 'visorreporte', component: VisorReporteComponent, canActivate: [GuardiaGuard] },
  { path: 'reportePowerBI', component: VisorPowerBIComponent, canActivate: [GuardiaGuard] },
  { path: 'parametricas', component: ParametricasComponent, canActivate: [GuardiaGuard] },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule {}
