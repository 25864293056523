import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ItemMenu } from 'modelos/ItemMenu';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuComponent {
  @Input() item: ItemMenu;
  @Input() depth: number;
  @Output() itemMenuClick: EventEmitter<any> = new EventEmitter();
  expanded: boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;

  constructor(public router: Router) {
    if (this.depth === undefined)
      this.depth = 0;
  }

  onItemSelected(item: ItemMenu) {
    if (!item.ItemMenuHijos || !item.ItemMenuHijos.length) {

      if (!item.URL.includes("https")) {
        this.router.navigateByUrl(item.URL.replace('%3F', '?').replace('%3D', '='));
        this.itemMenuClick.emit();
      }
      else
        window.open(item.URL, '_blank');
    }

    if (item.ItemMenuHijos && item.ItemMenuHijos.length) {
      this.expanded = !this.expanded;
    }
  }
}