import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatRadioChange } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ProductoService } from 'servicios/producto.service';
import { ResultadoBusqueda } from 'modelos/ResultadoBusqueda';
import { FiltroBusquedaCatalogo } from 'modelos/FiltroBusquedaCatalogo';
import { ReferenciaBusqueda } from 'modelos/ReferenciaBusqueda';

@Component({
  selector: 'app-catalogo-busqueda',
  templateUrl: './catalogo-busqueda.component.html',
  styleUrls: ['./catalogo-busqueda.component.css']
})
export class CatalogoBusquedaComponent implements OnInit {
  palabrasClaveActuales: string;
  idTipoProductoInicial: number;
  idTipoProductoActual: number;
  paginaActual: number = 1;
  filtrosActuales: FiltroBusquedaCatalogo[] = [];
  resultadoBusqueda: ResultadoBusqueda;
  paginas: number[];  
  idReferencia: number;
  filtroCantidad: number;
  buscando: boolean = false;
  @ViewChild('contenido') contenido: ElementRef;
  idtipoproducto:number;
  referencia:ReferenciaBusqueda;

  constructor(
    private productoService: ProductoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CatalogoBusquedaComponent>,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.palabrasClaveActuales = this.data && this.data.palabrasClave ? this.data.palabrasClave : "";
    this.idTipoProductoInicial = this.data && this.data.idTipoProducto ? this.data.idTipoProducto : -1;    
    this.idTipoProductoActual = this.idTipoProductoInicial;
  }

  aceptar() {
    this.data.ref=this.referencia;
    this.dialogRef.close(this.data);
    // this.dialogRef.close(this.idReferencia);
  }

  cancelar() {
    this.dialogRef.close();
  }

  buscar(palabrasClave: string, idTipoProducto: number, pagina: number, filtros: FiltroBusquedaCatalogo[]) {
    this.buscando = true;
    this.contenido.nativeElement.scrollTop = 0;
    
    this.productoService.BuscarReferencias(palabrasClave, idTipoProducto, pagina, filtros).subscribe(
      data => {
        this.resultadoBusqueda = data;
        this.buscando = false;
        this.palabrasClaveActuales = palabrasClave;
        this.idTipoProductoActual = idTipoProducto;
        this.paginaActual = pagina;
        this.filtrosActuales = filtros;

        if (!this.filtrosActuales.some(f => f.Nombre == "Cantidad"))
          this.filtroCantidad = null;

        // Calcula la cantidad de páginas
        let cantidadPaginas: number = (data.Total - (data.Total % 50)) / 50;

        if (cantidadPaginas > 10)
          cantidadPaginas = 10;
        else
          if ((data.Total % 50) > 0)
            cantidadPaginas++;

        // Genera el arreglo con el número de la página
        this.paginas = new Array(cantidadPaginas).fill(0).map((x, i) => i + 1);
      },
      error => {
        this.toastr.error(error, "ERROR");
        this.buscando = false;
      }
    );
  }

  filtrar(nombre: string, valor: number) {
    if (!this.esFiltroActivo(nombre, valor)) {
      // Crea el filtro
      let filtro: FiltroBusquedaCatalogo = new FiltroBusquedaCatalogo();
      filtro.Nombre = nombre;
      filtro.Valor = valor;

      // Copia los filtros actuales
      let filtros: FiltroBusquedaCatalogo[] = this.filtrosActuales.map(f => Object.assign({}, f));
      filtros.push(filtro);

      // Realiza la búsqueda
      this.buscar(this.palabrasClaveActuales, this.idTipoProductoActual, 1, filtros);
    }
  }

  esFiltroActivo(nombre: string, valor?: number): boolean {
    if (valor)
      return this.filtrosActuales.some(f => f.Nombre == nombre && f.Valor == valor);
    else
      return this.filtrosActuales.some(f => f.Nombre == nombre);
  }

  removerFiltro(nombre: string, valor: number) {
    // Copia los filtros actuales
    let filtros: FiltroBusquedaCatalogo[] = this.filtrosActuales.map(f => Object.assign({}, f));

    // Busca el filtro
    let filtro: FiltroBusquedaCatalogo = this.filtrosActuales.find(f => f.Nombre == nombre && f.Valor == valor);
    let indice: number = this.filtrosActuales.indexOf(filtro);

    // Elimina el filtro
    filtros.splice(indice, 1);

    // Realiza la búsqueda
    this.buscar(this.palabrasClaveActuales, this.idTipoProductoActual, 1, filtros);
  }

  onChange(event: MatRadioChange) {
    // this.idReferencia = event.value;
    this.referencia= event.value;
    
  }
}
